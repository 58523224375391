import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigate from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import "../Orders/order.css";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import imgHost from "../../../Components/ReUsed";
import Navigation from "../../../config/Navigation.json";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Donate = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);

  let [data, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  const [tablePageIndex, setTablePageIndex] = useState(1);
  const [productsPerPage, setProductPerPage] = useState(15);

  // Product Search
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [isSearchApply, setIsSearchApply] = useState(false);
  let url = `${API_HOST}/${APIEndpoint.donatableProducts}?page=${currentPage}&per_page=${productsPerPage}`;

  // get all donatable products
  const fetchDonatableProducts = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setPagination(res.data);
        const dataArray = res.data.data;
        const updatedData = [...[], ...dataArray];
        setOrderData(updatedData);
        setCurrentPage(res.data.current_page);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 400) {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setOrderData([]);
        setPagination("");
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setSearchLoading(false);
    setLoading(false);
  };

  // pagination URL change
  const handleURLChange = (url) => {
    window.scrollTo(0, 0);
    fetchDonatableProducts(url);
  };

  // handle product search api calls
  useEffect(() => {
    if (isSearchApply) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        data = [];
        currentPage = 1;
        let url = `${API_HOST}/${APIEndpoint.donatableProducts}?page=${currentPage}&per_page=${productsPerPage}`;
        if (searchQuery !== "") {
          url = url + "&search=" + searchQuery;
          fetchDonatableProducts(url);
        } else {
          fetchDonatableProducts(url);
        }
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  // NGOs
  const [ngos, setNgos] = useState([]);
  const [ngoPagination, setNgoPagination] = useState([]);
  const [enableNgoSelectBtn, setEnableNgoSelectBtn] = useState(false);
  const [ngoPerPage, setNgoPerPage] = useState(5);
  const [ngoCurrentPage, setNgoCurrentPerPage] = useState(1);
  const [selectedNgo, setSelectedNgo] = useState(null);

  // get all ngos
  const getNGOs = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const ngoUrl = `${API_HOST}/${APIEndpoint.donatableNgos}?page_no=${ngoCurrentPage}&per_page=${ngoPerPage}`;
    try {
      const response = await fetch(ngoUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setNgos(res.data.data);
        setNgoPagination(res.data);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 400) {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setNgos([]);
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error fetching ngos:", error);
    }
  };
  useEffect(() => {
    getNGOs();
  }, [ngoCurrentPage]);

  // loading products
  useEffect(() => {
    setSearchQuery("");
    setTablePageIndex(0);
    fetchDonatableProducts(url);
  }, []);

  // selected products
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const handleCheckboxChange = useCallback(
    (productId) => {
      setSelectedProductIds((prevSelected) =>
        prevSelected.includes(productId)
          ? prevSelected.filter((id) => id !== productId)
          : [...prevSelected, productId]
      );
    },
    [setSelectedProductIds]
  );

  // Donate Now
  const donateNow = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const payload = {
      ngo_id: selectedNgo.id,
      product_id: selectedProductIds,
    };
    try {
      const response = await fetch(`${API_HOST}/${APIEndpoint.donate}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const res = await response.json();

      if (res.status === 201) {
        showToast("success", res.message);

        // Reset All fileds
        fetchDonatableProducts(url);
        setSelectedProductIds([]);
        setSelectedNgo(null);
        setEnableNgoSelectBtn(false);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 400) {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setOrderData([]);
        setPagination("");
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error donate products:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Select",
        accessor: "id",
        Cell: ({ row }) => (
          <input
            className="donatable-pro-select"
            type="checkbox"
            checked={selectedProductIds.includes(row.original.id)}
            onChange={() => handleCheckboxChange(row.original.id)}
          />
        ),
      },
      {
        Header: "Image",
        accessor: "thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${imgHost}/${value}`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Product",
        accessor: "name",
        cell: ({ value }) => <>{value}</>,
      },
      {
        Header: "Price",
        accessor: "product_price",
        Cell: ({ value }) => <>₹{value}</>,
      },
    ],
    [currentPage, selectedProductIds, handleCheckboxChange]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tablePageIndex,
        pageSize: productsPerPage,
        globalFilter: "",
      },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li
            onClick={() => {
              navigate(Navigation.donations);
            }}
          >
            Donations
          </li>
          <li className="active">Donate</li>
        </div>
      </div>

      <div className="page-container">
        <div className="donate-container">
          <div className="ngo-area">
            <h3 className="">NGO Details</h3>

            {!enableNgoSelectBtn && (
              <button
                type="button"
                className="btn secondary-btn select-ngo-btn"
                onClick={(e) => {
                  setEnableNgoSelectBtn(true);
                }}
              >
                Select NGO
              </button>
            )}

            {enableNgoSelectBtn && selectedNgo == null && (
              <>
                <div className="ngo-list">
                  {ngos &&
                    ngos.length > 0 &&
                    ngos.map((ngo, index) => (
                      <div
                        key={index}
                        className="ngo-card"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedNgo(ngo);
                        }}
                      >
                        <h3>{ngo.org_name}</h3>
                        <p>{ngo.address1}</p>
                      </div>
                    ))}
                </div>

                <div className="ngo-pagination-line">
                  <button
                    type="button"
                    disabled={ngoPagination.prev_page_url === null}
                    style={{
                      pointerEvents: ngoPagination.prev_page_url ? "" : "none",
                      opacity: ngoPagination.prev_page_url ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (ngoPagination.prev_page_url !== null) {
                        setNgoCurrentPerPage(ngoPagination.current_page - 1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} color="#0969da" />{" "}
                    Previous
                  </button>
                  <button
                    type="button"
                    disabled={ngoPagination.next_page_url === null}
                    style={{
                      pointerEvents: ngoPagination.next_page_url ? "" : "none",
                      opacity: ngoPagination.next_page_url ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (ngoPagination.next_page_url !== null) {
                        setNgoCurrentPerPage(ngoPagination.current_page + 1);
                      }
                    }}
                  >
                    Next <FontAwesomeIcon icon={faAngleRight} color="#0969da" />
                  </button>
                </div>
              </>
            )}

            {selectedNgo && (
              <div className="selected-ngo-details">
                <button
                  type="button"
                  className="ngo-reset-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedNgo(null);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} color="#0969da" /> Back
                </button>
                <div>
                  <h3>{selectedNgo.org_name}</h3>
                  <p>{selectedNgo.address1}</p>
                </div>
              </div>
            )}
          </div>
          <div className="product-selecting-area">
            <div className="select-product-heading">
              <div>
                <h3>Donatable Products</h3>
                <p>Select products from below list</p>
              </div>
              <div>
                <button
                  title="Donate Now"
                  type="button"
                  className="btn primary-btn"
                  style={{
                    opacity:
                      selectedNgo && selectedProductIds.length > 0 ? 1 : 0.5,
                    pointerEvents:
                      selectedNgo && selectedProductIds.length > 0
                        ? ""
                        : "none",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedNgo && selectedProductIds.length > 0) {
                      donateNow();
                    }
                  }}
                >
                  Donate Now
                  {selectedProductIds &&
                    selectedProductIds.length > 0 &&
                    ` (${selectedProductIds.length})`}
                </button>
              </div>
            </div>
            <div className="table-serach-area">
              {/* search bar */}

              <div className="first-area">
                <div className="search-bar">
                  <div className="fontAwsomeDiv">
                    {searchLoading ? (
                      <LoaderButton />
                    ) : (
                      <FontAwesomeIcon
                        className="SearchIconDiv"
                        icon={faSearch}
                      />
                    )}
                  </div>
                  <input
                    id="seller-searchForProducts"
                    placeholder="Search..."
                    className="search-input-order"
                    name="search-query"
                    value={searchQuery}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.target.value;
                      setSearchQuery(val);
                      setIsSearchApply(true);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Data table */}
            <div className="InnerTableContainer">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="TableHeading"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div className="header-cell">
                            <span>{column.render("Header")}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">Loading...</div>
                      </td>
                    </tr>
                  ) : page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="TableBodyData" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">No data</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="pagination-container">
              {pagination && (
                <Paginate
                  pagination={pagination}
                  pageChange={handleURLChange}
                  pageName={
                    contextObj.inputValue.activeOrderFilter + " Products"
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
