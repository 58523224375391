import React, { useState, useMemo } from "react";
import CreatedContexts from "./createContext";

const CreatedContextsState = (Props) => {
  const [states, setStates] = useState({
    sellerId: "",
    inHouseProductsSortedState: [],
  });

  const setInputValue = (field, value) => {
    setStates((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Use useMemo to memoize the value object
  const contextValue = useMemo(
    () => ({ states, setInputValue }),
    [states]
  );

  return (
    <CreatedContexts.Provider value={contextValue}>
      {Props.children}
    </CreatedContexts.Provider>
  );
};

export default CreatedContextsState;