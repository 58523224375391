import React, { useState, useEffect, useRef, useContext } from "react";
import Logout from "../../Auth/Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getDataFromCookies,
  setDataToCookiesWithExpirationTime,
} from "../../Cookie";
import "./navbar.css";
import InputContext from "../../InputContext";

const APP_NAME = process.env.REACT_APP_NAME;

const TopNavbar = () => {
  const location = useLocation();
  const [userName, setUserName] = useState(false);
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  useEffect(() => {
    const isLoginByAdmin =
      getDataFromCookies("isLoginByAdmin") ||
      contextObj.inputValue.isLoginByAdmin;
    if (isLoginByAdmin) {
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "isLoginByAdmin",
        true,
        expiryDate,
        "/"
      );
      let login = getDataFromCookies("loggedIn");
      if (login) {
        setUserName(true);
      }
    }
  }, [contextObj.inputValue.isLoginByAdmin]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(true);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = async () => {
    await Logout();
  };

  const myProflile = () => {
    navigate("/profile");
  };

  if (
    location.pathname === "/" ||
    location.pathname === "/version" ||
    location.pathname === "/forgot-password"
  ) {
    return <></>;
  }
  return (
    <nav className="AdminTopnav">
      <h1 className="appname-heading" onClick={() => navigate("/dashboard")}>
        {APP_NAME}
      </h1>

      {userName && (
        <div className="user" ref={dropdownRef} onClick={toggleDropdown}>
          <button className="user-icon-btn">
            <FontAwesomeIcon icon={faUser} />
          </button>
          {isOpen && (
            <div className="AdminTopNavdropdown" id="dropdown">
              <ul>
                <li onClick={myProflile}>Profile</li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};
export default TopNavbar;
