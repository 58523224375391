import React, { useEffect, useState, useRef, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import Alert from "../../../config/ProjectConstants.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import "../../../Assets/style.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import loadingImgPath from "../../../Assets/rolling.svg";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";
// import constant
import IMG_HOST from "../../../Components/ReUsed";
import { formatDateTime } from "../../../utils/utils";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;
const SellerProductEdit = ({ page }) => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { showToast } = Toast();
  const [isLoading, setIsLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [productRejectModal, setProductRejectModal] = useState(false);
  const [disableCalculate, setDisableCalculate] = useState(true);
  const [openSeoEdit, setOpenSeoEdit] = useState(false);
  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);
  const [enableWarehouse, setEnableWarehouse] = useState(false);

  // Replace blank spaces with "&nbsp;" and line breaks with "<br>"
  const convertToAmpersand = (text) => {
    return text.replace(/\s/g, "&nbsp;").replace(/\n/g, "<br>");
  };
  const closeProductRejectModal = () => {
    setProduct((prev) => ({
      ...prev,
      denied_note: "",
    }));
    setErrors((prev) => ({
      ...prev,
      denied_note: "",
    }));
    setEnableRejectBtn(false);
    setProductRejectModal(false);
  };

  // set page name
  const [pageName, setPageName] = useState("");
  const [productId, setProductId] = useState("");
  useEffect(() => {
    if (page === "edit" || page === "view") {
      setPageName("Edit");
      const urls = slug.split("-");
      const productId = urls[urls.length - 2];
      setProductId(productId);
      getProductDetails(productId);
    }
  }, [page]);

  // save new products
  const [sellerData, setSellerData] = useState("");
  const [product, setProduct] = useState({
    name: "",
    status: "",
    description: "",
    category_id: "",
    brand_id: "",
    condition: "",
    product_price: "",
    current_market_price: "",
    age_group_id: "",
    thumb_image: null,
    video_url: "",
    platform_charge_type: "",
    platform_charge: "",
    gst: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    product_address: "",
    warehouse_id: "",
    is_donatable: 0,
    is_negotiable: 0,
    sku: "",
    barcode: "",
    status: "",
    hsn: "",
    denied_note: "",
    meta_title: "",
    meta_description: "",
    created_at: null,
    updated_at: null,
  });

  const [errors, setErrors] = useState({
    has_error: false,
    name: "",
    description: "",
    thumb_image: "",
    front_image: "",
    left_image: "",
    right_image: "",
    back_image: "",
    top_image: "",
    video_url: "",
    product_price: "",
    current_market_price: "",
    sku: "",
    hsn: "",
    barcode: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    product_address: "",
    warehouse_id: "",
    is_negotiable: "",
    is_donatable: "",
    status: "",
    condition: "",
    age_group_id: "",
    brand_id: "",
    category_id: "",
    estimated_shipping_cost: "",
    denied_note: "",
  });

  // handle selling button
  useEffect(() => {
    if (
      product.product_price !== "" &&
      product.current_market_price !== "" &&
      product.gst !== "" &&
      product.platform_charge_type !== "" &&
      product.platform_charge !== ""
    ) {
      setDisableCalculate(false);
    } else {
      setDisableCalculate(true);
    }
  }, [product]);

  const calculateSellingPrice = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    let errorHas = false;
    // product price error
    const productPrice = /^[0-9]+$/;
    const isPrice = productPrice.test(product.product_price);
    if (
      product.status === "Publish" &&
      (product.product_price === "" || product.product_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.product_price !== "" &&
      product.product_price != null &&
      !isPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.product_price !== "" &&
      product.product_price != null &&
      (product.product_price > 100000 || product.product_price < 100)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.valBetween100To100000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        product_price: "",
      }));
    }

    // current_market_price validation
    const marketPrice = /^[0-9]+$/;
    const isOriginalPrice = marketPrice.test(product.current_market_price);
    if (
      product.status === "Publish" &&
      (product.current_market_price === "" ||
        product.current_market_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.current_market_price !== "" &&
      product.current_market_price != null &&
      !isOriginalPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.current_market_price !== "" &&
      product.current_market_price != null &&
      (product.current_market_price > 200000 ||
        product.current_market_price < 200)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.valBetween200To200000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        current_market_price: "",
      }));
    }

    // GST Percentage validation
    if (
      product.status === "Publish" &&
      (product.gst === "" || product.gst === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (
      product.status === "Publish" &&
      (product.platform_charge === "" || product.platform_charge === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    if (errorHas === true) return;
    const payload = {
      product_price: product.product_price,
      current_market_price: product.current_market_price,
      gst: product.gst,
      platform_charge_type: product.platform_charge_type,
      platform_charge: product.platform_charge,
    };

    setDisableCalculate(true);
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.calculateSellingPrice}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      setProduct((prev) => ({
        ...prev,
        selling_price: res.data.selling_price,
      }));
    } else if (res.status === 403) {
      setInfoModalOpen(true);
      setModalMessage(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
        showToast("error", errorMessage);
      }
    } else {
      showToast("info", "Something went wrong");
    }
    setDisableCalculate(false);
  };

  // Enable reject button
  const [enableRejectBtn, setEnableRejectBtn] = useState(false);
  useEffect(() => {
    if (product.status === "Rejected" && product.denied_note) {
      setEnableRejectBtn(true);
    } else {
      setEnableRejectBtn(false);
    }
  }, [product]);

  // Enable Add product button
  const [enableAddProductBtn, setEnableAddProductBtn] = useState(false);
  useEffect(() => {
    if (product.status !== "" && product.name !== "") {
      setEnableAddProductBtn(true);
    } else {
      setEnableAddProductBtn(false);
    }
  }, [product]);

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const setImagePath = (images) => {
    if (images.thumb_image !== "null" && images.thumb_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        thumb_image: IMG_HOST + "/" + images.thumb_image,
      }));
    }
    if (images.front_image !== "null" && images.front_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        front_image: IMG_HOST + "/" + images.front_image,
      }));
    }
    if (images.left_image !== "null" && images.left_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        left_image: IMG_HOST + "/" + images.left_image,
      }));
    }
    if (images.right_image !== "null" && images.right_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        right_image: IMG_HOST + "/" + images.right_image,
      }));
    }
    if (images.back_image !== "null" && images.back_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        back_image: IMG_HOST + "/" + images.back_image,
      }));
    }
    if (images.top_image !== "null" && images.top_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        top_image: IMG_HOST + "/" + images.top_image,
      }));
    }
  };

  // Get product details
  const getProductDetails = async (productId) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      const resData = res.data;
      setProduct(resData);
      if (resData) {
        setProduct(resData);
        setImagePath(resData);
      }

      if (resData.product_address === "Warehouse") {
        setEnableWarehouse(true);
      }
      if (resData.seller) {
        setSellerData(resData.seller);
      }
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // image handling
  const [showImgUpModal, setShowImgUpModal] = useState(false);
  const [rawImgName, setRawImgName] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const cropperRef = useRef(null);

  const [imgContainer, setImgContainer] = useState({
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  const removeImageFromContainer = (imgName) => {
    setErrors((prev) => ({
      ...prev,
      [imgName]: "",
    }));
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setProduct((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  const resetInputType = (imgName) => {
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setProduct((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  const isBase64Image = (value) => {
    return typeof value === "string" && value.startsWith("data:image/");
  };

  const loadImgOnModal = (imgName) => {
    setShowImgUpModal(true);
    setRawImgName(imgName);
    setRawImage(imgContainer[imgName]);
  };

  const convertBase64ToFile = (base64Image, file) => {
    if (base64Image.startsWith("data:image/")) {
      const binaryString = atob(base64Image.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const fileSize = arrayBuffer.byteLength;
      const customFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(customFile, "size", {
        value: fileSize,
      });

      return customFile;
    }
  };

  const cropImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const customFile = convertBase64ToFile(croppedImg, product[rawImgName]);
      if (customFile) {
        setProduct((prev) => ({
          ...prev,
          [rawImgName]: customFile,
        }));
        setImgContainer((prev) => ({
          ...prev,
          [rawImgName]: croppedImg,
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [rawImgName]: "Invalid image format",
        }));
      }
    }
    // reset modal
    setShowImgUpModal(false);
    setRawImgName("");
    setRawImage(null);
  };

  const compressAndResizeImage = (file, imgName) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set dimensions to 600x600
        canvas.width = 600;
        canvas.height = 600;

        // Calculate scaling factors to fit image within 600x600
        const scaleFactor = Math.min(600 / img.width, 600 / img.height);
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;

        // Draw image on canvas with new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgContainer((prev) => ({
                ...prev,
                [imgName]: reader.result,
              }));
            };
            reader.readAsDataURL(compressedImg);

            setProduct((prev) => ({
              ...prev,
              [imgName]: compressedImg,
            }));
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // image upload functions start here
  const handleImageUpload = (imgName) => {
    const ele = document.getElementById(imgName);
    ele.click();

    ele.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (file === undefined) return;

      setErrors((prev) => ({
        ...prev,
        [imgName]: "",
      }));

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        setErrors((prev) => ({
          ...prev,
          [imgName]:
            "Please upload an image with .png, .jpg, .jpeg, or .webp extension.",
        }));
        resetInputType(imgName);
        return;
      }

      // Compress and resize image
      compressAndResizeImage(file, imgName);
    });
  };

  // Featch Category API TO show List of All Category Element;
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setCategoryData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })

      .catch((err) => console.error(err));
  };

  // Featch Brand API TO show List of All Brand List;
  const [brandData, setBrandData] = useState([]);

  const getAllBrandList = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    await fetch(`${API_HOST}/${APIEndpoint.getBrandsList}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setBrandData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [conditionData, setConditionData] = useState([]);

  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/api/conditions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(`${API_HOST}/api/applicable-age-groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    }
  };

  const [gstPercentage, setGstPercentage] = useState([]);
  const getGSTpercentage = async () => {
    await fetch(`${API_HOST}/api/gst-percents`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGstPercentage(data.data);
      })
      .catch((err) => console.error(err));
  };

  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAllCategoriesData();
    getAllBrandList();
    getAllConditionsList();
    getAllAplplicableAgeGroupList();
    getGSTpercentage();
    getWereHouseAddress();
  }, []);

  const [imagesURL, setImagesURL] = useState({
    font_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  // This API Fetching for the Verifying the Products.
  const verifyAndPublishProduct = async (formData) => {
    setIsLoading(true);

    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    formData.append("_method", "PUT");
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", "Product updated successfully");
      window.history.back();
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired! Please login");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 400) {
      if (res.message && res.message.includes("name")) {
        setErrors((prev) => ({
          ...prev,
          name: res.message,
        }));
      } else if (res.message && res.message.includes("sku")) {
        setErrors((prev) => ({
          ...prev,
          sku: res.message,
        }));
      } else {
        const errors = res.errors;
        let errorMessage = "";
        if (errors) {
          Object.keys(errors).forEach((key) => {
            errors[key].forEach((message) => {
              errorMessage = message;
            });
          });
        }
        showToast("error", errorMessage);
      }
    } else if (res.status === 403) {
      setInfoModalOpen(true);
      setModalMessage(res.message);
    } else {
      showToast("error", "Something went wrong!");
    }
    setIsLoading(false);
    setEnableAddProductBtn(true);
  };

  // Rejected the product
  const rejectedProduct = async () => {
    setEnableRejectBtn(false);
    let error = false;
    if (product.denied_note.length < 10) {
      setErrors((prev) => ({
        ...prev,
        denied_note: "Minimum 10 characters are required.",
      }));
      error = true;
    }

    if (error) {
      setEnableRejectBtn(true);
      return;
    }
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const payload = {
      id: productId,
      status: "Rejected",
      denied_note: product.denied_note,
    };

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,

      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", res.message);
      window.history.back();
    } else if (
      response.status === 401 &&
      response.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (response.status === 401 && response.message !== "") {
      showToast("error", response.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setEnableRejectBtn(true);
  };

  const handleButtonSubmit = async () => {
    setEnableAddProductBtn(false);
    let errorHas = false;

    if (product.name === "" || product.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.required,
      }));
      errorHas = true;
    } else if (product.name.length > 150 || product.name.length < 3) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.charBetween3To150,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: "",
      }));
    }

    // Description validation
    const emptyHTMLTagsFound = (input) => {
      if (
        /^<\w+><br><\/\w+>/.test(input) ||
        /<\w+><br><\/\w+>$/.test(input) ||
        /^<\w+>\s*<\/\w+>/.test(input)
      ) {
        return true;
      }
      return false;
    };
    if (
      product.status !== "Rejected" &&
      emptyHTMLTagsFound(product.description)
    ) {
      setErrors((prev) => ({
        ...prev,
        description: "Leading / trailing whitespace not allowed",
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        description: "",
      }));
    }

    // all images validation
    if (
      product.status === "Verified and Published" &&
      (product.thumb_image === "null" ||
        product.thumb_image === null ||
        product.thumb_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        thumb_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, thumb_image: "" }));
    }
    if (
      product.status === "Verified and Published" &&
      (product.front_image === "null" ||
        product.front_image === null ||
        product.front_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        front_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, front_image: "" }));
    }
    if (
      product.status === "Verified and Published" &&
      (product.left_image === "null" ||
        product.left_image === null ||
        product.left_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        left_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, left_image: "" }));
    }
    if (
      product.status === "Verified and Published" &&
      (product.right_image === "null" ||
        product.right_image === null ||
        product.right_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        right_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, right_image: "" }));
    }
    if (
      product.status === "Verified and Published" &&
      (product.back_image === "null" ||
        product.back_image === null ||
        product.back_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        back_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, back_image: "" }));
    }
    if (
      product.status === "Verified and Published" &&
      (product.top_image === "null" ||
        product.top_image === null ||
        product.top_image === undefined)
    ) {
      setErrors((prev) => ({
        ...prev,
        top_image: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, top_image: "" }));
    }

    // product price error
    const productPrice = /^[0-9]+$/;
    const isPrice = productPrice.test(product.product_price);
    if (
      product.status === "Verified and Published" &&
      (product.product_price === "" || product.product_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.product_price !== "" &&
      product.product_price != null &&
      !isPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.product_price !== "" &&
      product.product_price != null &&
      (product.product_price > 100000 || product.product_price < 100)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.valBetween100To100000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        product_price: "",
      }));
    }

    // current_market_price validation
    const marketPrice = /^[0-9]+$/;
    const isOriginalPrice = marketPrice.test(product.current_market_price);
    if (
      product.status === "Verified and Published" &&
      (product.current_market_price === "" ||
        product.current_market_price === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.current_market_price !== "" &&
      product.current_market_price != null &&
      !isOriginalPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.current_market_price !== "" &&
      product.current_market_price != null &&
      (product.current_market_price > 200000 ||
        product.current_market_price < 200)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.valBetween200To200000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        current_market_price: "",
      }));
    }

    // gst validation
    if (
      product.status === "Verified and Published" &&
      (product.gst === "" || product.gst === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (
      product.status === "Verified and Published" &&
      (product.platform_charge === "" ||
        product.platform_charge === null ||
        product.platform_charge_type === "" ||
        product.platform_charge_type === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    // Warehouse validation
    if (
      enableWarehouse &&
      (product.warehouse_id === null || product.warehouse_id === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: "",
      }));
    }

    // length error
    const isValidNumeric = (value) => {
      const numeric = /^[0-9]+$/;
      const isValid = numeric.test(value);
      return isValid;
    };

    if (
      product.status === "Verified and Published" &&
      (product.length === "" || product.length === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    // width error
    if (
      product.status === "Verified and Published" &&
      (product.width === "" || product.width === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric(product.width)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric &&
      (product.width === 0 ||
        product.width === "0" ||
        product.width < 0.1 ||
        product.width > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        width: "",
      }));
    }

    // height error
    if (
      product.status === "Verified and Published" &&
      (product.height === "" || product.height === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      !isValidNumeric(product.height)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      (product.height === 0 ||
        product.height === "0" ||
        product.height < 0.1 ||
        product.height > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        height: "",
      }));
    }

    // weight validation
    if (
      product.status === "Verified and Published" &&
      (product.weight === "" || product.weight === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.weight !== "" &&
      product.weight != null &&
      (product.weight === 0 || product.weight === "0" || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.valBetweenPoint1To20,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        weight: "",
      }));
    }

    // category
    if (
      product.status === "Verified and Published" &&
      product.category_id === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        category_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        category_id: "",
      }));
    }

    // Condition
    if (
      product.status === "Verified and Published" &&
      product.condition === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        condition: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        condition: "",
      }));
    }

    // Age group validation
    if (
      product.status === "Verified and Published" &&
      product.age_group_id === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        age_group_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        age_group_id: "",
      }));
    }

    if (errorHas === true) {
      setEnableAddProductBtn(true);
      return;
    }
    const formData = new FormData();
    // Add meta title and description
    if (product.meta_title) {
      formData.append("meta_title", product.meta_title);
    } else {
      formData.append(
        "meta_title",
        product.name.substring(0, metaTitleMaxLength)
      );
    }
    if (product.meta_description) {
      formData.append("meta_description", product.meta_description);
    } else if (product.description) {
      formData.append("meta_description", removeHtmlTags(product.description));
    }

    // mandatory fields

    formData.append("name", product.name);
    formData.append("status", product.status);

    // Optional fields
    if (product.category_id) {
      formData.append("category_id", product.category_id);
    }
    if (product.condition) {
      formData.append("condition", product.condition);
    }
    if (product.product_price) {
      formData.append("product_price", product.product_price);
    }
    if (product.age_group_id) {
      formData.append("age_group_id", product.age_group_id);
    }
    if (product.thumb_image) {
      formData.append("thumb_image", product.thumb_image);
    }
    if (product.length) {
      formData.append("length", product.length);
    }
    if (product.width) {
      formData.append("width", product.width);
    }
    if (product.height) {
      formData.append("height", product.height);
    }
    if (product.weight) {
      formData.append("weight", product.weight);
    }
    if (product.is_donatable) {
      formData.append("is_donatable", product.is_donatable);
    }
    if (product.is_negotiable) {
      formData.append("is_negotiable", product.is_negotiable);
    }
    // console.log(product.gst);
    if (product.gst >= "0") {
      formData.append("gst", product.gst);
    }
    if (product.platform_charge_type) {
      formData.append("platform_charge_type", product.platform_charge_type);
    }
    if (product.platform_charge) {
      formData.append("platform_charge", product.platform_charge);
    }
    if (product.product_address) {
      formData.append("product_address", product.product_address);
    }
    if (product.product_address && product.product_address === "Warehouse") {
      formData.append("warehouse_id", product.warehouse_id);
    }
    if (product.sku) {
      formData.append("sku", product.sku);
    }
    if (product.barcode) {
      formData.append("barcode", product.barcode);
    }
    if (product.hsn) {
      formData.append("hsn", product.hsn);
    }
    if (product.description) {
      const descriptionWithAmpersand = convertToAmpersand(product.description);
      formData.append("description", descriptionWithAmpersand);
    }
    if (product.brand_id) {
      formData.append("brand_id", product.brand_id);
    }
    if (product.current_market_price) {
      formData.append("current_market_price", product.current_market_price);
    }
    if (product.video_url) {
      formData.append("video_url", product.video_url);
    }

    formData.append("thumb_image", product.thumb_image);
    formData.append("front_image", product.front_image);
    formData.append("left_image", product.left_image);
    formData.append("right_image", product.right_image);
    formData.append("back_image", product.back_image);
    formData.append("top_image", product.top_image);

    if (
      product.status === "Verified and Published" ||
      product.status === "Unverified"
    ) {
      verifyAndPublishProduct(formData);
    } else if (product.status === "Rejected") {
      setProductRejectModal(true);
      setEnableAddProductBtn(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "thumb_image") {
      const file = event.target.files[0];
      setProduct((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (
      name === "font_image" ||
      name === "left_image" ||
      name === "right_image" ||
      name === "back_image" ||
      name === "top_image"
    ) {
      const file = event.target.files[0];
      setImagesURL((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setProduct((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
    if (name === "platform_charge_type" && value !== "") {
      setErrors((prevData) => ({
        ...prevData,
        platform_charge: "",
      }));
    }
  };

  const handleDescriptionChange = (value) => {
    setProduct((prev) => ({
      ...prev,
      description: value,
    }));
    setErrors((prev) => ({
      ...prev,
      description: "",
    }));
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li
            onClick={() => {
              navigate(window.history.back());
            }}
          >
            Products
          </li>
          <li className="active">{pageName} Product</li>
        </div>

        <div className="timeContainerHolder ">
          {pageName === "Edit" && (
            <>
              {product.created_at && (
                <div className="timeContainer">
                  <p className="timeTitle">Created At :</p>
                  <p className="">{formatDateTime(product.created_at)} </p>
                </div>
              )}

              {product.updated_at && (
                <div className="timeContainer">
                  <p className="timeTitle">Updated At :</p>
                  <p className="">{formatDateTime(product.updated_at)} </p>
                </div>
              )}
            </>
          )}
          <button
            type="button"
            onClick={() => {
              navigate(window.history.back());
            }}
            className="primary-btn"
          >
            Product List
          </button>{" "}
        </div>
      </div>
      {/* Product read-only note */}
      {product &&
        (product.status === "Hold" ||
          product.status === "Sold" ||
          product.status === "Unavailable" ||
          product.status === "Donated") && (
          <div className="alert primary-alert">
            You can't save your changes right now because the product details is
            in read-only mode.
          </div>
        )}
      {/* Product denied note */}
      {product && product.status === "Rejected" && product.denied_note && (
        <div className="alert danger-alert">
          Rejection Note : {product.denied_note}
        </div>
      )}

      <div className="page-container">
        <div className="page-content">
          <div className="left-area">
            {/* name, description */}
            <div className="small-box">
              <label>
                Product Name <span className="red">*</span>
              </label>
              <input
                type="text"
                name="name"
                maxLength="150"
                value={product.name}
                onChange={handleInputChange}
                className="form-control"
              />
              {errors.name && <label className="red">{errors.name}</label>}
              <label className="mt-10">
                Description{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <ReactQuill
                name="description"
                value={product.description}
                onChange={handleDescriptionChange}
                theme="snow"
                style={{ height: "fitContent" }}
                className="custom-react-quill"
              />
              {errors.description && (
                <label className="red">{errors.description}</label>
              )}
            </div>

            {/* images, video URL */}
            <div className="small-box">
              <div className="product-images">
                <div>
                  <label>
                    Thumbnail{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.thumb_image) {
                        handleImageUpload("thumb_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.thumb_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.thumb_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.thumb_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.thumb_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("thumb_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("thumb_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      id="thumb_image"
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="thumb_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.thumb_image && (
                    <label className="red">{errors.thumb_image}</label>
                  )}
                </div>
                <div>
                  <label>
                    Front View{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.front_image) {
                        handleImageUpload("front_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.front_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.front_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.front_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.front_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("front_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("front_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="front_image"
                      id="front_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.front_image && (
                    <label className="red">{errors.front_image}</label>
                  )}
                </div>
                <div>
                  <label>
                    Left View{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.left_image) {
                        handleImageUpload("left_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.left_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.left_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.left_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.left_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("left_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("left_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="left_image"
                      id="left_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.left_image && (
                    <label className="red">{errors.left_image}</label>
                  )}
                </div>
                <div>
                  <label>
                    Right View{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.right_image) {
                        handleImageUpload("right_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.right_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.right_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.right_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.right_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("right_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("right_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="right_image"
                      id="right_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.right_image && (
                    <label className="red">{errors.right_image}</label>
                  )}
                </div>
                <div>
                  <label>
                    Back View{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.back_image) {
                        handleImageUpload("back_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.back_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.back_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.back_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.back_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("back_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("back_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="back_image"
                      id="back_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.back_image && (
                    <label className="red">{errors.back_image}</label>
                  )}
                </div>
                <div>
                  <label>
                    Top View{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div
                    onClick={() => {
                      if (!imgContainer.top_image) {
                        handleImageUpload("top_image");
                      }
                    }}
                    className={
                      "image-dropzone " +
                      (imgContainer.top_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.top_image ? (
                      <>
                        <img
                          className="product-image"
                          src={imgContainer.top_image}
                          loading="lazy"
                        />
                        <div className="img-btn-action">
                          <div className="img-btns">
                            {isBase64Image(imgContainer.top_image) && (
                              <button
                                type="button"
                                className="image-edit-icon"
                                onClick={() => {
                                  loadImgOnModal("top_image");
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="image-remove-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeImageFromContainer("top_image");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                              Remove
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="click-to-upload">Click to upload</p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      name="top_image"
                      id="top_image"
                      onChange={handleInputChange}
                      hidden
                    />
                  </div>
                  {errors.top_image && (
                    <label className="red">{errors.top_image}</label>
                  )}
                </div>
              </div>
              <div className="video-url">
                <label className="mt-10">Video URL</label>
                <input
                  type="text"
                  name="video_url"
                  value={product.video_url}
                  onChange={handleInputChange}
                  className="form-control"
                />
                {errors.video_url && (
                  <label className="red">{errors.video_url}</label>
                )}
              </div>
            </div>

            {/* Product Price, Estimated Original Price, Warehouse */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    Product Price (₹){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="product_price"
                    min="100"
                    value={product.product_price}
                    // readOnly={product && product.is_negotiable == 1}
                    onChange={(e) => {
                      if (product && product.is_negotiable === 1) {
                        handleInputChange(e);
                      }
                    }}
                    className="form-control"
                  />
                  {errors.product_price && (
                    <label className="red">{errors.product_price}</label>
                  )}
                </div>
                <div>
                  <label>
                    Estimated Original Price (₹){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="current_market_price"
                    value={product.current_market_price}
                    onChange={(e) => {
                      if (product && product.is_negotiable === 1) {
                        handleInputChange(e);
                      }
                    }}
                    min="200"
                    className="form-control"
                  />
                  {errors.current_market_price && (
                    <label className="red">{errors.current_market_price}</label>
                  )}
                </div>
                <div>
                  <label>
                    Product Address{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    className="form-control"
                    name="product_address"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const val = e.target.value;
                      if (val === "Warehouse") setEnableWarehouse(true);
                      else setEnableWarehouse(false);
                    }}
                    value={product.product_address}
                  >
                    <option value="">Select</option>
                    <option value="Seller">Seller Address</option>
                    <option value="Warehouse">Warehouse</option>
                  </select>
                  {errors.product_address && (
                    <label className="red">{errors.product_address}</label>
                  )}
                </div>
                {enableWarehouse && (
                  <div>
                    <label>
                      Warehouse <span className="red">*</span>
                    </label>
                    <select
                      className="form-control warehouse-select"
                      name="warehouse_id"
                      onChange={handleInputChange}
                      value={product.warehouse_id}
                    >
                      <option value="">Select</option>
                      {warehouseOptionsValue.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.warehouse_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.warehouse_id && (
                      <label className="red">{errors.warehouse_id}</label>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* GST, Platform Charge Type and Value */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    GST Percentage{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    value={product.gst}
                    name="gst"
                    onChange={handleInputChange}
                    className="form-control gst-percentage"
                  >
                    <option value="">Select</option>
                    {Object.entries(gstPercentage).map(([key, value]) => (
                      <option key={key} value={value}>
                        {`${value}%`}
                      </option>
                    ))}
                  </select>
                  {errors.gst && <label className="red">{errors.gst}</label>}
                </div>
                <div>
                  <label>
                    Platform Charges{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div className="platform-charge-area">
                    <select
                      value={product.platform_charge_type}
                      onChange={handleInputChange}
                      name="platform_charge_type"
                      className="form-control platform-charge-type"
                    >
                      <option value="">Select</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                    <input
                      value={product.platform_charge}
                      onChange={handleInputChange}
                      name="platform_charge"
                      type="text"
                      className="form-control platform-charge"
                    />
                  </div>
                  {errors.platform_charge && (
                    <label className="red">{errors.platform_charge}</label>
                  )}
                </div>
                <div>
                  <label>Selling Price (₹)</label>
                  <div className="selling-price-line">
                    <input
                      disabled={true}
                      readOnly={true}
                      type="number"
                      name="selling_price"
                      value={product.selling_price}
                      onChange={handleInputChange}
                      className="form-control"
                      style={{ backgroundColor: "#e7e7e7" }}
                    />
                    <button
                      type="button"
                      className={`btn ${
                        disableCalculate ? "" : "secondary-btn"
                      }`}
                      disabled={disableCalculate}
                      style={{
                        cursor: disableCalculate && "not-allowed",
                        opacity: disableCalculate && "0.5",
                        backgroundColor: disableCalculate && "#E7E7E7",
                      }}
                      onClick={(e) => {
                        calculateSellingPrice();
                      }}
                    >
                      Calculate
                    </button>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            {/* SKU, Barcode, HSN */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>SKU</label>
                  <input
                    type="text"
                    name="sku"
                    className="form-control"
                    value={product.sku}
                    onChange={handleInputChange}
                  />
                  {errors.sku && <label className="red">{errors.sku}</label>}
                </div>
                <div>
                  <label>Barcode</label>
                  <input
                    type="text"
                    name="barcode"
                    className="form-control"
                    value={product.barcode}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label>HSN</label>
                  <input
                    type="text"
                    name="hsn"
                    className="form-control"
                    value={product.hsn}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {/* Length, Width, Height, Weight */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    Box Length (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="length"
                    value={product.length}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.length && (
                    <label className="red">{errors.length}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box width (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="width"
                    value={product.width}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.width && (
                    <label className="red">{errors.width}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box height (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="height"
                    value={product.height}
                    onChange={handleInputChange}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.height && (
                    <label className="red">{errors.height}</label>
                  )}
                </div>
                <div>
                  <label>
                    Weight (kg){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="weight"
                    value={product.weight}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.weight && (
                    <label className="red">{errors.weight}</label>
                  )}
                </div>
              </div>
            </div>

            {/* Product SEO */}
            <div className="small-box">
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaTitleRemaining(metaTitleMaxLength - length);
                    }}
                    className="form-control"
                    type="text"
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    name="meta_description"
                    className="form-control meta-description-input"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaDescRemaining(metaDescMaxLength - length);
                    }}
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="right-area">
            <div className="small-box">
              <label>
                Status <span className="red">*</span>
              </label>
              <select
                name="status"
                className="form-control"
                value={product.status}
                onChange={(e) => {
                  const val = e.target.value;
                  setProduct((prev) => ({
                    ...prev,
                    status: val,
                  }));
                }}
              >
                <option value="">Select</option>
                <option value="Verified and Published">
                  Verified and Published
                </option>
                <option value="Unverified">Unverified</option>
                <option value="Rejected">Rejected</option>
              </select>
              <p style={{ marginTop: "15px" }}>
                Created By{" "}
                <span className="sold-by-label">
                  {sellerData.is_owner ? "Business Seller" : "Domestic Seller"}
                </span>
              </p>
            </div>
            <div className="small-box">
              <label>
                Category{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <select
                className="form-control"
                name="category_id"
                value={product.category_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {categoryData &&
                  categoryData.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
              {errors.category_id && (
                <label className="red">{errors.category_id}</label>
              )}
              <label className="mt-10">Brand</label>
              <select
                className="form-control"
                name="brand_id"
                value={product.brand_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {brandData &&
                  brandData.map((brands, index) => (
                    <option key={index} value={brands.id}>
                      {brands.name}
                    </option>
                  ))}
              </select>
              {errors.brand_id && (
                <label className="red">{errors.brand_id}</label>
              )}
              <label className="mt-10">
                Condition{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <select
                name="condition"
                value={product.condition}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select</option>
                {conditionData &&
                  conditionData.map((conditions) => (
                    <option key={conditions} value={conditions}>
                      {conditions}
                    </option>
                  ))}
              </select>
              {errors.condition && (
                <label className="red">{errors.condition}</label>
              )}
              <div>
                <label className="mt-10">
                  Applicable Age Group{" "}
                  {product.status === "Verified and Published" && (
                    <span className="red">*</span>
                  )}
                </label>
                <select
                  className="form-control"
                  name="age_group_id"
                  value={product.age_group_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {applicableAgeGroupData.map((ageGroup) => (
                    <option key={ageGroup.id} value={ageGroup.id}>
                      {ageGroup.title}
                    </option>
                  ))}
                </select>
                {errors.age_group_id && (
                  <label className="red">{errors.age_group_id}</label>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Save Product btn area*/}
        <div className="product-btn-area">
          <button
            type="button"
            onClick={() => navigate(window.history.back())}
            className="secondary-btn"
          >
            Cancel
          </button>
          {enableAddProductBtn ? (
            <button
              type="button"
              className="primary-btn"
              style={{
                width: "80px",
              }}
              onClick={() => {
                handleButtonSubmit();
              }}
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="disabled-primary-btn"
              style={{
                width: "80px",
              }}
            >
              {/* Save */}
              <LoaderButton />
            </button>
          )}
        </div>
      </div>

      {/* Max Selling Price */}
      {infoModalOpen && (
        <div
          className="modal fade"
          style={{ display: infoModalOpen ? "block" : "none" }}
        >
          <div className="modal-content">
            <h3 className="modal-header">Oops!</h3>
            <p className="modal-line">{modalMessage}</p>
            <div className="modal-buttons">
              <button
                className="btn primary-btn"
                onClick={() => {
                  setInfoModalOpen(false);
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reject success modal */}
      {productRejectModal && (
        <div id="productRejectModal" className="modal fade">
          <div className="modal-content">
            <span
              className="modal-close"
              onClick={() => {
                closeProductRejectModal();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <h3 className="modal-header">Rejection Confirmation</h3>
            <label>Denied Note</label>
            <textarea
              name="denied_note"
              onChange={handleInputChange}
              className="form-control"
              value={product.denied_note}
            />
            <label className="red">{errors.denied_note}</label>
            <div className="modal-buttons">
              <button
                disabled={!enableRejectBtn}
                type="button"
                onClick={() => {
                  rejectedProduct();
                }}
                className={`btn${
                  enableRejectBtn ? " primary-btn" : " disabled-primary-btn"
                }`}
              >
                {enableRejectBtn ? "Reject" : <LoaderButton />}
              </button>
              <button
                type="button"
                className="btn secondary-btn"
                onClick={() => {
                  closeProductRejectModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* image upload modal */}
      {showImgUpModal && (
        <div className="modal fade">
          <div className="img-modal-content">
            <div className="img-modal-header">
              <p>Image upload</p>
              <button
                className="modal-close-icon"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-img-editor">
                <Cropper
                  src={rawImage}
                  ref={cropperRef}
                  initialAspectRatio={1}
                  aspectRatio={rawImgName === "thumb_image" ? 1 : undefined}
                  preview=".img-preview"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  style={{ maxHeight: 350 }}
                />
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    float: "left",
                    height: "300px",
                    border: "1px solid #ced4da",
                    maxHeight: 350,
                  }}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn primary-btn" onClick={cropImage}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* page loading modal */}
      {isLoading && (
        <div className="modal fade">
          <div className="modal-content-loader">
            <img src={loadingImgPath} className="loader-img-size" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerProductEdit;
