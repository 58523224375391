import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import ReactStars from "react-rating-stars-component";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import InputContext from "../../../Components/InputContext";
import "./feedback.css";
import Toast from "../../../Components/Toast";
import Navigation from "../../../config/Navigation.json";
import APIEndpoint from "../../../config/APIEndpoints.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSearch,
  faTrashCan,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Paginate from "../../../Components/Pagination/Paginate";
import stringConstant from "../../../config/ProjectConstants.json";
import StarRating from "../../../Components/Star/star";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const FeedbackList = () => {
  const location = useLocation();
  const [feedbackList, setFeedbackList] = useState([]);
  const [pagination, setPagination] = useState("");
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const { showToast } = Toast();
  const [loading, setLoading] = useState(true);
  const FeedbackPageToShow = 20;
  const [currentPage, setCurrentPage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showEditFeedbackModal, setShowEditFeedbackModal] = useState(false);
  const [isNotAdminEnvironment, setIsNotAdminEnvironment] = useState(false);
  const [editId, SetEditId] = useState(null);
  const [ratingDataDetails, setRatingDataDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    rating: "",
    environment: "Admin",
    is_anonymous: null,
    status: "New",
  });
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const [ratingEditDataDetails, setRatingEditDataDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    rating: "",
    environment: "",
    status: "",
  });
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const handleCheckboxChange = (event) => {
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setIsChecked(event.target.checked);
  };
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    GetFeedbackList(data);
  };

  const handleDeleteClickpopup = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };
  // const Get Banner List
  const GetFeedbackList = async (url) => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }
      const response = await fetch(`${url}&per_page=${FeedbackPageToShow}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 401 && res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 200) {
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        const newdataArray = res.data.data;
        setFeedbackList(newdataArray);
      } else if (res.status === 404) {
        setFeedbackList([]);
        setPagination("");
      }
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetFeedbackList(`${API_HOST}/${APIEndpoint.CrudsforFeedbacks}?page_no=1`);
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        GetFeedbackList(
          `${API_HOST}/${APIEndpoint.CrudsforFeedbacks}?page_no=1`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  // React Table implementation
  const openEditFeedbackModal = (
    id,
    name,
    phone,
    email,
    message,
    rating,
    enviroment,
    status
  ) => {
    // Set rating
    EditRatingChange(rating);

    // Check environment and set state
    if (enviroment === "Admin") {
      setIsNotAdminEnvironment(false);
    } else {
      setIsNotAdminEnvironment(true);
    }

    // Set ID and other values
    SetEditId(id);
    setRatingEditDataDetails((prevData) => ({
      ...prevData,
      name: name,
      phone: phone,
      email: email,
      message: message,
      rating: rating,
      environment: enviroment,
      status: status,
    }));

    // Show modal
    setShowEditFeedbackModal(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * FeedbackPageToShow,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (value == null ? "NA" : value),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (value == null ? "NA" : value),
      },
      {
        Header: "Phone",
        accessor: "phone",
        Cell: ({ value }) => (value == null ? "NA" : value),
      },
      {
        Header: "Rating",
        accessor: "rating",
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          if (value === "New") {
            backgroundColor = "#07bc0c";
            color = "white";
          } else if (value === "In Progress") {
            backgroundColor = "rgb(255, 153, 0)";
            color = "white";
          } else if (value === "Closed") {
            backgroundColor = "#3498db";
            color = "white";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "Type Of User",
        accessor: "environment",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const phone = row.original.phone;
          const email = row.original.email;
          const message = row.original.message;
          const rating = row.original.rating;
          const enviroment = row.original.environment;
          const status = row.original.status;
          return (
            <div>
              <button
                onClick={() => {
                  openEditFeedbackModal(
                    id,
                    name,
                    phone,
                    email,
                    message,
                    rating,
                    enviroment,
                    status
                  );
                }}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                onClick={() => handleDeleteClickpopup(id)}
                className="action-icon trash-button"
                title="Delete"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: feedbackList,
      manualPagination: true,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  // Custom filtering function excluding the 'id' column
  const customFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      return Object.entries(row.values).some(([key, value]) => {
        return (
          key !== id &&
          String(value).toLowerCase().includes(filterValue.toLowerCase())
        );
      });
    });
  };

  // Apply the custom filter when setting the global filter
  const handleSetFilter = (value) => {
    const filteredRows = customFilter(rows, "id", value);
    setGlobalFilter(value, filteredRows);
  };

  const ratingChanged = (newRating) => {
    setRatingError("");
    setRatingDataDetails((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
  };

  // Edit Rating Change
  const EditRatingChange = (newRating) => {
    setRatingEditDataDetails((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
  };
  // Delete Feedback
  const token = getDataFromCookies("7b9KpQ3rW6");
  const handleConfirmDelete = (id) => {
    fetch(`${API_HOST}/${APIEndpoint.CrudsforFeedbacks}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          showToast(
            stringConstant.alertErrorMessage.alertseveritysuccess,
            stringConstant.alertErrorMessage.alertforDeleteFeedback
          );
          handleCancelDelete();
          GetFeedbackList(
            `${API_HOST}/${APIEndpoint.CrudsforFeedbacks}?page_no=1`
          );
        } else if (
          response.status === 401 &&
          response.status === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status === 401 && response.message !== "") {
          showToast("error", response.message);
        } else if (response.status === 409 && response.message !== "") {
          showToast("error", response.message);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };

  const handleCloseFeedback = () => {
    setShowFeedbackModal(false);
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setDescriptionError("");
    setRatingError("");
    setIsChecked(false);
    setRatingDataDetails((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      message: "",
      rating: "",
      environment: "Admin",
      is_anonymous: null,
    }));
  };
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value === "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "message") {
      setDescriptionError("");
      if (value.trim() !== "" || value === "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      setPhoneError("");
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      if (value.trim() !== "" || value === "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else if (name === "name") {
      setNameError("");
      if (value && /^[A-Za-z\s]+$/.test(value)) {
        if (value.trim() !== "" || value === "") {
          setRatingDataDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (value.trim() === "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
    }
  };
  const openAddFeedbackModal = () => {
    setShowFeedbackModal(true);
  };
  const createFeedback = async () => {
    let errorHas = false;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (isChecked) {
      if (ratingDataDetails.name !== "" && ratingDataDetails.name != null) {
        if (
          ratingDataDetails.name.length < 2 ||
          ratingDataDetails.name.length > 50
        ) {
          setNameError("Name should be between 2 and 50 characters.");
          errorHas = true;
        } else {
          setNameError("");
        }
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        if (!isPhoneNumber) {
          setPhoneError("Please enter a valid Phone.");
          errorHas = true;
        } else {
          setPhoneError("");
        }
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        if (!isEmail) {
          setEmailError("Please enter a valid Email.");
          errorHas = true;
        } else if (
          ratingDataDetails.email.length < 5 ||
          ratingDataDetails.email.length > 100
        ) {
          setEmailError("Email should be between 3 and 150 characters.");
          errorHas = true;
        } else {
          setEmailError("");
        }
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    } else {
      if (ratingDataDetails.name === "" || ratingDataDetails.name === null) {
        setNameError("Name is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.name.length < 2 ||
        ratingDataDetails.name.length > 50
      ) {
        setNameError("Name should be between 2 and 50 characters.");
        errorHas = true;
      } else {
        setNameError("");
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone === "" || ratingDataDetails.phone === null) {
        setPhoneError("Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber) {
        setPhoneError("Please enter a valid Phone.");
        errorHas = true;
      } else {
        setPhoneError("");
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email === "" || ratingDataDetails.email === null) {
        setEmailError("Email is required.");
        errorHas = true;
      } else if (!isEmail) {
        setEmailError("Please enter a valid Email.");
        errorHas = true;
      } else if (
        ratingDataDetails.email.length < 5 ||
        ratingDataDetails.email.length > 100
      ) {
        setEmailError("Email should be between 3 and 150 characters.");
        errorHas = true;
      } else {
        setEmailError("");
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    }
    if (errorHas === true) {
      return;
    }
    const payload = {};
    if (isChecked) {
      if (ratingDataDetails.name !== "" && ratingDataDetails.name != null) {
        payload.name = ratingDataDetails.name;
      }
      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        payload.phone = ratingDataDetails.phone;
      }
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        payload.email = ratingDataDetails.email;
      }
      payload.is_anonymous = true;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    } else {
      payload.is_anonymous = false;
      payload.name = ratingDataDetails.name;
      payload.phone = ratingDataDetails.phone;
      payload.email = ratingDataDetails.email;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
      payload.status = ratingDataDetails.status;
    }
    const response = await fetch(`${API_HOST}/${APIEndpoint.createFeedback}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 201) {
      showToast(
        "success",
        "Thank you for your feedback. Your Feedback is valued and appreciated by JOYREJOY"
      );
      handleCloseFeedback();
      GetFeedbackList(`${API_HOST}/${APIEndpoint.CrudsforFeedbacks}?page_no=1`);
    } else if (res.status === 409) {
    } else if (res.status === 404) {
    } else if (res.status === 400) {
    } else if (res.status === 403) {
    } else {
    }
  };
  const handleCloseEditFeedback = () => {
    setShowEditFeedbackModal(false);
    setStatusError("");
    setRatingEditDataDetails((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      message: "",
      rating: "",
      environment: "",
    }));
  };

  const handleEditChange = (event) => {
    let { name, value } = event.target;
    if (name === "status") {
      setStatusError("");
      if (value.trim() !== "" || value === "") {
        setRatingEditDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value === "") {
        setRatingEditDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "message") {
      setDescriptionError("");
      if (value.trim() !== "" || value === "") {
        setRatingEditDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      setPhoneError("");
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      if (value.trim() !== "" || value === "") {
        setRatingEditDataDetails((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else if (name === "name") {
      setNameError("");
      if (value && /^[A-Za-z\s]+$/.test(value)) {
        if (value.trim() !== "" || value === "") {
          setRatingEditDataDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (value.trim() === "") {
        setRatingEditDataDetails((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
    }
  };

  const EditFeedback = async (id) => {
    let errorHas = false;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!isNotAdminEnvironment) {
      if (
        ratingEditDataDetails.name === "" ||
        ratingEditDataDetails.name === null
      ) {
        setNameError("Name is required.");
        errorHas = true;
      } else if (
        ratingEditDataDetails.name.length < 2 ||
        ratingEditDataDetails.name.length > 50
      ) {
        setNameError("Name should be between 2 and 50 characters.");
        errorHas = true;
      } else {
        setNameError("");
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingEditDataDetails.phone);

      if (
        ratingEditDataDetails.phone === "" ||
        ratingEditDataDetails.phone === null
      ) {
        setPhoneError("Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber) {
        setPhoneError("Please enter a valid Phone.");
        errorHas = true;
      } else {
        setPhoneError("");
      }

      const isEmail = emailRegex.test(ratingEditDataDetails.email);
      if (
        ratingEditDataDetails.email === "" ||
        ratingEditDataDetails.email === null
      ) {
        setEmailError("Email is required.");
        errorHas = true;
      } else if (!isEmail) {
        setEmailError("Please enter a valid Email.");
        errorHas = true;
      } else if (
        ratingEditDataDetails.email.length < 5 ||
        ratingEditDataDetails.email.length > 100
      ) {
        setEmailError("Email should be between 3 and 150 characters.");
        errorHas = true;
      } else {
        setEmailError("");
      }

      if (
        ratingEditDataDetails.message === "" ||
        ratingEditDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingEditDataDetails.message.length < 10 ||
        ratingEditDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingEditDataDetails.rating === "" ||
        ratingEditDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }

      if (
        ratingEditDataDetails.status === "" ||
        ratingEditDataDetails.status === null
      ) {
        setStatusError("Status is required.");
        errorHas = true;
      }
    } else {
      if (
        ratingEditDataDetails.status === "" ||
        ratingEditDataDetails.status === null
      ) {
        setStatusError("Status is required.");
        errorHas = true;
      }
    }
    if (errorHas === true) {
      return;
    }
    const payload = {};
    payload.name = ratingEditDataDetails.name;
    payload.phone = ratingEditDataDetails.phone;
    payload.email = ratingEditDataDetails.email;
    payload.message = ratingEditDataDetails.message;
    payload.rating = ratingEditDataDetails.rating;
    payload.environment = ratingEditDataDetails.environment;
    payload.status = ratingEditDataDetails.status;

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.CrudsforFeedbacks}/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", "Thank you for Updating the feedback.");
      handleCloseEditFeedback();
      GetFeedbackList(`${API_HOST}/${APIEndpoint.CrudsforFeedbacks}?page_no=1`);
    } else if (res.status === 409) {
    } else if (res.status === 404) {
    } else if (res.status === 400) {
    } else if (res.status === 403) {
    } else {
    }
  };

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Feedbacks</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              openAddFeedbackModal();
            }}
            className="btn primary-btn"
          >
            Add Feedback
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="InnerTableContainer">
          <table
            id="categoryTable"
            className="order-table"
            {...getTableProps()}
          >
            <thead className="Category-grid-container-item2-tableHead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="Category-grid-container-item2-tableHead">
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          {pagination && (
            <Paginate pagination={pagination} pageChange={handleURLChange} />
          )}
        </div>

        {/* Delete modal */}
        {showDeleteModal && (
          <>
            <div
              id="deleteModal"
              className="modal fade"
              style={{ display: showDeleteModal ? "block" : "none" }}
            >
              <div className="modal-content">
                <span className="close-icon" onClick={handleCancelDelete}>
                  <FontAwesomeIcon icon={faX} />
                </span>
                <h3 className="modal-header">Delete Confirmation</h3>
                <p className="modal-line">
                  Are you sure you want to delete this Feedback?
                </p>
                <div className="modal-buttons">
                  <button
                    className="delete-yes-button"
                    onClick={() => handleConfirmDelete(deleteId)}
                  >
                    Yes, Delete It!
                  </button>
                  <button
                    className="btn-secondary-btn"
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Feedback modal */}
        {showFeedbackModal && (
          <div className="Feedback_modal_container">
            <div className="Feedback_modal_c2">
              <div className="cross_button_container">
                <button className="cross_button" onClick={handleCloseFeedback}>
                  <FontAwesomeIcon style={{ color: "#595959" }} icon={faX} />
                </button>
              </div>
              <h1 id="Feedback_Heading"> Add Feedback</h1>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleChange}
                    name="name"
                    placeholder={`Name ${isChecked ? "(optional)" : ""}`}
                    maxLength={50}
                    value={ratingDataDetails.name}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Name {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{nameError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleChange}
                    name="phone"
                    placeholder={`Phone ${isChecked ? "(optional)" : ""}`}
                    maxLength={10}
                    value={ratingDataDetails.phone}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Phone {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{phoneError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleChange}
                    name="email"
                    placeholder={`Email ${isChecked ? "(optional)" : ""}`}
                    maxLength={50}
                    value={ratingDataDetails.email}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Email {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{emailError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <textarea
                    type="text"
                    className="email-phone-input login_input_tag footer-textarea"
                    onChange={handleChange}
                    maxLength={500}
                    minLength={10}
                    name="message"
                    placeholder="Tell us more about your experience."
                    value={ratingDataDetails.message}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Tell us more about your experience.
                  </span>
                </label>
                <li className="fotter-error">{descriptionError}</li>
              </div>
              <div>
                <p className="rate_your_experience">
                  How would you rate your experience?
                </p>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  isHalf={false}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ff9f00"
                />
                <li className="fotter-error">{ratingError}</li>
              </div>
              <button onClick={createFeedback} className="submit-button">
                Submit
              </button>
            </div>
          </div>
        )}

        {/* Feedback edit modal */}
        {showEditFeedbackModal && (
          <div className="Feedback_modal_container">
            <div className="Feedback_modal_c2">
              <div className="cross_button_container">
                <button
                  className="cross_button"
                  onClick={handleCloseEditFeedback}
                >
                  <FontAwesomeIcon style={{ color: "#595959" }} icon={faX} />
                </button>
              </div>
              <h1 id="Feedback_Heading"> Edit Feedback</h1>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleEditChange}
                    name="name"
                    placeholder={`Name ${isChecked ? "(optional)" : ""}`}
                    maxLength={50}
                    value={ratingEditDataDetails.name}
                    disabled={isNotAdminEnvironment}
                    style={{
                      opacity: isNotAdminEnvironment ? 0.5 : 1,
                    }}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Name {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{nameError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleEditChange}
                    name="phone"
                    placeholder={`Phone ${isChecked ? "(optional)" : ""}`}
                    maxLength={10}
                    value={ratingEditDataDetails.phone}
                    disabled={isNotAdminEnvironment}
                    style={{
                      opacity: isNotAdminEnvironment ? 0.5 : 1,
                    }}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Phone {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{phoneError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <input
                    type="text"
                    className="email-phone-input login_input_tag"
                    onChange={handleEditChange}
                    name="email"
                    placeholder={`Email ${isChecked ? "(optional)" : ""}`}
                    maxLength={50}
                    value={ratingEditDataDetails.email}
                    disabled={isNotAdminEnvironment}
                    style={{
                      opacity: isNotAdminEnvironment ? 0.5 : 1,
                    }}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Email {isChecked ? "(optional)" : " "}
                  </span>
                </label>
                <li className="fotter-error">{emailError}</li>
              </div>
              <div>
                <label className="login-label-tag">
                  <textarea
                    type="text"
                    className="email-phone-input login_input_tag footer-textarea"
                    onChange={handleEditChange}
                    maxLength={500}
                    minLength={10}
                    name="message"
                    placeholder="Tell us more about your experience."
                    value={ratingEditDataDetails.message}
                    disabled={isNotAdminEnvironment}
                    style={{
                      backgroundColor: "white",
                      opacity: isNotAdminEnvironment ? 0.5 : 1,
                    }}
                  />
                  <span className="login-Span-tags">
                    {" "}
                    Tell us more about your experience.
                  </span>
                </label>
                <li className="fotter-error">{descriptionError}</li>
              </div>
              <div>
                <p className="rate_your_experience">
                  How would you rate your experience?
                </p>
                {isNotAdminEnvironment ? (
                  <>
                    <StarRating
                      style={{
                        opacity: isNotAdminEnvironment ? 0.5 : 1,
                      }}
                      rating={ratingEditDataDetails.rating}
                    />
                  </>
                ) : (
                  <>
                    <ReactStars
                      count={5}
                      value={ratingEditDataDetails.rating}
                      onChange={EditRatingChange}
                      disabled={isNotAdminEnvironment ? true : false}
                      size={40}
                      isHalf={false}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ff9f00"
                    />
                  </>
                )}
                <li className="fotter-error">{ratingError}</li>
              </div>

              <div>
                <label className="Admin_user_register_label">
                  <select
                    name="status"
                    className="Admin_user_register_input"
                    placeholder="Status"
                    value={ratingEditDataDetails.status}
                    onChange={handleEditChange}
                  >
                    <option value="">Select</option>
                    <option value="New">New </option>
                    <option value="In Progress">In Progress</option>
                    <option value="Closed">Closed</option>
                  </select>
                  <span className="Admin_user_register_span">Status</span>
                </label>
                <li className="fotter-error">{statusError}</li>
              </div>
              <button
                onClick={() => {
                  EditFeedback(editId);
                }}
                className="submit-button"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackList;
