import React, { useEffect, useMemo, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  faArrowLeft,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import SellerTopNavigation from "./SellerNavigation";
import GlobalFilter from "../../../Components/GlobalFilter";
import "./css/orders.css";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerOrders = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { sellerId } = useParams();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ShowNoProduct, setShowNoProduct] = useState(false);
  const [orderCount, setOrderCount] = useState({});
  const { showToast } = Toast();
  const sellerOrderShowToPerPage = 10;
  const [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    sellerOrders(data);
  };

  const token = getDataFromCookies("7b9KpQ3rW6");

  const sellerOrders = async (url) => {
    const response = await fetch(
      `${url}&per_page=${sellerOrderShowToPerPage}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      setOrders(res.data.orders.data);
      setOrderCount(res.data.count);
      setPagination(res.data.orders);
      setCurrentPage(res.data.orders.current_page);
    } else if (res.status === 404) {
      setShowNoProduct(true);
      setPagination("");
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };
  useEffect(() => {
    sellerOrders(
      `${API_HOST}/api/admin/user/sellers/${sellerId}/orders?page_no=1`
    );
  }, []);

  // Navigate to OrderDetailsPage
  const handleViewOrderPage = (orderId) => {
    navigate(`/order-details/${orderId}`);
    return;
  };

  function formatNumberWithCommas(number) {
    if (number === null || number === undefined) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * sellerOrderShowToPerPage,
      },
      {
        Header: "Order ID",
        accessor: "order_number",
      },

      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>
            <p>{new Date(value).toLocaleDateString()}</p>
            <p>{new Date(value).toLocaleTimeString()}</p>
          </>
        ),
      },
      {
        Header: "Total Amount",
        Footer: "Total Amount",
        accessor: "order_amount",
        Cell: ({ value }) => (
          <div className="cell-container">
            ₹{formatNumberWithCommas(Math.round(value))}
          </div>
        ),
      },
      {
        Header: "Order Status",
        accessor: "order_status",
        Cell: ({ value }) => (
          <>
            {value === "Pending" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffe082" }}
              >
                Pending
              </p>
            ) : value === "Delivered" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "green", color: "white" }}
              >
                Delivered
              </p>
            ) : value === "Confirmed" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#c3e5a0" }}
              >
                Confirmed
              </p>
            ) : value === "Out For Delivery" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", whiteSpace: "nowrap" }}
              >
                Out For Delivery
              </p>
            ) : value === "Cancelled" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffbcbc", whiteSpace: "nowrap" }}
              >
                Cancelled
              </p>
            ) : (
              <p
                className="order-status"
                style={{ backgroundColor: "#cbcbcb" }}
              >
                {value}
              </p>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <span>
            <button
              onClick={() => handleViewOrderPage(row.original.id)}
              className="action-icon eye-button"
              title="view"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </span>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: orders,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <SellerTopNavigation />
      <div className="page-container">
        {/* Data counter */}
        <div className="data-counter">
          <div className="counter-item">
            <p>All Orders</p>
            <p>{orderCount.total_orders}</p>
          </div>
          <div className="counter-item">
            <p>Pending Orders</p>
            <p>{orderCount.pending_orders}</p>
          </div>
          <div className="counter-item">
            <p>Delivered Orders</p>
            <p>{orderCount.delivered_orders}</p>
          </div>
        </div>

        {/* Filter and Button */}
        <div className="table-serach-area">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>

        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : ShowNoProduct ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={"Seller Orders"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SellerOrders;
