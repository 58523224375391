import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../../Components/Cookie";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputContext from "../../../../Components/InputContext";
import "./role.css";
import Toast from "../../../../Components/Toast";

let API_HOST = process.env.REACT_APP_HOST_API_URL;

const AddRole = ({ closeAddModal }) => {
  const [roleName, setRoleName] = useState({
    name: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const ADMIN_LOGIN_TOKEN = getDataFromCookies("7b9KpQ3rW6");
  const [users, setUsers] = useState([]);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [errorForName, setErrorForName] = useState("");
  const [errorForPermission, setErrorForPermission] = useState("");
  const [showAddPopup, setShowAddPopup] = useState(false);
  const { showToast } = Toast();

  // const nameRegex = /^[A-Za-z\s]*$/;
  const isValidName = (value) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(value);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setErrorForName("");
    if (name === "name") {
      let validName = isValidName(value);
      if (value.trim() !== "" || value === "") {
        if (value === "" || (validName && name.length <= 80)) {
          setRoleName((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
    }
  };

  const VerifiedRolePermission = async () => {
    await fetch(`${API_HOST}/${APIEndpoint.getRolePermission}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ADMIN_LOGIN_TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data;
          setUsers(dataArray);
          setShowAddPopup(true);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    VerifiedRolePermission();
  }, []);

  function ListOfValidRole(users) {
    let array = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].isChecked === true) {
        array.push(users[i].id);
      }
    }
    return array;
  }
  function replaceSpaceAndLowercase(inputString) {
    // Replace spaces with underscores
    let modifiedString = inputString.replace(/ /g, "_");
    // Convert all letters to lowercase
    modifiedString = modifiedString.toLowerCase();
    return modifiedString;
  }
  const createNewRole = async () => {
    const permission_id = ListOfValidRole(users);
    let error = false;
    if (roleName.name === "") {
      setErrorForName("Please Enter Role Name.");
      error = true;
    }
    if (permission_id.length < 1) {
      setErrorForPermission("Please Select the Module Permission.");
      error = true;
    }
    if (error === true) {
      return;
    }
    const name = replaceSpaceAndLowercase(roleName.name);
    let Payload = {
      display_name: roleName.name,
      name: name,
      permission_id: permission_id,
    };
    Payload = JSON.stringify(Payload);
    await fetch(`${API_HOST}/${APIEndpoint.createAdminRole}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ADMIN_LOGIN_TOKEN}`,
      },
      body: Payload,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 201) {
          showToast("success", "New Admin User Role created successfully");
          setDataToCookies("isAddedRole", true);
          closeAddModal();
        } else if (res.status === 400) {
          if (res.errors.name) {
            setErrorForName(res.errors.name[0]);
          }
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  const changeCheckboxStatus = (e, id) => {
    setErrorForPermission("");
    const myUsers = [...users];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "p1") {
        setIsParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user.id === id) {
          user.isChecked = checked;
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });

    setUsers([...myUsers]);
  };

  return (
    <>
      {showAddPopup && (
        <div className="Add_Role_Admin_main_C1">
          <div className="Add_Role_Admin_Main_c1_b1">
            <div className="Role_setup_heading">
              <h1 className="Addrole_Heading"> Add Role</h1>
              <span
                className="Add_role_cross_icon"
                onClick={() => closeAddModal()}
              >
                <FontAwesomeIcon icon={faX} style={{ color: "#9e9e9e" }} />
              </span>
            </div>

            {/* <div className="AdminRoleSetup-grid-container-item1"> */}

            <label className="Role_Setup_lable_tag">
              <input
                type="text"
                className="Role_Setup_input_tag"
                value={roleName.name}
                name="name"
                onChange={handleChange}
                placeholder="Role Name"
              />
              <span className="Role_Setup_span_tag">Role Name</span>
            </label>
            <li className="error_for_name">{errorForName}</li>

            <h1 className="role_setup_Model_permission_heading">
              Module Permission
            </h1>
            <div className="Role_C1_B1_X1">
              <input
                type="checkbox"
                value="parent"
                onChange={(e) => changeCheckboxStatus(e, "p1")}
                checked={isParentChecked}
              />
              <label className="add_role_select_all_text">Select All</label>
            </div>
            {users &&
              users.map((user) => {
                return (
                  <div className="add_role_permission_child_div" key={user.id}>
                    <div className="Role_C1_B1_X1">
                      <input
                        type="checkbox"
                        checked={user?.isChecked}
                        value="child"
                        onChange={(e) => changeCheckboxStatus(e, user.id)}
                      />
                      <span className="role-name-label">
                        <label className="add_role_lable_display">
                          {user.display_name}
                        </label>
                      </span>
                    </div>
                  </div>
                );
              })}
            <li className="error_for_name"> {errorForPermission}</li>
            {/* </div> */}
            <div className="modal-buttons">
              <button className="btn secondary-btn" onClick={closeAddModal}>
                Cancel
              </button>
              <button className="btn primary-btn" onClick={createNewRole}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddRole;
