import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate, useLocation } from "react-router-dom";
import InputContext from "../../../Components/InputContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import "./ngo.css";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import LoaderButton from "../../../Components/LoaderButton";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import placeHolder from "../../../Assets/image-placeholder.png";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const NGOs = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = new useNavigate();
  const NgoPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  let [totalCount, setTotalCount] = useState("");
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const { showToast } = Toast();

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    fetchData(data);
  };

  const fetchData = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    await fetch(`${url}&per_page=${NgoPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data.data;
          setData(dataArray);
          setPagination(res.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalCount(res.data.total);
        } else if (res.status === 404) {
          setData([]);
          setPagination("");
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error(res);
        }
        setSearchLoading(false);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    currentPage = sessionStorage.getItem("CurrentPageNgo");
    lastPage = sessionStorage.getItem("lastPageNgo");
    totalCount = sessionStorage.getItem("totalCountNgo");
    const isAddedNgo = getDataFromCookies("isAddedNgo");
    const isEditedNgo = getDataFromCookies("isEditedNgo");
    if (currentPage == null) {
      currentPage = 1;
    }
    if (isAddedNgo) {
      lastPage = totalCount % NgoPerPage === 0 ? lastPage + 1 : lastPage;
      fetchData(`${API_HOST}/${APIEndpoint.ngos}?page_no=${lastPage}`);
      removeDataFromCookie("isAddedNgo");
      sessionStorage.removeItem("CurrentPageNgo");
      sessionStorage.removeItem("lastPageNgo");
      sessionStorage.removeItem("totalCountNgo");
    } else if (isEditedNgo) {
      fetchData(`${API_HOST}/${APIEndpoint.ngos}?page_no=${currentPage}`);
      removeDataFromCookie("isEditedNgo");
      sessionStorage.removeItem("CurrentPageNgo");
    } else {
      fetchData(`${API_HOST}/${APIEndpoint.ngos}?page_no=${currentPage}`);
    }
  }, []);

  const handleConfirmDelete = (id) => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    fetch(`${API_HOST}/${APIEndpoint.ngos}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          setLoadingButton(false);
        }
        if (response.status === 200) {
          setDeleteItemId(null);
          setShowDeleteSuccessModal(true);
          if (currentPage === lastPage) {
            currentPage =
              totalCount % NgoPerPage === 1 ? currentPage - 1 : currentPage;
            fetchData(`${API_HOST}/${APIEndpoint.ngos}?page_no=${currentPage}`);
          } else {
            fetchData(`${API_HOST}/${APIEndpoint.ngos}?page_no=${currentPage}`);
          }
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };

  const handleCancelDelete = () => {
    setDeleteItemId(null);
  };

  const handleDeleteClickpopup = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  function handleEdit(id) {
    sessionStorage.setItem("CurrentPageNgo", currentPage);
    navigate(`/ngo/edit/${id}`);
    return;
  }

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * NgoPerPage, // Adding 1 to get a 1-based serial number
      },
      {
        Header: "Image",
        Footer: "Image",
        accessor: "image",
        Cell: ({ value }) => (
          <img
            className="ngoImage"
            src={imgHost + "/" + value}
            alt="Image"
            onError={(e) => {
              e.target.src = placeHolder;
            }}
          />
        ),
      },
      {
        Header: "NGO Name",
        Footer: "NGO Name",
        accessor: "org_name",
      },

      {
        Header: "Contact",
        Footer: "Contact",
        Cell: ({ row }) => (
          <>
            <p>{row.original.org_email}</p>
            <p>{row.original.org_phone}</p>
          </>
        ),
      },
      {
        Header: "Certificate",
        accessor: "certificate_no",
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          if (value === "Block") {
            return (
              <p
                style={{
                  display: "inline-block",
                  margin: 0,
                  borderRadius: "4px",
                  padding: "2px 5px",
                  color: "white",
                  backgroundColor: "#db3d3d",
                }}
              >
                {value}
              </p>
            );
          } else if (value === "Unblock") {
            return (
              <p
                style={{
                  display: "inline-block",
                  margin: 0,
                  borderRadius: "4px",
                  padding: "2px 5px",
                  color: "white",
                  backgroundColor: "green",
                }}
              >
                Active
              </p>
            );
          }
          return value;
        },
      },

      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.organization_name;
          const isDeleting = deleteItemId === id;
          return isDeleting ? (
            <div>
              {/* Product delete modal */}
              <>
                {showDeleteModal && (
                  <div
                    id="deleteModal"
                    className="modal fade"
                    style={{ display: showDeleteModal ? "block" : "none" }}
                  >
                    <div className="modal-content">
                      <span className="close" onClick={handleCancelDelete}>
                        &times;
                      </span>
                      <h3 className="modal-header">Delete Confirmation</h3>
                      <p className="modal-line">
                        Are you sure you want to delete{" "}
                        <span className="deleteitemsNameappPages">{name}</span>{" "}
                        item?
                      </p>
                      <div className="modal-buttons">
                        <button
                          className="btn primary-btn"
                          onClick={() => {
                            setLoadingButton(true);
                            handleConfirmDelete(id);
                          }}
                        >
                          {loadingButton ? <LoaderButton /> : "Yes, delete it!"}
                        </button>
                        <button
                          className="btn secondary-btn"
                          onClick={handleCancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <button
                    onClick={() => handleEdit(id)}
                    className="action-icon edit-button"
                    title="Edit"
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>

                  <button
                    onClick={() => handleDeleteClickpopup(id)}
                    className="action-icon trash-button"
                    title="Delete"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </div>
              </>
            </div>
          ) : (
            <div>
              <button
                onClick={() => handleEdit(id)}
                className="action-icon edit-button"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>

              <button
                onClick={() => handleDeleteClickpopup(id)}
                className="action-icon trash-button"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [deleteItemId, showDeleteSuccessModal, currentPage] // Add deleteItemId as a dependency
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage, // Corrected function name
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);

        currentPage = 1;
        let url = `${API_HOST}/${APIEndpoint.ngos}?page_no=${currentPage}`;
        fetchData(url);
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">NGO</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
          <button
            type="button"
            className="primary-btn"
            onClick={() => {
              sessionStorage.setItem("CurrentPageNgo", currentPage);
              sessionStorage.setItem("lastPageNgo", lastPage);
              sessionStorage.setItem("totalCountNgo", totalCount);
              navigate("/ngo/new");
            }}
          >
            New NGO
          </button>
        </div>

        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* table body logic where data appends */}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No NGO available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"NGOs"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NGOs;
