import React, { useState, useEffect } from "react";
import { TbArrowDown, TbArrowUp } from "react-icons/tb";
import "./CustomDropdown.css"; // Import your CSS file

const CustomDropdown = ({
  options,
  onSelect,
  selectedRole,
  hasMoreRoles,
  fetchMoreAdminRoles,
  loadingMoreRoles,
  setLoadingMoreRoles,
}) => {
  let [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const selected = options.find((option) => option.id === selectedRole);
    if (selected) {
      setSelectedOption(selected.display_name);
    }
  }, [selectedRole, options]);
  const handleSelect = (option) => {
    setSelectedOption(option.display_name);
    setIsOpen(false);
    onSelect(option.id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".custom-dropdown-container") === null) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className="custom-dropdown-container">
      <button className="custom-dropdown-button" onClick={handleToggle}>
        {selectedOption || "Select Role"}
      </button>
      <span
        style={{
          position: "absolute",
          right: "10px",
          color: "#a6a6a6",
          top: "12px",
        }}
      >
        {isOpen ? <TbArrowUp /> : <TbArrowDown />}
      </span>
      {isOpen && (
        <div>
          <div className="custom-dropdown-menu">
            {options.map((option) =>
              option.display_name === "Master Admin Role" ? null : (
                <div
                  key={option.id}
                  className="custom-dropdown-item"
                  onClick={(e) => {
                    handleSelect(option);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {option.display_name}
                </div>
              )
            )}
            {hasMoreRoles && (
              <div
                className="custom-dropdown-item"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (!loadingMoreRoles) {
                    setLoadingMoreRoles(true);
                    await fetchMoreAdminRoles(hasMoreRoles);
                  }
                }}
                style={{
                  color: "#ea5e3d",
                  textDecoration: "underline",
                }}
              >
                {loadingMoreRoles ? "Loading..." : "Load more..."}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
