import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./css/product.css";
import { useParams, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPenToSquare,
  faTrashCan,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import GlobalFilter from "../../../Components/GlobalFilter";
import SellerTopNavigation from "./SellerNavigation";
import InputContext from "../../../Components/InputContext";
import Navigation from "../../../config/Navigation.json";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerProducts = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const { showToast } = Toast();
  const navigate = useNavigate();
  const { sellerId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [countofAllProducts, setCountofAllProducts] = useState({});
  const [ShowNoProduct, setShowNoProduct] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState("");
  const [deletingItemName, setDeletingItemName] = useState("");
  const NumberOfSellerProductsPerPage = 10;

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    let url = `${data}&per_page=${NumberOfSellerProductsPerPage}`;
    SellersProduct(url);
  };

  let url = `${API_HOST}/api/admin/user/sellers/${sellerId}/products?page=${currentPage}&per_page=${NumberOfSellerProductsPerPage}`;
  const SellersProduct = async (url) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      setProducts(res.data.product.data);
      setPagination(res.data.product);
      setCountofAllProducts(res.data.count);
      setCurrentPage(res.data.product.current_page);
    } else if (res.status === 404) {
      setShowNoProduct(true);
      setPagination("");
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };
  useEffect(() => {
    SellersProduct(url);
  }, []);

  const handleConfirmDelete = async (id) => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(
      `${API_HOST}/api/admin/seller/products/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", "Product deleted successfully");
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const openDeleteModal = (id, name) => {
    setDeleteItemId(id);
    setDeletingItemName(name);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteItemId("");
    setDeletingItemName("");
    setShowDeleteModal(false);
  };

  const openProductDetails = (slug, action) => {
    if (action === "view") {
      navigate(`${Navigation.sellerProducts}/${slug}`);
    } else if (action === "edit") {
      navigate(`${Navigation.sellerProductEdit}/${slug}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * NumberOfSellerProductsPerPage,
      },

      {
        Header: "Image",
        Footer: "Image",
        accessor: "thumb_image",
        Cell: ({ value }) => <img src={`${imgHost + "/" + value}`} />,
      },
      {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <span title={value}>
            {value && value.length > 50 ? `${value.slice(0, 40)}...` : value}
          </span>
        ),
      },
      {
        Header: "Product Price",
        Footer: "Product Price",
        accessor: "product_price",
        Cell: ({ value }) => <div className="cell-container">₹{value}</div>,
      },
      {
        Header: "Selling Price",
        Footer: "Selling Price",
        accessor: "selling_price",
        Cell: ({ value }) => (
          <div className="cell-container">
            {value && "₹"}
            {value}
          </div>
        ),
      },
      {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          if (value === "Unverified") {
            backgroundColor = "#F8D294";
            color = "#000000";
          } else if (value === "Verified and Published") {
            backgroundColor = "#4CAF50";
            color = "white";
          } else if (value === "Rejected") {
            backgroundColor = "#FF0000";
            color = "white";
          } else if (value === "Hold") {
            backgroundColor = "#FFA500";
            color = "white";
          } else if (value === "Sold") {
            backgroundColor = "#2196F3";
            color = "white";
          } else if (value === "Donated") {
            backgroundColor = "#9C27B0";
            color = "white";
          } else if (value === "Disabled") {
            backgroundColor = "#0D47A1";
            color = "white";
          } else if (value === "Unavailable") {
            backgroundColor = "#606060";
            color = "white";
          } else if (value === "Blocked") {
            backgroundColor = "#ea5e3d";
            color = "white";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const status = row.original.status;
          const slug = row.original.slug;
          if (
            status === "Hold" ||
            status === "Sold" ||
            status === "Donated" ||
            status === "Unavailable"
          ) {
            return (
              <button
                type="button"
                onClick={() => openProductDetails(slug, "view")}
                className="action-icon eye-button"
                title="View"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            );
          } else {
            return (
              <>
                <button
                  type="button"
                  onClick={() => openProductDetails(slug, "edit")}
                  className="action-icon edit-button"
                  title="Edit"
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
                <button
                  type="button"
                  onClick={() => openDeleteModal(id, name)}
                  className="action-icon trash-button"
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </>
            );
          }
        },
      },
    ],
    [deleteItemId, showDeleteSuccessModal, currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: products, // Rename 'categoryData' to 'data'
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <SellerTopNavigation />

      <div className="page-container">
        {/* Data counter */}
        <div className="data-counter">
          <div className="counter-item">
            <p>Total Products</p>
            <p>{countofAllProducts.total_products}</p>
          </div>
          <div className="counter-item">
            <p>Unverified Products</p>
            <p>{countofAllProducts.unverified_products}</p>
          </div>
          <div className="counter-item">
            <p>Verified and Published</p>
            <p>{countofAllProducts.verified_and_published_products}</p>
          </div>
          <div className="counter-item">
            <p>Rejected Products</p>
            <p>{countofAllProducts.rejected_products}</p>
          </div>
          <div className="counter-item">
            <p>Sold Products</p>
            <p>{countofAllProducts.sold_products}</p>
          </div>
        </div>

        <div className="table-serach-area">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>

        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : ShowNoProduct ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={" Sellers Products"}
              />
            )}
          </div>

          {/* Delete Modal */}
          {showDeleteModal && (
            <div
              id="deleteModal"
              className="modal fade"
              style={{ display: showDeleteModal ? "block" : "none" }}
            >
              <div className="modal-content">
                <span className="modal-close" onClick={closeDeleteModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>

                <h3 className="modal-header">Delete Confirmation</h3>
                <p className="modal-line">
                  Are you sure you want to delete{" "}
                  <span className="deleteitemsNameappPages">
                    {deletingItemName}
                  </span>{" "}
                  item?
                </p>
                <div className="modal-buttons">
                  <button
                    type="button"
                    className="btn primary-btn"
                    onClick={() => handleConfirmDelete(deleteItemId)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn secondary-btn"
                    onClick={closeDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SellerProducts;
