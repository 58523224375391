import React from "react";
import { useEffect, useState, useMemo, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalFilter from "../../../Components/GlobalFilter";
import { Switch } from "@mui/material";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import LoaderButton from "../../../Components/LoaderButton";
import Navigation from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { useNavigate, useLocation } from "react-router-dom";
import "./feature-flags.css";
import Paginate from "../../../Components/Pagination/Paginate";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const FeatureFlags = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  // State hooks
  const { showToast } = Toast();
  const [data, setFeatureFlagData] = useState([]);
  const [deleteFlagID, setDeleteFlagID] = useState();
  const [addFlagError, setAddFlagError] = useState("");
  const [flagTypeError, setFlagTypeError] = useState("");
  const [showDeleteFeatureModel, setDeleteAddFeatureModel] = useState(false);
  const [showAddFeatureModel, setShowAddFeatureModel] = useState(false);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [viewAlert, setViewAlert] = useState(false);
  const navigate = useNavigate();
  const dataPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [isFeatureFlagCreated, setIsFeatureFlagCreated] = useState(false);
  const [pagination, setPagination] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [totalCount, setTotalCount] = useState("");
  let [isDeleted, setIsDeleted] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addFeaturePayload, setAddFeaturePayload] = useState({
    name: "",
    type: "",
    value: 0,
  });

  // Function to handle URL change
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getFeatureFlags(data);
  };

  // Function to validate feature flag name
  const validFeatureFlagName = (value) => {
    const regex = /^[A-Za-z_]+$/;
    return regex.test(value);
  };

  const closeAddFeatureModel = () => {
    const nextPage = isFeatureFlagCreated
      ? totalCount % dataPerPage === 0
        ? lastPage + 1
        : lastPage
      : lastPage;
    const requestUrl = `${API_HOST}/${APIEndpoint.getAllFlags}&page_no=${nextPage}`;

    if (isFeatureFlagCreated) {
      getFeatureFlags(requestUrl);
      setIsFeatureFlagCreated(false);
    }

    setShowAddFeatureModel(false);
    setAddFeaturePayload({
      name: "",
      type: "",
      value: 0,
    });
    setAddFlagError("");
    setFlagTypeError("");
    setLoadingButton(false);
  };

  const closeDeleteFeatureModel = () => {
    if (isDeleted) {
      const nextPage =
        currentPage === lastPage
          ? totalCount % dataPerPage === 1
            ? currentPage - 1
            : currentPage
          : currentPage;
      const requestUrl = `${API_HOST}/${APIEndpoint.getAllFlags}&page_no=${nextPage}`;
      getFeatureFlags(requestUrl);
      setIsDeleted(false);
    }
    setDeleteFlagID("");
    setDeleteAddFeatureModel(false);
    setLoadingButtonDelete(false);
  };

  let url = `${API_HOST}/${APIEndpoint.getAllFlags}&page_no=${currentPage}`;
  const getFeatureFlags = async (url) => {
    try {
      const your_access_token = getDataFromCookies("7b9KpQ3rW6");
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }
      const response = await fetch(`${url}&per_page=${dataPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${your_access_token}`,
        },
      });

      const res = await response.json();

      if (res.data) {
        const { data, current_page, last_page, total } = res.data;
        setPagination(res.data);
        setCurrentPage(current_page);
        setFeatureFlagData(data);
        setLastPage(last_page);
        setTotalCount(total);
      } else if (res.status === 404) {
        setFeatureFlagData([]);
        setPagination("");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(Navigation.login);
        showToast("info", "Session expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      }
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const createFeatureFlag = async () => {
    setLoadingButton(true);
    const your_access_token = getDataFromCookies("7b9KpQ3rW6");
    const validName = validFeatureFlagName(addFeaturePayload.name);
    let error = false;
    if (addFeaturePayload.name === "") {
      setAddFlagError("Name is required.");
      error = true;
    } else if (!validName) {
      setAddFlagError("Only characters are allowed.");
      error = true;
    }
    if (addFeaturePayload.type === "") {
      setFlagTypeError("Flag Type is required.");
      error = true;
    }
    if (error) {
      setLoadingButton(false);
      return;
    }
    try {
      const url = `${API_HOST}/${APIEndpoint.crudAdminFlag}`;
      const createFeatureResponse = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${your_access_token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(addFeaturePayload),
      });

      if (createFeatureResponse.ok) {
        isFeatureFlagCreated = true;
        showToast("success", "Feature Flag created.");
        closeAddFeatureModel();
      } else if (createFeatureResponse.status === 401) {
        const responseData = await createFeatureResponse.json();
        if (responseData.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Session expired!");
        }
      } else if (createFeatureResponse.status === 400) {
        if (createFeatureResponse.errors.name) {
          setAddFlagError(createFeatureResponse.errors.name[0]);
        } else if (createFeatureResponse.errors.type) {
          setFlagTypeError(createFeatureResponse.errors.type[0]);
        }
      } else {
        throw new Error("Failed to create feature flag");
      }
    } catch (error) {
      console.error(error);
      showToast("error", "Failed to create feature flag");
      setLoadingButton(false);
    } finally {
      setLoadingButton(false);
    }
  };

  const DeleteFeatureFlag = async () => {
    try {
      const your_access_token = getDataFromCookies("7b9KpQ3rW6");
      const url = `${API_HOST}/${APIEndpoint.crudAdminFlag}/${deleteFlagID}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${your_access_token}`,
          "Content-type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete feature flag");
      }

      setLoadingButtonDelete(false);
      const res = await response.json();

      if (res.status === 200) {
        isDeleted = true;
        showToast("success", "Feature Flag Deleted.");
        closeDeleteFeatureModel();
      } else {
        throw new Error("Failed to delete feature flag");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const UpdateFeatureFlag = async (id, name, type, value) => {
    try {
      const your_access_token = getDataFromCookies("7b9KpQ3rW6");
      const payload = { name, type, value };
      const url = `${API_HOST}/${APIEndpoint.crudAdminFlag}/${id}`;
      const api = `${API_HOST}/${APIEndpoint.getAllFlags}&page_no=${currentPage}`;
      const UpdateFeatureResponse = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${your_access_token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (UpdateFeatureResponse.status === 200) {
        getFeatureFlags(api);
        showToast("success", "Feature Flag Updated.");
      } else if (UpdateFeatureResponse.status === 401) {
        const responseData = await UpdateFeatureResponse.json();
        if (responseData.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          handleUnauthorizedAccess();
        }
      } else {
        throw new Error("Failed to update feature flag");
      }
    } catch (error) {
      console.error(error);
      showToast("error", "Failed to update feature flag");
    }
  };

  const handleUnauthorizedAccess = () => {
    removeDataFromCookie("7b9KpQ3rW6", "/");
    removeDataFromCookie("loggedIn", "/");
    navigate(Navigation.login);
    showToast("info", "Session expired!");
  };

  useEffect(() => {
    getFeatureFlags(url);
  }, []);

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      currentPage = 1;
      url = `${API_HOST}/${APIEndpoint.getAllFlags}&page_no=${currentPage}`;
      getFeatureFlags(url);
    }, 1000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (viewAlert) {
      setViewAlert(false);
    }
  }, [viewAlert]);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        width: 200,
        accessor: (row, index) => index + 1 + (currentPage - 1) * dataPerPage,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: ({ row }) => (
          <Switch
            checked={row.original.value}
            onChange={() => {
              const updatedValue = row.original.value ? 0 : 1;
              UpdateFeatureFlag(
                row.original.id,
                row.original.name,
                row.original.type,
                updatedValue
              );
            }}
          />
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <FontAwesomeIcon
            className="action-icon trash-button"
            icon={faTrashCan}
            onClick={() => {
              setDeleteFlagID(row.original.id);
              setDeleteAddFeatureModel(true);
            }}
          />
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: dataPerPage, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      {/* Page Heading */}
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Feature Flags</li>
        </div>
      </div>

      <div className="page-container">
        {/* Category Grid Container */}
        <div className="Category-grid-container">
          <div className="Category-grid-container-item2">
            <div className="category-container-box">
              {/* Add Feature Flag Button */}
              <button
                style={{ height: "40px" }}
                className="primary-btn"
                onClick={() => {
                  setAddFeaturePayload((prev) => ({
                    ...prev,
                    name: "",
                    type: "",
                    value: 0,
                  }));
                  setShowAddFeatureModel(true);
                  setViewAlert(false);
                }}
              >
                Add Feature Flag
              </button>
              {/* Table Search Area */}
              <div className="table-serach-area">
                <div className="search-bar">
                  <div className="fontAwsomeDiv">
                    {searchLoading ? (
                      <LoaderButton />
                    ) : (
                      <FontAwesomeIcon
                        className="SearchIconDiv"
                        icon={faSearch}
                      />
                    )}
                  </div>
                  <input
                    id="seller-searchForProducts"
                    placeholder="Search..."
                    className="search-input-order"
                    name="search-query"
                    value={searchQuery}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.target.value;
                      setSearchQuery(val);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customers Grid Container */}
        <div className="InnerTableContainer">
          {/* Feature Flags Table */}
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      style={{
                        width: "200px",
                      }}
                    >
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data && data.length > 0 ? (
                page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="TableBodyData" {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            className="showelement"
                            {...cell.getCellProps()}
                            style={{
                              width:
                                cell.column.Header === "Name"
                                  ? "250px"
                                  : "100px",
                            }}
                          >
                            {cell.column.Header === "Name" &&
                            cell.value.length > 70
                              ? `${cell.value.slice(0, 70)}...`
                              : cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">
                        No feature flags found.
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No feature flags found.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Feature Flags"}
            />
          )}
        </div>
      </div>

      {/* Add Feature Model */}
      {showAddFeatureModel && (
        <div className="ModalContainer">
          <div className="ModalContent">
            <div id="popup">
              <div className="modal-close" onClick={closeAddFeatureModel}>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{
                    color: "gray",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <div className="popupContainer" id="featureflagInputs">
                <h4 className="HeadingAdd-Feature-flag">
                  Create A Feature Flag
                </h4>

                <div className="input-row">
                  <label className="Admin_Profile_Lable_tag">
                    <input
                      type="text"
                      className="Admin_Profile_input_tag"
                      autoFocus
                      minLength="1"
                      maxLength="100"
                      onKeyPress={(event) => {
                        if (event.key === "Enter" || event.key === 13) {
                          event.preventDefault();
                        }
                      }}
                      value={addFeaturePayload.name}
                      onChange={(event) => {
                        setIsDataChanged(true);
                        setAddFlagError("");
                        let name = event.target.value.trim();
                        let validName = validFeatureFlagName(name);
                        if (validName || name === "") {
                          setAddFeaturePayload((prev) => ({
                            ...prev,
                            name: name,
                          }));
                        } else {
                          setAddFlagError("Only characters are allowed.");
                        }
                        if (event.target.value.length < 1) {
                          setIsDataChanged(false);
                        }
                      }}
                      placeholder="Flag Name"
                    />
                    <span className="Admin_Profile_span_tag">Flag Name</span>
                  </label>
                  <li className="error-box">{addFlagError}</li>
                </div>

                {/* Flag Type */}
                <div className="input-row">
                  <label className="Admin_Profile_Lable_tag">
                    <select
                      type="text"
                      className="Admin_Profile_input_tag"
                      id="userRole"
                      name="userRole"
                      placeholder="Flag Type"
                      onChange={(e) => {
                        setAddFeaturePayload((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }));
                        setFlagTypeError("");
                      }}
                    >
                      {" "}
                      <option value="">Select</option>
                      <option value="Customer">Customer</option>
                      <option value="Seller">Seller</option>
                      <option value="Admin">Admin</option>
                    </select>
                    <span className="Admin_Profile_span_tag">Flag Type</span>
                  </label>
                  <li className="error-box">{flagTypeError}</li>
                </div>

                <div className="Default_value_heading">
                  <p className="text_pera_defaut-value">
                    Default Value <span className="required">*</span>
                  </p>

                  <Switch
                    checked={addFeaturePayload.value === 0 ? false : true}
                    onChange={() => {
                      setAddFeaturePayload((prev) => ({
                        ...prev,
                        value: addFeaturePayload.value === 0 ? 1 : 0,
                      }));
                    }}
                  />
                </div>

                {/* Add and Cancel Buttons */}
                <div className="modal-buttons">
                  <button
                    className={"btn primary-btn"}
                    onClick={() => {
                      createFeatureFlag();
                    }}
                  >
                    {loadingButton ? <LoaderButton /> : "Add"}
                  </button>
                  <button
                    className="btn secondary-btn"
                    onClick={closeAddFeatureModel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation modal */}
      {showDeleteFeatureModel && (
        <div className="ModalContainer">
          <div className="ModalContent">
            <div id="popup">
              <div className="modal-close" onClick={closeDeleteFeatureModel}>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{
                    color: "gray",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <div className="" id="featureflagInputs">
                <h4 className="modal-header">Delete Confirmation</h4>
                <div className="popupRow">
                  <p className="delete_text_pera">
                    Are you sure you want to delete this feature flag?
                  </p>
                  <p className="delete_text_pera">
                    Please make sure that this is not used anywhere in the code.
                  </p>
                </div>

                <div className="modal-buttons">
                  <button
                    className={"btn primary-btn"}
                    onClick={() => {
                      setLoadingButtonDelete(true);
                      DeleteFeatureFlag();
                    }}
                  >
                    {loadingButtonDelete ? <LoaderButton /> : "Delete"}
                  </button>
                  <button
                    className="btn secondary-btn"
                    onClick={closeDeleteFeatureModel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureFlags;
