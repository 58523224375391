import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import "./role.css";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../../Components/Cookie";
import CreateAdminRole from "./AddRole";
import EditAdminRole from "./EditRole";
import Toast from "../../../../Components/Toast";
import InputContext from "../../../../Components/InputContext";
import Paginate from "../../../../Components/Pagination/Paginate";
import LoaderButton from "../../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Roles = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [showAddRoleModalPopup, setShowAddRoleModalPopup] = useState(false);
  const [showEditModalPopup, setShowEditModalPopup] = useState(false);
  const [roleId, setRoleId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const AdminRolesPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const { showToast } = Toast();
  const openAddModal = () => {
    setShowAddRoleModalPopup(true);
  };
  const closeAddModal = () => {
    const isAdded = getDataFromCookies("isAddedRole");
    if (isAdded) {
      lastPage = totalCount % AdminRolesPerPage === 0 ? lastPage + 1 : lastPage;
      GetListofAllRoles(
        `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${lastPage}`
      );
      removeDataFromCookie("isAddedRole");
    }
    setShowAddRoleModalPopup(false);
  };

  const openEditModal = (id) => {
    setRoleId(id);
    setShowEditModalPopup(true);
  };
  const closeEditModal = () => {
    setShowEditModalPopup(false);
    const isEdited = getDataFromCookies("isEditedRole");
    if (isEdited) {
      GetListofAllRoles(
        `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${currentPage}`
      );
      removeDataFromCookie("isEditedRole");
    }
  };
  const VerifiedRolePermission = async () => {
    let API_HOST = process.env.REACT_APP_HOST_API_URL;
    await fetch(`${API_HOST}/${APIEndpoint.getRolePermission}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data;
          setSelectedModules(dataArray);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    GetListofAllRoles(data);
  };

  const GetListofAllRoles = async (url) => {
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }

    await fetch(`${url}&per_page=${AdminRolesPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data.data;
          setData(dataArray);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalCount(res.data.total);
          setPagination(res.data);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else if (res.status === 404) {
          setData([]);
          setPagination("");
        } else {
          console.error("Data is not an array");
        }
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    GetListofAllRoles(
      `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${currentPage}`
    );
    VerifiedRolePermission();
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        GetListofAllRoles(
          `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  useEffect(() => {
    // Function to handle setting selected modules
    const setSelectedModulesForPermission = (isChecked) => {
      const selectedModulesState = { ...selectedModules };
      for (let a = 0; a < data.length; a++) {
        selectedModulesState[data[a].display_name] = isChecked
          ? data[a]
          : undefined;
      }
      setSelectedModules(selectedModulesState);
    };
    setSelectedModulesForPermission(false);
  }, [data]);

  // Delete Role API Call

  const handelAdminRoleSetupDelete = (id) => {
    //setDeleteRolenmae(roleName);
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    fetch(`${API_HOST}/${APIEndpoint.adminRoles}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          if (currentPage === lastPage) {
            currentPage =
              totalCount % AdminRolesPerPage === 1
                ? currentPage - 1
                : currentPage;
            GetListofAllRoles(
              `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${currentPage}`
            );
          } else {
            GetListofAllRoles(
              `${API_HOST}/${APIEndpoint.adminRoles}?page_no=${currentPage}`
            );
          }
          VerifiedRolePermission();
          showToast("success", "User Role Deleted Successfully");
          setShowDeleteModal(false);
        } else if (
          response.status === 401 &&
          response.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status === 401 && response.message !== "") {
          showToast("error", response.message);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClickpopup = (id, name) => {
    setDeleteItemId(id);
    setDeleteName(name);
    setShowDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * AdminRolesPerPage,
      },
      {
        Header: "Name",
        accessor: "display_name",
        Cell: ({ value }) => <div style={{ fontWeight: 500 }}>{value}</div>,
      },

      {
        Header: "Permissions",
        accessor: "permissions",
        Cell: ({ value }) => (
          <div>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {value.map((permission, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#00c5cf21" : "#51c9ac70",
                    // Optional: Set text color to white for visibility
                  }}
                >
                  {permission}
                </li>
              ))}
            </ul>
          </div>
        ),
      },

      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>
            <div>
              <span style={{ fontWeight: "520" }}>Date: </span>
              {new Date(value).toLocaleDateString()}
            </div>
            <div>
              <span style={{ fontWeight: "520" }}>Time: </span>
              {new Date(value).toLocaleTimeString()}
            </div>
          </>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const isStatusDisabled = row.original.name === "master_admin";
          return (
            <div>
              <button
                onClick={() => openEditModal(id)}
                className={` ${
                  isStatusDisabled
                    ? "edit-button-disabled"
                    : "action-icon edit-button"
                }`}
                disabled={isStatusDisabled}
                title={isStatusDisabled ? "Disabled" : "Edit"}
                style={{
                  opacity: isStatusDisabled ? 0.5 : 1,
                  cursor: isStatusDisabled ? "not-allowed" : "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                onClick={() => handleDeleteClickpopup(id, name)}
                className={` ${
                  isStatusDisabled
                    ? "trash-button-disabled"
                    : "action-icon trash-button"
                }`}
                title={isStatusDisabled ? "Disabled" : "Delete"}
                style={{
                  opacity: isStatusDisabled ? 0.5 : 1,
                  cursor: isStatusDisabled ? "not-allowed" : "pointer",
                }}
                disabled={isStatusDisabled}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage, // Corrected function name
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active">Role Setup</li>
          </div>
        </div>
        <div className="page-container">
          <div className="AdminRoleSetup-grid-container">
            <div className="AdminRoleSetup-grid-container-item2">
              <div className="roleSetupTable">
                <div className="table-serach-area">
                  <div>
                    <button
                      className="AddAdminRoleButton"
                      onClick={() => {
                        openAddModal();
                      }}
                    >
                      Add Role
                    </button>
                  </div>
                  <div className="search-bar">
                    <div className="fontAwsomeDiv">
                      {searchLoading ? (
                        <LoaderButton />
                      ) : (
                        <FontAwesomeIcon
                          className="SearchIconDiv"
                          icon={faSearch}
                        />
                      )}
                    </div>
                    <input
                      id="seller-searchForProducts"
                      placeholder="Search..."
                      className="search-input-order"
                      name="search-query"
                      value={searchQuery}
                      onChange={(e) => {
                        e.preventDefault();
                        const val = e.target.value;
                        setSearchQuery(val);
                        setIsSearchApplied(true);
                      }}
                    />
                  </div>
                </div>
                <div className="Role-setup-table-container">
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr
                          className="TableHeading"
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column) => (
                            <>
                              <th>
                                <div className="header-cell">
                                  <span>{column.render("Header")}</span>
                                </div>
                              </th>
                            </>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {loading ? (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            <div className="nomatchfound">Loading...</div>
                          </td>
                        </tr>
                      ) : page.length > 0 ? (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              className="TableBodyData"
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell) => (
                                <>
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                </>
                              ))}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            <div className="nomatchfound">
                              No Data available.
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* Pagination */}
                <div className="pagination-container">
                  {pagination && (
                    <Paginate
                      pagination={pagination}
                      pageChange={handleURLChange}
                      pageName={"Roles"}
                    />
                  )}
                </div>
              </div>
              {/* react table logic ends */}
            </div>
            {showAddRoleModalPopup && (
              <CreateAdminRole closeAddModal={closeAddModal} />
            )}
            {showEditModalPopup && (
              <EditAdminRole closeEditModal={closeEditModal} RoleId={roleId} />
            )}
            <div>
              {showDeleteModal && (
                <>
                  <div className="RoleSetup_deleteModal_c1">
                    <div className="Role_setup_modal_content">
                      <span
                        className="modal-close"
                        onClick={handleCancelDelete}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      <h3 className="modal-header">Delete Confirmation</h3>
                      <p className="modal-line">
                        Are you sure you want to delete{" "}
                        <span className="deleteitemsNameappPages">
                          {deleteName}
                        </span>{" "}
                        role?
                      </p>
                      <div className="modal-buttons">
                        <button
                          className="btn primary-btn"
                          onClick={() =>
                            handelAdminRoleSetupDelete(deleteItemId)
                          }
                        >
                          Yes, Delete It!
                        </button>
                        <button
                          className="btn secondary-btn"
                          onClick={handleCancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
