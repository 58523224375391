import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import APIEndpoint from "../../config/APIEndpoints.json";
import Navigation from "../../config/Navigation.json";
import LoaderButton from "../LoaderButton";
import { getDataFromCookies } from "../Cookie";
import "./forgot-password.css";
import Toast from "../Toast";
import validator from "validator";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const ForgotPassword = () => {
  const [errors, setErrors] = useState({
    email: "",
    OTP: "",
    Password: "",
    confirmPassword: "",
  });
  const { showToast } = Toast();
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [submitLoadingButton, setSubmitLoadingButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    email: "",
    token: "",
    OTP: "",
    Password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const redirectToPreviousPage = () => {
    setErrors((prev) => ({
      ...prev,
      email: "",
      OTP: "",
      Password: "",
      confirmPassword: "",
    }));
    setValues((prev) => ({
      ...prev,
      email: "",
      token: "",
      OTP: "",
      Password: "",
      confirmPassword: "",
    }));
    setShowSetPassword(false);
    navigate(Navigation.login);
  };

  const requestOTP = async () => {
    const yourAccessToken = getDataFromCookies("7b9KpQ3rW6");
    const isEmailValue = validator.isEmail(values.email);
    if (values.email === "") {
      setErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
      setSubmitLoadingButton(false);
      return;
    }
    if (!isEmailValue) {
      setErrors((prev) => ({
        ...prev,
        email: "Enter valid email address",
      }));
      setSubmitLoadingButton(false);
      return;
    }

    const payload = {
      email: values.email,
    };
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.adminForgetPassStep1}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${yourAccessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const forgetPassStep1Response = await response.json();

    if (forgetPassStep1Response) {
      setSubmitLoadingButton(false);
    }
    if (forgetPassStep1Response.status === 201) {
      setValues((prev) => ({
        ...prev,
        token: forgetPassStep1Response.data,
      }));
      setShowSetPassword(true);
    }
    if (forgetPassStep1Response.status === 404 && forgetPassStep1Response.message === "Email address is not exist.") {
        setErrors((prev) => ({
          ...prev,
          email: "Email address is not registered.",
        }));
    }
  };

  const updatePassword = async () => {
    const yourAccessToken = getDataFromCookies("7b9KpQ3rW6");
    let errorFound = false;
    if (values.email === "") {
      setErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
      errorFound = true;
    }
    if (values.OTP === "") {
      setErrors((prev) => ({
        ...prev,
        OTP: "OTP is required",
      }));
      errorFound = true;
    }
    if (values.Password === "") {
      setErrors((prev) => ({
        ...prev,
        Password: "Password is required",
      }));
      errorFound = true;
    }
    if (values.confirmPassword === "") {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Confirm Password is required",
      }));
      errorFound = true;
    }
    if (values.Password !== values.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Password and confirm Password must be same.",
      }));
      errorFound = true;
    }

    if (errorFound === true) {
      setLoadingButton(false);
      return;
    }

    const payload = {
      email: values.email,
      otp: values.OTP,
      token: values.token,
      new_password: values.Password,
      new_password_confirmation: values.confirmPassword,
    };
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.adminForgetPassStep2}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${yourAccessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const forgetPassStep2Response = await response.json();

    if (forgetPassStep2Response) {
      setLoadingButton(false);
    }
    if (forgetPassStep2Response.status === 200) {
      setErrors((prev) => ({
        ...prev,
        email: "",
        OTP: "",
        Password: "",
        confirmPassword: "",
      }));
      setValues((prev) => ({
        ...prev,
        email: "",
        token: "",
        OTP: "",
        Password: "",
        confirmPassword: "",
      }));
      setShowSetPassword(false);
      showToast("success", forgetPassStep2Response.message);
      navigate(Navigation.login);
    }
    if (forgetPassStep2Response.status === 400) {
      if (forgetPassStep2Response.errors) {
        if (forgetPassStep2Response.errors.email) {
          setErrors((prev) => ({
            ...prev,
            email: forgetPassStep2Response.errors.email,
          }));
        }
        if (forgetPassStep2Response.errors.new_password) {
          setErrors((prev) => ({
            ...prev,
            Password: forgetPassStep2Response.errors.new_password,
          }));
        }
        if (forgetPassStep2Response.errors.otp) {
          setErrors((prev) => ({
            ...prev,
            OTP: forgetPassStep2Response.errors.otp,
          }));
        }
      }
      if (forgetPassStep2Response.message === "Invalid otp.") {
        setErrors((prev) => ({
          ...prev,
          OTP: "Invalid otp.",
        }));
      }
      if (
        forgetPassStep2Response.message ===
        "New password cannot be same as your previous password"
      ) {
        setErrors((prev) => ({
          ...prev,
          Password: "New password cannot be same as your previous password",
        }));
      }
    }
    if (forgetPassStep2Response.status === 401 && forgetPassStep2Response.message) {
        setErrors((prev) => ({
          ...prev,
          OTP: forgetPassStep2Response.message,
        }));
      }
  };

  return (
      <div className="forget">
        <div className="forget__container">
          <div style={{ justifyContent: "end" }}>
            <div className="crossbutton">
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: "gray",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  redirectToPreviousPage();
                }}
                tabindex="0"
              />
            </div>
          </div>
          <div id="iconContainer">
            <FontAwesomeIcon
              icon={faArrowLeft}
              faRectangleXmark
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                setErrors((prev) => ({
                  ...prev,
                  email: "",
                  OTP: "",
                  Password: "",
                  confirmPassword: "",
                }));
                setValues((prev) => ({
                  ...prev,
                  token: "",
                  OTP: "",
                  Password: "",
                  confirmPassword: "",
                }));

                redirectToPreviousPage();
              }}
              tabindex="0"
            />
          </div>
          <h4 className="forget-password-heading">Forgot Password ?</h4>
          <div className="InputControlContainer">
            <label className="ForgotPassword_Joyrejoy_lable_tag">
              <input
                type="text"
                name="email"
                disabled={showSetPassword ? true : false}
                className="ForgotPassword_Joyrejoy_input_tag"
                value={values.email}
                onChange={(e) => {
                  setErrors((prev) => ({
                    ...prev,
                    email: "",
                  }));
                  setValues((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                placeholder="Email"
              />
              <span className="ForgotPassword_Joyrejoy_span_tag"> Email</span>
              {showSetPassword && (
                <span
                  className="forgotpassword_email_edit_icon"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setShowSetPassword(false);
                  }}
                    onKeyDown={() => {
                      setShowSetPassword(false);
                    }}
                >
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.00432 11.0312C1.63242 11.0312 1.28491 10.9032 1.03494 10.6654C0.717916 10.3666 0.565499 9.91549 0.620369 9.42776L0.845947 7.45243C0.888624 7.08053 1.1142 6.5867 1.37636 6.31845L6.38175 1.02045C7.63157 -0.30253 8.93626 -0.339111 10.2592 0.910712C11.5822 2.16053 11.6188 3.46523 10.369 4.78821L5.3636 10.0862C5.10754 10.3606 4.63199 10.6166 4.26009 10.6776L2.29696 11.0129C2.19332 11.019 2.10187 11.0312 2.00432 11.0312ZM8.33879 0.904615C7.86934 0.904615 7.46086 1.19726 7.04629 1.63622L2.0409 6.94037C1.91896 7.0684 1.77874 7.37324 1.75435 7.55004L1.52878 9.52531C1.50439 9.7265 1.55316 9.89111 1.6629 9.99475C1.77264 10.0984 1.93725 10.135 2.13845 10.1045L4.10158 9.76923C4.27838 9.73875 4.57103 9.58018 4.69296 9.45215L9.69835 4.15415C10.4543 3.34939 10.7287 2.60559 9.62519 1.56915C9.13745 1.09971 8.71678 0.904615 8.33879 0.904615Z"
                      fill="#888888"
                    />
                    <path
                      d="M9.19847 5.80701C9.18628 5.80701 9.16799 5.80701 9.15579 5.80701C7.25362 5.61801 5.72336 4.17313 5.43071 2.28315C5.39413 2.03319 5.56484 1.80152 5.81481 1.75884C6.06477 1.72226 6.29644 1.89297 6.33912 2.14293C6.5708 3.61833 7.76575 4.75232 9.25334 4.89864C9.5033 4.92302 9.68621 5.1486 9.66182 5.39853C9.63133 5.63021 9.43014 5.80701 9.19847 5.80701Z"
                      fill="#888888"
                    />
                    <path
                      d="M11.4313 13.0004H0.457252C0.207288 13.0004 0 12.7932 0 12.5432C0 12.2932 0.207288 12.0859 0.457252 12.0859H11.4313C11.6813 12.0859 11.8885 12.2932 11.8885 12.5432C11.8885 12.7932 11.6813 13.0004 11.4313 13.0004Z"
                      fill="#888888"
                    />
                  </svg>
                </span>
              )}
            </label>
            <li className="PasswordErrorMessage"> {errors.email}</li>
            {showSetPassword ? (
              <>
                <label className="ForgotPassword_Joyrejoy_lable_tag">
                  <input
                    type="text"
                    name="OTP"
                    maxLength={4}
                    className="ForgotPassword_Joyrejoy_input_tag"
                    value={values.OTP}
                    onChange={(e) => {
                      setErrors((prev) => ({
                        ...prev,
                        OTP: "",
                      }));
                      if (/^\d*$/.test(e.target.value)) {
                        setValues((prev) => ({
                          ...prev,
                          OTP: e.target.value,
                        }));
                      }
                    }}
                    placeholder="OTP"
                  />
                  <span className="ForgotPassword_Joyrejoy_span_tag"> OTP</span>
                </label>
                <li className="PasswordErrorMessage">{errors.OTP}</li>
                <div className="password-line">
                  <label className="ForgotPassword_Joyrejoy_lable_tag">
                    <input
                      type="password"
                      name="Password"
                      id="Passwordinput"
                      className="ForgotPassword_Joyrejoy_input_tag"
                      value={values.Password}
                      onChange={(e) => {
                        setErrors((prev) => ({
                          ...prev,
                          Password: "",
                        }));
                        setValues((prev) => ({
                          ...prev,
                          Password: e.target.value,
                        }));
                      }}
                      placeholder="Password"
                    />
                    <span className="ForgotPassword_Joyrejoy_span_tag">
                      {" "}
                      Password
                    </span>
                    <span className="password-eye-icons">
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="fontassowmicon addColor"
                          onClick={() => {
                            setShowPassword(false);
                            const x = document.getElementById("Passwordinput");
                            x.type = "password";
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fontassowmicon addColor"
                          icon={faEyeSlash}
                          onClick={() => {
                            setShowPassword(true);
                            const x = document.getElementById("Passwordinput");
                            x.type = "text";
                          }}
                        />
                      )}
                    </span>
                  </label>
                  <li className="PasswordErrorMessage"> {errors.Password}</li>
                </div>
                <div className="password-line">
                  <label className="ForgotPassword_Joyrejoy_lable_tag">
                    <input
                      type="password"
                      name="Confirm Password"
                      id="PasswordContainer11"
                      className="ForgotPassword_Joyrejoy_input_tag"
                      value={values.confirmPassword}
                      onChange={(e) => {
                        setErrors((prev) => ({
                          ...prev,
                          confirmPassword: "",
                        }));
                        setValues((prev) => ({
                          ...prev,
                          confirmPassword: e.target.value,
                        }));
                      }}
                      placeholder="Confirm Password"
                    />
                    <span className="ForgotPassword_Joyrejoy_span_tag">
                      {" "}
                      Confirm Password
                    </span>
                    <span className="password-eye-icons">
                      {showPasswordConfirmation ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="fontassowmicon addColor"   
                          onClick={() => {
                            setShowPasswordConfirmation(false);
                            const x = document.getElementById(
                              "PasswordContainer11"
                            );
                            x.type = "password";
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fontassowmicon addColor"
                          icon={faEyeSlash}
                          onClick={() => {
                            setShowPasswordConfirmation(true);
                            const x = document.getElementById(
                              "PasswordContainer11"
                            );
                            x.type = "text";
                          }}
                        />
                      )}
                    </span>
                  </label>
                  <li className="PasswordErrorMessage">
                    {" "}
                    {errors.confirmPassword}
                  </li>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="button-row">
            <button
              className="forget__btn"
              onClick={() => {
                setErrors((prev) => ({
                  ...prev,
                  email: "",
                  OTP: "",
                  Password: "",
                  confirmPassword: "",
                }));

                if (!showSetPassword) {
                  setValues((prev) => ({
                    ...prev,
                    OTP: "",
                    Password: "",
                    confirmPassword: "",
                  }));
                  setSubmitLoadingButton(true);
                  requestOTP();
                } else {
                  setLoadingButton(true);
                  updatePassword();
                }
              }}
            >
              {showSetPassword ? (
                loadingButton ? (
                  <LoaderButton />
                ) : (
                  "Update"
                )
              ) : submitLoadingButton ? (
                <LoaderButton />
              ) : (
                "Submit"
              )}
            </button>{" "}
          </div>
        </div>
      </div>
  );
};

export default ForgotPassword;
