import React, { useState, useEffect, useContext, useRef } from "react";
import "./brand.css";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import stringConstant from "../../../config/ProjectConstants.json";
import LoaderButton from "../../../Components/LoaderButton";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const EditBrand = ({
  handleCloseModalEdit,
  BrandId,
  BrandImage,
  BrandName,
  reFetch,
  setReFetch,
}) => {
  // constant
  const location = useLocation();
  const API_HOST = process.env.REACT_APP_HOST_API_URL;
  let your_api_token = getDataFromCookies("7b9KpQ3rW6");
  const navigate = useNavigate();
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });

  const [product, setProduct] = useState({
    name: "",
    logo: null,
  });
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  useEffect(() => {
    setProduct({
      name: BrandName,
      logo: BrandImage,
    });
    setContainer1({
      image: `${imgHost + "/" + BrandImage}`,
    });
    setDataToCookies("isEdited", false);
  }, []);
  const [errorBrandName, setErrorBrandName] = useState("");
  const [errorImageUpload, setErrorImageUpload] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const setImagesToContainer = (images) => {
    setContainer1({
      image: `${imgHost + "/" + images.image}`,
    });
  };

  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      // setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setErrorImageUpload(
          "Invalid file type. Only JPG, JPEG, PNG, and WEBP files are allowed."
        );
        return;
      }
      if (file.size > maxFileSize) {
        compressImage(file, maxFileSize);
      } else {
        readFile(file);
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800; // Maximum width for the compressed image
          const maxHeight = 800; // Maximum height for the compressed image
          let width = image.width;
          let height = image.height;

          // Calculate new dimensions if the original image is larger than the maximum dimensions
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;

            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                // "Compression failed to meet target size requirement."
                // Handle the error as needed
                return;
              }
              setProduct((prevData) => ({
                ...prevData,
                logo: compressedFile,
              }));
              // setImageUpdated(true);
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };
  // img remove after upload
  const inputFileThumbRef = useRef(null);
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setProduct((pre) => ({
      ...pre,
      logo: null,
    }));
    // Clear the value of the input file element
    if (inputFileThumbRef.current) {
      inputFileThumbRef.current.value = null;
    }
  };
  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    event.stopPropagation(); // Prevent event propagation to the parent div
    event.nativeEvent.stopImmediatePropagation(); // Prevent event propagation to the parent's parent div
    event.preventDefault(); // Prevent
    handleRemoveThumbImage();
  };

  let errorsFound = false;
  const handleEditUpload = () => {
    setErrorBrandName("");
    setErrorImageUpload("");
    if (product.name.length < 1) {
      setErrorBrandName(stringConstant.errorMessages.errorForBlankBrandName);
      errorsFound = true;
    } else if (product.name.length < 3 || product.name.length > 50) {
      setErrorBrandName(stringConstant.errorMessages.alertForBrandNameLength);
      errorsFound = true;
    }
    if (!product.logo) {
      setErrorImageUpload(stringConstant.errorMessages.errorforBrandLogo);
      errorsFound = true;
    }
    if (errorsFound === true) {
      setLoadingButton(false);
      return;
    } else {
      const formData = new FormData();

      formData.append("name", product.name);
      formData.append("logo", product.logo);

      formData.append("_method", "PUT");
      fetch(`${API_HOST}/${APIEndpoint.crudBrands}/${BrandId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${your_api_token}`,
        },
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === 200) {
            setLoadingButton(false);
            setImagesToContainer(data);
            setReFetch(!reFetch);
            setDataToCookies("isEdited", true);
            handleCloseModalEdit();
            showToast("success", data.message);
          } else if (data.status === 400) {
            setLoadingButton(false);
            if (data.errors.name) {
              setErrorBrandName(data.errors.name[0]);
            }
            if (data.errors.logo) {
              setErrorImageUpload(data.errors.logo[0]);
            }
          } else if (
            data.status === 500 ||
            data.status === 501 ||
            data.status === 502 ||
            data.status === 503
          ) {
            showToast(
              stringConstant.alertErrorMessage.alertseverityerror,
              stringConstant.alertErrorMessage.alertForInternalServerError
            );
            setLoadingButton(false);
          } else if (
            data.status === 401 &&
            data.message === "You are not authorized."
          ) {
            contextObj.setInput("prevPath", location.pathname);
            removeDataFromCookie("7b9KpQ3rW6", "/");
            removeDataFromCookie("loggedIn", "/");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            navigate(Navigation.login);
            showToast("info", "Sessions expired!");
            setLoadingButton(false);
          } else if (data.status === 401 && data.message !== "") {
            showToast("error", data.message);
          }
        });
    }
  };

  const handleInputChange = (name, value) => {
    setProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="modailmaindiv">
        <div className="mainContainer">
          <div className="AdminEditBrandBox">
            <div className="buttoncrossdiv">
              <h1 className="AdminEditBrandHeadingTag">Edit a Brand</h1>
              <span className="modal-close" onClick={handleCloseModalEdit}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <div>
              <label className="ForgotPassword_Joyrejoy_lable_tag">
                <input
                  type="text"
                  name="name"
                  value={product.name}
                  placeholder="Brand Name"
                  className="ForgotPassword_Joyrejoy_input_tag"
                  onChange={(e) => {
                    handleInputChange("name", e.target.value);
                    setErrorBrandName("");
                  }}
                />
                <span className="ForgotPassword_Joyrejoy_span_tag">
                  Brand Name
                </span>
              </label>
              {/* <InputControl /> */}
              <li className="errormessageshownImage">{errorBrandName}</li>
            </div>
            <label className="brand_name_logo">Brand Logo</label>
            <div
              id="logo-container-brand"
              onClick={thumbImgUpload}
              className="dropzone"
              onMouseEnter={handleMouseEnterThumbImg}
              onMouseLeave={handleMouseLeaveThumbImg}
            >
              {container1.image ? (
                <div className="image-placeholder-brand">
                  <img
                    className="image-storing-container-brand"
                    src={container1.image}
                    loading="lazy"
                  />
                  {container1.hovering && (
                    <button
                      className="delete-button"
                      onClick={handleDeleteThumbClick}
                    >
                      Remove Image
                    </button>
                  )}
                </div>
              ) : (
                <p className="click-to-upload">Click to upload</p>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="logo"
                onChange={(e) => {
                  handleInputChange("logo", e.target.files[0]);
                }}
                id="thumb-img-input"
                hidden
              />
            </div>
            <li className="errormessageshownImage">{errorImageUpload}</li>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={handleCloseModalEdit}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  setLoadingButton(true);
                  handleEditUpload();
                }}
              >
                {loadingButton ? <LoaderButton /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditBrand;
