import { getDataFromCookies, removeDataFromCookie } from "../Cookie";
import Navigation from "../../config/Navigation.json";

const HOST_API = process.env.REACT_APP_HOST_API_URL;

const Logout = async () => {
  const yourAccessToken = getDataFromCookies("7b9KpQ3rW6") || null;
  if (yourAccessToken !== null) {
    const response = await fetch(`${HOST_API}/api/admin/logout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${yourAccessToken}`,
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      window.location.href = `${Navigation.login}`;
      removeDataFromCookie("7b9KpQ3rW6");
      removeDataFromCookie("loggedIn");
      removeDataFromCookie("isLoginByAdmin");
    } 
  }
  sessionStorage.removeItem("7b9KpQ3rW6");
  window.location.href = `${Navigation.login}`;
};

export default Logout;
