import { useContext, useEffect, useState } from "react";
import LoaderButton from "../../../Components/LoaderButton";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import stringConstant from "../../../config/ProjectConstants.json";
import "./category.css";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const EditCategory = ({
  handleCloseEditModel,
  CategoryId,
  categoryName,
  CategoryImage,
  categoryParentId,
  parentCategories,
  getCategories,
  loadingButtonEdit,
  setLoadingButtonEdit,
}) => {
  const location = useLocation();
  const [editCategory, setEditCategory] = useState({
    name: "",
    thumb_image: null,
    parent_category_id: categoryParentId,
  });
  const [container1, setContainer1] = useState({
    thumb_image: null,
  });

  const [thumbnailError, setThumbnailError] = useState("");
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [isNotParentCategory, setIsNotParentCategory] = useState(true);
  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const { showToast } = Toast();
  useEffect(() => {
    setEditCategory({
      name: categoryName,
      thumb_image: CategoryImage,
      parent_category_id: categoryParentId,
    });
    if (categoryParentId === 0) {
      setIsNotParentCategory(false);
    }
    setContainer1({
      thumb_image: `${imgHost}/${CategoryImage}`,
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      setNameError("");
      setEditCategory((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "parent_category") {
      setCategoryError("");
      setEditCategory((prevData) => ({
        ...prevData,
        parent_category_id: value,
      }));
    } else if (name === "thumb_image") {
      const file = event.target.files[0];
      setEditCategory((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setEditCategory((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setDataToCookies("isCategoryEdit", false);
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.addEventListener("change", handleInputChange);
    return () => {
      inputElement.removeEventListener("change", handleInputChange);
    };
  }, []);

  // upload thumb image
  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp", "gif"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setThumbnailError(
          "Please upload an image with .png, .jpg, .jpeg, .webp, .gif extension."
        );
        return;
      }
      if (file.size > maxFileSize) {
        compressImage(file, maxFileSize);
      } else {
        readFile(file);
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800;
          const maxHeight = 800;
          let width = image.width;
          let height = image.height;

          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;

            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                return;
              }
              setEditCategory((prevData) => ({
                ...prevData,
                thumb_image: compressedFile,
              }));
              readFile(compressedFile);
            },
            file.type,
            1
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          thumb_image: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteThumbClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  // img remove after upload
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      thumb_image: null,
    }));
    setEditCategory((prevData) => ({
      ...prevData,
      thumb_image: null,
    }));
    const thumbImageInput = document.getElementById("thumb-img-input");
    if (thumbImageInput.value) {
      thumbImageInput.value = null;
    }
  };
  const token = getDataFromCookies("7b9KpQ3rW6");

  const handelCategoryEditSubmit = async () => {
    setLoadingButtonEdit(true);
    let errorHas = false;

    // Name validation
    if (editCategory.name === "" || editCategory.name === null) {
      setNameError(stringConstant.errorMessages.errorForBlankCategoryName);
      errorHas = true;
    } else if (editCategory.name.length < 3 || editCategory.name.length > 30) {
      setNameError(stringConstant.errorMessages.alertForCategoryNameLength);
      errorHas = true;
    } else {
      setNameError("");
    }

    // Thumbnail Validation
    if (editCategory.thumb_image == null) {
      setThumbnailError(
        stringConstant.errorMessages.errorForBlankCategoryImage
      );
      errorHas = true;
    }

    if (errorHas === true) {
      setLoadingButtonEdit(false);
      return;
    }

    const formData = new FormData();
    formData.append("name", editCategory.name);
    if (editCategory.thumb_image instanceof File) {
      formData.append("image", editCategory.thumb_image);
    }
    if (
      editCategory.parent_category_id !== 0 &&
      editCategory.parent_category_id !== "0" &&
      editCategory.parent_category_id !== ""
    ) {
      formData.append("parent_category_id", editCategory.parent_category_id);
    }
    formData.append("_method", "PUT");

    try {
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.crudCategory}/${CategoryId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const res = await response.json();

      if (res.status === 200) {
        setDataToCookies("isCategoryEdit", true);
        showToast("success", "Category Updated Successfully");
        handleCloseEditModel();
        getCategories();
      } else if (res.status === 400) {
        if (
          res.message ===
          "Parent category id can't be same with current category id"
        ) {
          setCategoryError(
            "Parent category id can't be same with current category id"
          );
        } else if (res.errors) {
          if (res.errors.name) {
            setNameError(res.errors.name[0]);
          }
          if (res.errors.image) {
            setThumbnailError(res.errors.image[0]);
          }
        }
      } else if (res.status === 401) {
        if (res.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          navigate(Navigation.login);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          showToast("info", "Session expired!");
        } else {
          showToast("error", res.message);
        }
      } else {
        showToast("info", "Something went wrong");
      }
      if (res.status !== 200) {
        setLoadingButtonEdit(false);
      }
    } catch (error) {
      setLoadingButtonEdit(false);
      console.error("An error occurred:", error);
      showToast("error", "An unexpected error occurred.");
    }
  };

  return (
    <>
      <div className="modailmaindiv">
        <div className="AdminEditBrandBox">
          <h1 className="category-modal-heading">Edit Category</h1>

          <span className="modal-close" onClick={handleCloseEditModel}>
            <FontAwesomeIcon icon={faTimes} />
          </span>

          <input
            type="text"
            name="name"
            className="Add_category_input"
            value={editCategory.name}
            onChange={handleInputChange}
            placeholder="Category Name"
            maxLength={30}
          />

          {nameError && <label className="red">{nameError}</label>}

          {isNotParentCategory && (
            <div>
              <label className="Add_category_label">
                <select
                  name="parent_category"
                  className="Add_category_input"
                  placeholder="Parent Category"
                  onChange={handleInputChange}
                  onClick={handleInputChange}
                >
                  <option value="">is Parent</option>
                  {parentCategories &&
                    parentCategories.map((parentCategory, index) => {
                      return (
                        <option
                          key={index}
                          value={parentCategory.id}
                          selected={
                            editCategory.parent_category_id === parentCategory.id
                          }
                          disabled={
                            editCategory.parent_category_id === parentCategory.id
                          }
                        >
                          {parentCategory.name}
                        </option>
                      );
                    })}
                </select>
                <span className="Add_category_span">Parent Category</span>
              </label>
              <li className="errorCategoryEdit">{categoryError}</li>
            </div>
          )}
          <div className="Category_thumbnailOuterContainer">
            <div>
              <label>Image</label>
              <div
                onClick={thumbImgUpload}
                className="modal-dropzone"
                onMouseEnter={handleMouseEnterThumbImg}
                onMouseLeave={handleMouseLeaveThumbImg}
              >
                {container1.thumb_image ? (
                  <div className="Category_image-placeholderForThumbnail">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        className="Category_image-storing-containerForThumbnail"
                        src={container1.thumb_image}
                        loading="lazy"
                      />
                    </div>
                    {container1.hovering && (
                      <button
                        id="sellers-removeThumbImgae-ofProduct"
                        className="delete-button"
                        onClick={handleDeleteThumbClick}
                      >
                        Remove Image
                      </button>
                    )}
                  </div>
                ) : (
                  <p
                    id="sellers-clickToUpload-Thumbimage-ofProduct"
                    className="click-to-upload"
                  >
                    Click to upload
                  </p>
                )}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp, .gif"
                  name="thumb_image"
                  onChange={handleInputChange}
                  id="thumb-img-input"
                  hidden
                />
              </div>
              <label className="red">{thumbnailError}</label>
            </div>
          </div>
          <div className="modal-buttons ">
            <button
              type="button"
              className="btn secondary-btn"
              onClick={handleCloseEditModel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn primary-btn"
              onClick={() => {
                if (!loadingButtonEdit) {
                  handelCategoryEditSubmit();
                }
              }}
            >
              {loadingButtonEdit ? <LoaderButton /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
