import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigate from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import "./order.css";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import Navigation from "../../../config/Navigation.json";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const AllOrders = () => {
  const { showToast } = Toast();
  const maxDate = new Date().toISOString().split("T")[0]; // Get today's date
  const minDate = "2024-01-01"; // Set minimum date
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let currentStatus = queryParams.get("status");
  const filterStatus = currentStatus ? currentStatus : "All";

  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);

  let [data, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderCount, setOrderCount] = useState({});
  const [orderStatus, setOrderStatus] = useState("");
  const [pagination, setPagination] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tablePageIndex, setTablePageIndex] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(15);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [isApplyDateFilter, setIsApplyDateFilter] = useState(false);
  const [isSearchApply, setIsSearchApply] = useState(false);
  let url = `${API_HOST}/${APIEndpoint.getAllOrders}?page=${currentPage}&per_page=${ordersPerPage}`;

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const fetchOrders = async (status, url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      setLoadingDateFilter(false);
      return;
    }

    // Update exact status
    if (status) {
      if (status === "All") {
        url = `${url}`;
      } else if (status === "In Transit") {
        url =
          `${url}` +
          `&order_status=Admin to Seller Pickup Processing,Seller to Buyer Delivery Processing,Failed in Sanitizing Return Processing,Admin to Buyer Delivery Processing,Admin to Buyer Out For Delivery,Seller to Buyer Out For Delivery,Buyer to Admin Return Processing,Admin to Seller Return Processing,Buyer to Seller Return Processing`;
      } else if (status === "Packaging") {
        url =
          `${url}` +
          `&order_status=Sanitizing and Packaging,Failed in Sanitizing and Packaging,Passed in Sanitizing and Packaging`;
      } else if (status === "Returned") {
        url =
          `${url}` +
          `&order_status=Failed in Sanitizing Return Completed,Buyer to Admin Return Completed,Admin to Seller Return Completed,Buyer to Seller Return Completed`;
      } else {
        url = `${url}` + `&order_status=${status}`;
      }
    }

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    if (isApplyDateFilter) {
      url =
        `${url}` +
        "&start_date=" +
        formattedStartDate +
        "&end_date=" +
        formattedEndDate;
    }

    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    setLoading(true);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setPagination(res.data.orders);
        const dataArray = res.data.orders.data;
        const updatedData = [...[], ...dataArray];
        setOrderData(updatedData);
        setOrderCount(res.data.counts);
        setCurrentPage(res.data.orders.current_page);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 400) {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setOrderData([]);
        setPagination("");
        setOrderCount(res.data.counts);
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setSearchLoading(false);
    setLoadingDateFilter(false);
    setLoading(false);
  };

  const handleURLChange = (url) => {
    window.scrollTo(0, 0);
    if (startDate !== "" || endDate !== "") {
      setIsApplyDateFilter(true);
    }
    fetchOrders(orderStatus, url);
  };

  // set orderStatus on sidebar menu click
  useEffect(() => {
    setStartDate(minDate);
    setEndDate(maxDate);
    setSearchQuery("");
    setTablePageIndex(0);
    setIsApplyDateFilter(false);
    setOrderStatus(filterStatus);
    fetchOrders(filterStatus, url);
  }, [filterStatus]);

  // handle search api calls
  useEffect(() => {
    if (isSearchApply) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        data = [];
        let url = `${API_HOST}/${APIEndpoint.getAllOrders}?page=${currentPage}&per_page=${ordersPerPage}`;
        if (searchQuery !== "") {
          url = url + "&search=" + searchQuery;
          fetchOrders(orderStatus, url);
        } else {
          fetchOrders(orderStatus, url);
        }
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const ApplyDateFilterAPIcall = () => {
    let url = `${API_HOST}/${APIEndpoint.getAllOrders}?page=${currentPage}&per_page=${ordersPerPage}`;
    fetchOrders(orderStatus, url);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    setStartDateError("");
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    setEndDateError("");
  };

  const [loadingDateFilter, setLoadingDateFilter] = useState(false);
  const applyFilters = async () => {
    setLoadingDateFilter(true);
    let error = false;
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    if (!startDate) {
      setStartDateError("Start Date is required");
      error = true;
    } else if (startDateObj > endDateObj) {
      setStartDateError("Start date cannot be greater than end date.");
      error = true;
    } else {
      setStartDateError("");
    }

    if (!endDate) {
      setEndDateError("End Date is required.");
      error = true;
    } else {
      setEndDateError("");
    }
    if (error) {
      setLoadingDateFilter(false);
      return;
    }
    setIsApplyDateFilter(true);
    ApplyDateFilterAPIcall();
  };

  const clearFilterData = () => {
    setIsApplyDateFilter(false);
    let url = `${API_HOST}/${APIEndpoint.getAllOrders}?page=${currentPage}&per_page=${ordersPerPage}`;
    fetchOrders(orderStatus, url);
    setStartDate(minDate);
    setEndDate(maxDate);
    setEndDateError("");
    setStartDateError("");
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        Footer: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * ordersPerPage,
      },
      {
        Header: "Order No.",
        accessor: "order_number",
      },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>
            <div>
              <span style={{ fontWeight: "bold" }}>Date: </span>
              {new Date(value).toLocaleDateString()}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Time: </span>
              {new Date(value).toLocaleTimeString()}
            </div>
          </>
        ),
      },
      {
        Header: "Customer Info.",
        accessor: (row) => {
          if (row.customer) {
            const fullName = `${row.customer.f_name} ${
              row.customer.l_name || ""
            }`;
            return `${fullName}\n${row.customer.phone}`;
          } else {
            return "Not available";
          }
        },
        Cell: ({ value }) => (
          <div className="cell-container">
            {value &&
              value
                .split("\n")
                .map((info, index) => <div key={index}>{info}</div>)}
          </div>
        ),
      },
      {
        Header: "Total Amount",
        Footer: "Total Amount",
        accessor: "order_amount",
        Cell: ({ value, row }) => (
          <div className="cell-container">
            ₹{value}
            <br />
            <span
              style={{
                color: row.original.payment_status === "Paid" ? "green" : "red",
              }}
            >
              {row.original.payment_status}
            </span>
          </div>
        ),
      },
      {
        Header: "Order Status",
        accessor: "order_status",
        Cell: ({ value }) => (
          <>
            {value === "Pending" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffe082" }}
              >
                Pending
              </p>
            ) : value === "Delivered" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "green", color: "white" }}
              >
                Delivered
              </p>
            ) : value === "Confirmed" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#c3e5a0" }}
              >
                Confirmed
              </p>
            ) : value === "Out For Delivery" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", whiteSpace: "nowrap" }}
              >
                Out For Delivery
              </p>
            ) : value === "Cancelled" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffbcbc", whiteSpace: "nowrap" }}
              >
                Cancelled
              </p>
            ) : (
              <p
                className="order-status"
                style={{ backgroundColor: "#cbcbcb" }}
              >
                {value}
              </p>
            )}
          </>
        ),
      },
      {
        Header: "Actions",
        Footer: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <button
            onClick={() => {
              navigate(`${Navigate.orderDetails}/${value}`);
            }}
            className="action-icon eye-button"
            title="View Order"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tablePageIndex,
        pageSize: ordersPerPage,
        globalFilter: "",
      },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // const maxDate = new Date().toISOString().split("T")[0]; // Get today's date
  // const minDate = "2024-01-01"; // Set minimum date
  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">{orderStatus} Orders</li>
        </div>
      </div>

      <div className="page-container">
        {orderStatus === "All" && (
          <div className="data-counter">
            <div className="counter-item">
              <p>All Orders</p>
              <p>{orderCount.all_order}</p>
            </div>
            <div className="counter-item">
              <p>Pending Orders</p>
              <p>{orderCount.pending}</p>
            </div>
            <div className="counter-item">
              <p>Confirmed Orders</p>
              <p>{orderCount.confirmed}</p>
            </div>
            <div className="counter-item">
              <p>Cancelled Orders</p>
              <p>{orderCount.cancelled}</p>
            </div>
            <div className="counter-item">
              <p>Packaging Orders</p>
              <p>{orderCount.packaging}</p>
            </div>
            <div className="counter-item">
              <p>In Transit Orders</p>
              <p>{orderCount.in_transit}</p>
            </div>
            <div className="counter-item">
              <p>Delivered Orders</p>
              <p>{orderCount.delivered}</p>
            </div>
            <div className="counter-item">
              <p>Returned Orders</p>
              <p>{orderCount.returned}</p>
            </div>
          </div>
        )}

        <div className="table-serach-area">
          {/* search bar */}
          <div className="input-div-date-orders">
            {" "}
            <div className="Start-End-Box-c1">
              <input
                placeholder="Start Date"
                type="date"
                className="search-input-order"
                value={startDate}
                onChange={handleStartDateChange}
                min={minDate}
                max={maxDate} // Set maximum date
                style={{
                  backgroundColor: "#fff",
                  height: "40px",
                  fontSize: "14px",
                }}
              />
              {startDateError && (
                <div className="error-message-order">{startDateError}</div>
              )}
            </div>
            <div className="Start-End-Box-c1 end-date-box">
              <input
                placeholder="End Date"
                type="date"
                className="search-input-order"
                value={endDate}
                onChange={handleEndDateChange}
                min={minDate}
                max={maxDate} // Set maximum date
                style={{
                  backgroundColor: "#fff",
                  height: "40px",
                  fontSize: "14px",
                }}
              />
              {endDateError && (
                <div className="error-message-order">{endDateError}</div>
              )}
            </div>
            <button
              onClick={() => {
                if (!loadingDateFilter) {
                  applyFilters();
                }
              }}
              className="btn primary-btn"
            >
              {loadingDateFilter ? <LoaderButton /> : "Apply"}
            </button>
            <button onClick={clearFilterData} className="btn secondary-btn">
              Clear
            </button>
          </div>
          <div className="search-bar search-box-c1-order">
            <div className="fontAwsomeDiv order-font-div">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order order-div-alignment"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApply(true);
              }}
            />
          </div>
        </div>

        {/* Data table */}
        <div className="InnerTableContainer">
          <table {...getTableProps()} className="order-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} style={{ overflow: "auto" }}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}

        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={contextObj.inputValue.activeOrderFilter + " Orders"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
