import { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./blog.css";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import stringConstant from "../../../config/ProjectConstants.json";
import Navigation from "../../../config/Navigation.json";
import Paginate from "../../../Components/Pagination/Paginate";
import placeHolder from "../../../Assets/image-placeholder.png";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const BlogList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const contextObj = useContext(InputContext);
  const [blogList, setBloglist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [deleteBlogTitle, setDeleteBlogTitle] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  // handle pagination
  const dataPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getAllBlogs(data);
  };

  const getAllBlogs = async (url) => {
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }

    await fetch(`${url}&per_page=${dataPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setPagination(res.data);
          const newdataArray = res.data.data;
          setCurrentPage(res.data.current_page);
          setBloglist(newdataArray);
        } else if (res.status === 404) {
          setBloglist([]);
          setPagination("");
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
        setSearchLoading(false);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getAllBlogs(
      `${API_HOST}/${APIEndpoint.CrudBlogOperationByAdmin}?page_no=1`
    );
  }, []);

  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        getAllBlogs(
          `${API_HOST}/${APIEndpoint.CrudBlogOperationByAdmin}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  // Delete Blog API Integration
  const handleDeleteClickpopup = (id, title) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    setDeleteBlogTitle(title);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = (id) => {
    fetch(`${API_HOST}/${APIEndpoint.CrudBlogOperationByAdmin}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          showToast(
            stringConstant.alertErrorMessage.alertseveritysuccess,
            stringConstant.alertErrorMessage.alertForBlogDeleted
          );
          handleCancelDelete();
          getAllBlogs(
            `${API_HOST}/${APIEndpoint.CrudBlogOperationByAdmin}?page_no=1`
          );
        } else if (
          response.status === 401 &&
          response.status === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status === 401 && response.message !== "") {
          showToast("error", response.message);
        } else if (response.status === 409) {
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };
  // React Table implementation

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * dataPerPage,
      },
      {
        Header: "Image",
        accessor: "thumbnail",
        Cell: ({ value }) => (
          <img
            className="Blog_img"
            src={imgHost + "/" + value}
            onError={(e) => {
              e.target.src = placeHolder;
            }}
            alt="Image"
          />
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          if (value === "Published") {
            color = "white";
            backgroundColor = "#4CAF50";
          } else if (value === "Draft") {
            backgroundColor = "#c5c5c5";
            color = "#000000";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const title = row.original.title;
          return (
            <div>
              <button
                onClick={() => {
                  navigate(`/blogs/edit/${id}`);
                }}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                onClick={() => handleDeleteClickpopup(id, title)}
                className="action-icon trash-button"
                title="Delete"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: blogList,
      manualPagination: true,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Blogs</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              navigate("/blog/new");
            }}
            className="btn primary-btn"
          >
            Add Blog
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="Blogs-container">
          <table id="categoryTable" {...getTableProps()}>
            <thead className="Category-grid-container-item2-tableHead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="Category-grid-container-item2-tableHead">
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No Blogs available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Blogs"}
            />
          )}
        </div>

        {showDeleteModal && (
          <>
            <div
              id="deleteModal"
              className="modal fade"
              style={{ display: showDeleteModal ? "block" : "none" }}
            >
              <div className="modal-content">
                <span className="modal-close" onClick={handleCancelDelete}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <h3 className="modal-header">Delete Confirmation</h3>
                <p className="modal-line">
                  Are you sure you want to delete{" "}
                  <span className="deleteitemsNameappPages">
                    {deleteBlogTitle}
                  </span>{" "}
                  Blog?
                </p>
                <div className="modal-buttons">
                  <button
                    className="btn primary-btn"
                    onClick={() => handleConfirmDelete(deleteId)}
                  >
                    Yes, Delete It!
                  </button>
                  <button
                    className="btn secondary-btn"
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlogList;
