import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faFaceSmileBeam,
  faFaceGrinBeam,
} from "@fortawesome/free-solid-svg-icons";

const FaceRating = ({ totalFaces, onRatingChange, rating }) => {
  const [currentRating, setCurrentRating] = useState(0);

  useEffect(() => {
    if (rating !== undefined) {
      setCurrentRating(rating);
    }
  }, [rating]);

  const handleClick = (rate) => {
    setCurrentRating(rate);
    onRatingChange(rate);
  };

  const getFaceIcon = (rate) => {
    switch (rate) {
      case 1:
        return faFaceFrown;
      case 2:
        return faFaceMeh;
      case 3:
        return faFaceSmile;
      case 4:
        return faFaceSmileBeam;
      case 5:
        return faFaceGrinBeam;
      default:
        return faFaceSmile;
    }
  };

  return (
    <div
      style={{
        width: "max-content",
      }}
    >
      <FontAwesomeIcon
        // key={index}
        icon={faFaceFrown}
        onClick={() => {
          if (onRatingChange !== undefined) {
            handleClick(1);
          }
        }}
        style={{
          color: 1 === currentRating ? "#ffd700" : "#123153",
          cursor: "pointer",
          fontSize: "24px",
          margin: "4px",
        }}
      />
      <FontAwesomeIcon
        // key={index}
        icon={faFaceMeh}
        onClick={() => {
          if (onRatingChange !== undefined) {
            handleClick(2);
          }
        }}
        style={{
          color: 2 === currentRating ? "#ffd700" : "#123153",
          cursor: "pointer",
          fontSize: "24px",
          margin: "4px",
        }}
      />
      {/* rating - 3 */}
      <FontAwesomeIcon
        // key={index}
        icon={faFaceSmile}
        onClick={() => {
          if (onRatingChange !== undefined) {
            handleClick(3);
          }
        }}
        style={{
          color: 3 === currentRating ? "#ffd700" : "#123153",
          cursor: "pointer",
          fontSize: "24px",
          margin: "4px",
        }}
      />
      {/* rating - 4 */}
      <FontAwesomeIcon
        // key={index}
        icon={faFaceSmileBeam}
        onClick={() => {
          if (onRatingChange !== undefined) {
            handleClick(4);
          }
        }}
        style={{
          color: 4 === currentRating ? "#ffd700" : "#123153",
          cursor: "pointer",
          fontSize: "24px",
          margin: "4px",
        }}
      />
      {/* rating - 5 */}
      <FontAwesomeIcon
        // key={index}
        icon={faFaceSmile}
        onClick={() => {
          if (onRatingChange !== undefined) {
            handleClick(5);
          }
        }}
        style={{
          color: 5 === currentRating ? "#ffd700" : "#123153",
          cursor: "pointer",
          fontSize: "24px",
          margin: "4px",
        }}
      />
      {/* ))} */}
    </div>
  );
};

export default FaceRating;
