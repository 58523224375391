import React, { useEffect, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTimes,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const PaymentDetails = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const { paymentId } = useParams();
  const navigate = new useNavigate();
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [paymentDataTo, setPaymentDataTo] = useState([]);
  const [paymentDataFrom, setPaymentDataFrom] = useState([]);
  const [loadingModalBtn, setLoadingModalBtn] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentStatusError, setPaymentStatusError] = useState("");
  const { showToast } = Toast();
  const truncateText = (text) => {
    if (text && text.length > 25) {
      return text.substring(0, 25) + "...";
    }
    return text;
  };
  const handleChnagePaymentStatus = (e) => {
    setPaymentStatus(e.target.value);
    setPaymentStatusError("");
  };

  const openPaymentStatusUpdateModal = () => {
    document.getElementById("paymentStatusUpdateModal").style.display = "block";
  };

  const closePaymentStatusUpdateModal = () => {
    document.getElementById("paymentStatusUpdateModal").style.display = "none";
    setPaymentStatus("");
    setPaymentStatusError("");
  };

  const fetchPaymentDetails = async () => {
    const url = `${API_HOST}/api/admin/payments/${paymentId}`;
    const your_access_token = getDataFromCookies("7b9KpQ3rW6");
    if (!your_access_token) window.location.href = "/";

    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${your_access_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setData(data);
          setPaymentStatus(data.status);
          setProductData(data.product_details);
          setPaymentDataTo(data.to);
          setPaymentDataFrom(data.from);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else if (res.status === 404) {
          showToast(
            "error",
            "Oops Sorry!! Payments you are looking for does not exist."
          );
        } else {
          console.error(res);
        }
      });
  };

  const updatePaymentStatus = async () => {
    const url = `${API_HOST}/api/admin/payments/${paymentId}`;
    const your_access_token = getDataFromCookies("7b9KpQ3rW6");
    if (!your_access_token) window.location.href = "/";

    if (paymentStatus === "") {
      setPaymentStatusError("This field is required");
      return;
    }

    const payload = {
      id: paymentId,
      status: paymentStatus,
    };

    setLoadingModalBtn(true);
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${your_access_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 200) {
      setDataToCookies("isFromPaymentPage", true);
      showToast("success", res.message);
      window.history.back();
    } else if (res.status === 400) {
      showToast("error", res.message);
    }
    closePaymentStatusUpdateModal();
    setLoadingModalBtn(false);
  };
  useEffect(() => {
    fetchPaymentDetails(paymentId);
  }, []);

  function formatDateAndTime(value) {
    const date = new Date(value);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleString(undefined, options);
    const formattedTime = date.toLocaleTimeString();
    const newVariable = formattedDate + " " + formattedTime;
    return newVariable;
  }

  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <FontAwesomeIcon
              cont
              className="back-icon"
              onClick={() => {
                setDataToCookies("isFromPaymentPage", true);
                // sessionStorage.setItem("selectedStatus", selectedStatus);
                window.history.back();
              }}
              icon={faArrowLeft}
            />
            <li className="active">Payment Details</li>
          </div>
        </div>

        <div className="page-container">
          <div className="orderdetails-body">
            <div className="left-div">
              <div>
                <div className="content-head">
                  <div className="order-id">
                    <h4 className="payment-order-details-ordNo">
                      Order ID: {data.id}
                    </h4>
                    <div style={{ position: "relative", marginTop: "-12px" }}>
                      <FontAwesomeIcon
                        style={{ fontSize: "8px" }}
                        icon={faCalendarDays}
                      />
                      <span>{formatDateAndTime(data.created_at)}</span>
                    </div>
                  </div>
                  <div className="order-status paymeny-text-align-right">
                    <p>
                      <span className="bold">Status: </span>
                      {data.status}
                    </p>
                  </div>
                </div>

                <div className="payment-details-container">
                  <div className="payment-details-holder">
                    <div className="payment-details-header-container">
                      <div className="payment-details-header">
                        <p>Product</p>
                      </div>
                      <div
                        className=" payment-details-product-section"
                        id="payment-product-image"
                      >
                        <div className="payment-product-image">
                          <img
                            className="product-image"
                            src={imgHost + "/" + productData.thumb_image}
                            alt="image"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="payment-details-header-container">
                      <div className="payment-details-header">
                        <p>Name</p>
                      </div>
                      <div className="payment-name-price-box payment-details-product-section">
                        <p className="product-title">
                          <span className="bold">
                            {" "}
                            {truncateText(productData.name)}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="payment-details-header-container ">
                      <div
                        className="payment-details-header"
                        id="payment-details-header-Total"
                      >
                        <p>Total</p>
                      </div>
                      <div className="payment-details-data payment-details-product-section">
                        <p className="product-price">
                           <span className="bold">
                            ₹ {data.amount}
                          </span>
                        </p>
                        <p className="shipping-charge">
                          Shipping Charge:
                          <span className="bold">
                            {" - "}₹ {data.shipping_cost}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="payment-details-total">
                    <p>
                      Total:
                      <span className="product-price">
                        {" "}
                        ₹ {data.payable_amount}
                      </span>
                    </p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
            <div className="right-div">
              <div className="top-div">
                <h4 className="box-heading">Payment Status</h4>
                <label htmlFor="order-status" className="order-status-label">
                  {data.status}
                </label>
                <button
                  type="button"
                  className="create-btnPayment"
                  onClick={openPaymentStatusUpdateModal}
                >
                  Update Status
                </button>
              </div>
              <div className="bottom-div">
                <h4 className="box-heading">Payment From</h4>
                <p>Name:- {paymentDataFrom.account_holder_name}</p>
                <p>Account No:- {paymentDataFrom.account_number}</p>
                <p>Bank:- {paymentDataFrom.bank_name}</p>
                <p>Branch:- {paymentDataFrom.branch_name}</p>
                <p>IFSC Code:- {paymentDataFrom.ifsc_code}</p>
                <p>UPI ID:- {paymentDataFrom.upi_id}</p>
              </div>
              <div className="bottom-div">
                <h4 className="box-heading">Payment To</h4>
                <p>Name:- {paymentDataTo.account_holder_name}</p>
                <p>Account No:- {paymentDataTo.account_number}</p>
                <p>Bank:- {paymentDataTo.bank_name}</p>
                <p>Branch:- {paymentDataTo.branch_name}</p>
                <p>IFSC Code:- {paymentDataTo.ifsc_code}</p>
                <p>UPI ID:- {paymentDataTo.upi_id}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Status update modal */}
      <div id="paymentStatusUpdateModal" className="order modal fade">
        <div className="modal-content">
          <span
            className="order modal-close"
            onClick={closePaymentStatusUpdateModal}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <div className="order-modal-header">
            <p className="modal-heading">Update payment status</p>
          </div>
          <div className="modal-body">
            <label>Status</label>
            <select
              className="form-control"
              value={paymentStatus}
              onChange={handleChnagePaymentStatus}
            >
              <option value="">Select</option>
              <option value="Initiated">Initiated</option>
              <option value="Processing">Processing</option>
              <option value="Completed">Completed</option>
            </select>
            {paymentStatusError && (
              <label htmlFor="error" className="error">
                {paymentStatusError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn secondary-btn"
                  onClick={closePaymentStatusUpdateModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn"
                  onClick={() => {
                    updatePaymentStatus();
                  }}
                >
                  Create
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
