import React, { useEffect, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../Components/Toast";
import Navigation from "../../../config/Navigation.json";
import APIEndpoint from "../../../config/APIEndpoints.json";

// import css
import "../Orders/order.css";
import "../../../Assets/style.css";

// define constants
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const DonationDetails = ({ isToggleOn, selectedStatus, handleClickOrder }) => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = new useNavigate();
  const { showToast } = Toast();
  const { donationId } = useParams();
  const [donationData, setDonationData] = useState();
  const [donationStatusActivity, setDonationStatusActivity] = useState([]);
  const [ngoData, setNgoData] = useState();
  const [productData, setProductData] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState();
  const [pickupAddress, setPickupAddress] = useState();
  const [donorData, setDonorData] = useState();
  const [donationActionLoading, setDonationActionLoading] = useState(false);

  // order update
  const [donationCancelNote, setDonationCancelNote] = useState("");
  const [donationCancelNoteError, setDonationCancelNoteError] = useState("");
  const [productBackInStock, setProductBackInStock] = useState(false);
  const [productBackInStockError, setProductBackInStockError] = useState("");
  const [productCancelNote, setProductCancelNote] = useState("");
  const [productCancelNoteError, setProductCancelNoteError] = useState("");

  // initiate refund
  const [refundNote, setRefundNote] = useState("");
  const [refundNoteError, setRefundNoteError] = useState("");

  // create shipment
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingMethodError, setShippingMethodError] = useState("");
  const [shipmentFor, setShipmentFor] = useState("");
  const [shipmentForError, setShipmentForError] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseError, setWarehouseError] = useState("");
  const [loadingModalBtn, setLoadingModalBtn] = useState(false);
  const [anyActiveShipment, setAnyActiveShipment] = useState(false);
  const [enableScheduleDate, setEnableScheduleDate] = useState(false);
  const [pickupScheduleDate, setPickupScheduleDate] = useState("");
  const [pickupScheduleError, setPickupScheduleError] = useState("");
  const [enableShippingCost, setEnableShippingCost] = useState(false);
  const [shippingCost, setShippingCost] = useState("");
  const [shippingCostError, setShippingCostError] = useState("");
  const [shipmentCreateError, setShipmentCreateError] = useState("");
  const [shipmentOpen, setShipmentOpen] = useState(false);

  // shipment update
  const [updateShipmentId, setUpdateShipmentId] = useState("");
  const [enableShipmentCancel, setEnableShipmentCancel] = useState(false);
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [shipmentStatusError, setShipmentStatusError] = useState("");
  const [shipmentCancelNote, setShipmentCancelNote] = useState("");
  const [shipmentCancelError, setShipmentCancelError] = useState("");
  const [shipmentUpdateError, setShipmentUpdateError] = useState("");

  // modal dropdown values
  const shippingMethods = ["JOYREJOY"];
  const [shipmentToList, setShipmentToList] = useState([]);
  const [shipmentStatusList, setShipmentStatusList] = useState([]);

  // get order details by donationId
  const fetchOrderDetails = async () => {
    const url = `${API_HOST}/${APIEndpoint.donations}/${donationId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const data = await res.data;
      setDonationData(data.donation);
      setNgoData(data.ngo);
      setShippingAddress(data.shipping_address);
      setProductData(data.product);
      setDonorData(data.donor);
      setPickupAddress(data.pickup_address);
      setDonationStatusActivity(data.donation.status_tracker);
      setAnyActiveShipment(data.has_active_shipment);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      showToast(
        "error",
        "Oops Sorry! Order you are looking for does not exist."
      );
      navigate(Navigation.orders);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // get order details by donationId
  const fetchShipments = async () => {
    const url = `${API_HOST}/api/admin/shipments-of-donation/${donationId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const data = await res.data;
      setShipmentData(data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setShipmentData([]);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const [enableWarehouse, setEnableWarehouse] = useState(false);
  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchOrderDetails();
    fetchShipments();
    getWereHouseAddress();
  }, []);

  const updateDonationStatus = async (status) => {
    let payload = {
      status: status,
    };
    if (status === "Failed in Sanitizing and Packaging") {
      setLoadingModalBtn(true);

      let hasError = false;
      if (donationCancelNote === "") {
        setDonationCancelNoteError("This field is required");
        hasError = true;
      }
      if (!productBackInStock && productCancelNote === "") {
        setProductCancelNoteError("This field is required");
        hasError = true;
      }
      if (hasError) {
        setLoadingModalBtn(false);
        return;
      }

      payload["donation_cancel_reason"] = donationCancelNote;
      payload["product_cancel_reason"] = productCancelNote;
    } else {
      setDonationActionLoading(true);
    }

    const url = `${API_HOST}/${APIEndpoint.donations}/${donationId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 200) {
      fetchOrderDetails();
      showToast("success", res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }

    if (status === "Failed in Sanitizing and Packaging") {
      setLoadingModalBtn(false);
      closeSanitizationFailModal();
    } else {
      setDonationActionLoading(false);
    }
  };

  // Order decline modal open
  const openSanitizationFailModal = () => {
    document.getElementById("sanitizationFailModal").style.display = "block";
  };

  // Order decline modal close
  const closeSanitizationFailModal = () => {
    document.getElementById("sanitizationFailModal").style.display = "none";

    setDonationCancelNote("");
    setDonationCancelNoteError("");
    setProductCancelNote("");
    setProductCancelNoteError("");
  };

  // Order decline modal open
  const openDonationDeclineModal = () => {
    document.getElementById("orderDeniedModal").style.display = "block";
  };

  // Order decline modal close
  const closeOrderDeclineModal = () => {
    document.getElementById("orderDeniedModal").style.display = "none";

    setDonationCancelNote("");
    setDonationCancelNoteError("");
    setProductBackInStock(false);
    setProductBackInStockError("");
    setProductCancelNote("");
    setProductCancelNoteError("");
  };

  // Order decline modal close
  const closeRefundModal = () => {
    document.getElementById("refundModal").style.display = "none";
    setRefundNote("");
    setRefundNoteError("");
  };

  // status change from Pending to Decline.
  const initiateRefund = async () => {
    setLoadingModalBtn(true);
    let hasError = false;

    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    if (refundNote === "") {
      setRefundNoteError("This field is required");
      hasError = true;
    }

    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    const payload = {
      order_id: donationId,
      refund_reason: refundNote,
    };

    const response = await fetch(`${API_HOST}/api/admin/initiate-refund`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 201) {
      fetchOrderDetails();
      showToast("success", res.message);
      closeRefundModal();
    } else if (res.status === 404 || res.status === 422) {
      showToast("error", res.message);
      closeRefundModal();
    } else if (res.status === 400) {
      let errors = "";
      if (res.errors) {
        errors = res.errors;
      } else {
        errors = res.message;
      }
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setRefundNoteError(errorMessage);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setLoadingModalBtn(false);
  };

  // status change from Pending to Decline.
  const donationStatusToDeclined = async () => {
    setLoadingModalBtn(true);

    const url = `${API_HOST}/${APIEndpoint.donations}/${donationId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    let hasError = false;
    if (donationCancelNote === "") {
      setDonationCancelNoteError("This field is required");
      hasError = true;
    }
    if (!productBackInStock && productCancelNote === "") {
      setProductCancelNoteError("This field is required");
      hasError = true;
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    const payload = {
      order_id: donationId,
      status: "Cancelled",
      product_back_in_stock: productBackInStock ? 1 : 0,
      donation_cancel_reason: donationCancelNote,
      product_cancel_reason: productCancelNote,
    };
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 200) {
      fetchOrderDetails();
      showToast("success", "Order cancelled successfully");
    } else if (res.status === 400 && res.message) {
      showToast("error", res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    closeOrderDeclineModal();
    setLoadingModalBtn(false);
  };

  // Shipment modal open
  const openShipmentModal = () => {
    document.getElementById("shipmentModal").style.display = "block";
  };

  // Order decline modal close
  const closeShipmentModal = () => {
    document.getElementById("shipmentModal").style.display = "none";
    setShippingMethod("");
    setShippingMethodError("");
    setShipmentFor("");
    setShipmentForError("");
    setEnableScheduleDate(false);
    setEnableShippingCost(false);
    setPickupScheduleDate("");
    setPickupScheduleError("");
    setShipmentCreateError("");
    setEnableWarehouse(false);
    setWarehouseId("");
    setWarehouseError("");
  };

  // call create forward shipment for this order
  const createForwardShipment = async (payload) => {
    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(`${API_HOST}/api/admin/donation-shipment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 201) {
      fetchOrderDetails();
      fetchShipments();
      showToast("success", "Shipment created successfully");
      closeShipmentModal();
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      if (errorMessage === "") {
        errorMessage = res.message;
      }
      setShipmentCreateError(errorMessage);
    } else if (res.status === 409) {
      setShipmentCreateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // create return shipment for this order
  const createReturnShipment = async (payload) => {
    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(
      `${API_HOST}/api/admin/donation-return-shipment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 201) {
      fetchOrderDetails();
      fetchShipments();
      showToast("success", "Shipment created successfully");
      closeShipmentModal();
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      if (errorMessage === "") {
        errorMessage = res.message;
      }
      setShipmentCreateError(errorMessage);
    } else if (res.status === 409) {
      setShipmentCreateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // create shipment for this order
  const createShipment = async () => {
    setLoadingModalBtn(true);

    // check validation
    let hasError = false;
    if (shipmentFor === "") {
      hasError = true;
      setShipmentForError("This field is required");
    }
    if (shippingMethod === "") {
      hasError = true;
      setShippingMethodError("This field is required");
    } else if (enableScheduleDate && pickupScheduleDate === "") {
      hasError = true;
      setPickupScheduleError("This field is required");
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    // build payload
    const payload = {
      donation_id: donationId,
      shipment_to: shipmentFor,
      shipping_method: shippingMethod,
    };
    if (enableWarehouse && warehouseId !== "") {
      payload["warehouse_id"] = warehouseId;
    }

    if (enableScheduleDate) {
      const date = new Date(pickupScheduleDate);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      payload["pickup_scheduled_date"] = formattedDate;
    }
    if (enableShippingCost) {
      payload["shipping_cost"] = shippingCost;
    }

    if (
      shipmentFor === "Admin to NGO" ||
      shipmentFor === "Seller to Admin" ||
      shipmentFor === "Seller to NGO"
    ) {
      createForwardShipment(payload);
    } else if (
      shipmentFor === "Admin to Seller" ||
      shipmentFor === "NGO to Admin" ||
      shipmentFor === "NGO to Seller"
    ) {
      createReturnShipment(payload);
    }
  };

  const handleShippingMethodChange = (e) => {
    const val = e.target.value;
    setShippingMethod(val);
    if (val === "JOYREJOY") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      setPickupScheduleDate(`${year}-${month}-${day}`);

      setEnableScheduleDate(true);
      setEnableShippingCost(true);
    } else {
      setEnableScheduleDate(false);
      setEnableShippingCost(false);
    }
    setShippingMethodError("");
    setShipmentCreateError("");
    setPickupScheduleError("");
    setShippingCost("");
    setShippingCostError("");
  };

  const handleShipmentForChange = (e) => {
    setShipmentFor(e.target.value);
    setShipmentForError("");
    setShipmentCreateError("");
  };

  // Shipment modal open
  const openShipmentUpdateModal = () => {
    document.getElementById("shipmentUpdateModal").style.display = "block";
  };

  // Order decline modal close
  const closeShipmentUpdateModal = () => {
    document.getElementById("shipmentUpdateModal").style.display = "none";
    setEnableShipmentCancel(false);
  };

  const handleShipmentStatus = (e) => {
    const val = e.target.value;
    setShipmentStatus(val);
    if (val === "Cancelled" || val === "Undelivered")
      setEnableShipmentCancel(true);
    else {
      setEnableShipmentCancel(false);
      setShipmentCancelError("");
    }
    setShipmentUpdateError("");
    setShipmentStatusError("");
  };

  // update shipment for this order
  const updateShipmentStatus = async () => {
    // check validation
    let hasError = false;
    if (shipmentStatus === "") {
      setShipmentStatusError("This field is required");
      hasError = true;
    }
    if (enableShipmentCancel && shipmentCancelNote === "") {
      setShipmentCancelError("This field is required");
      hasError = true;
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    // build payload
    const payload = {
      shipment_id: updateShipmentId,
      status: shipmentStatus,
    };
    if (enableShipmentCancel) {
      payload["cancel_reason"] = shipmentCancelNote;
    }

    const response = await fetch(
      `${API_HOST}/api/admin/donation-update-shipment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 200) {
      showToast("success", res.message);
      fetchOrderDetails();
      fetchShipments();
      closeShipmentUpdateModal();
      setShipmentStatus("");
      setShipmentStatusError("");
      setShipmentCancelNote("");
      setShipmentCancelError("");
      setShipmentUpdateError("");
      setEnableShipmentCancel(false);
    } else if (res.status === 400 || res.status === 409) {
      setShipmentUpdateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const getDateTime = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(timestamp));
  };

  const getDate = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date(timestamp));
  };

  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li
              onClick={() => {
                navigate(Navigation.donations);
              }}
            >
              Donations
            </li>
            <li className="active">Details</li>
          </div>
        </div>

        {donationData && (
          <div className="orderdetails-body">
            <div className="left-div">
              {donationData && (
                <div className="order-info">
                  <p>
                    Donation ID <span>{donationData.id}</span>
                  </p>
                  <p>
                    Donated By
                    <span>
                      {donationData.donated_by === "seller"
                        ? "Seller"
                        : "Admin"}
                    </span>
                  </p>
                  <p>
                    Status <span>{donationData.status}</span>
                  </p>
                  <p>
                    Donation Created
                    <span>{getDateTime(donationData.created_at)}</span>
                  </p>
                </div>
              )}
              <div className="product-all-details">
                <div>
                  <div className="image-details">
                    <img
                      className="product-image"
                      src={imgHost + "/" + productData.thumb_image}
                      alt="image"
                      loading="lazy"
                    />
                  </div>
                  <div className="product-details">
                    <div className="product-names">
                      <p>
                        Name<span>{productData.name}</span>
                      </p>
                    </div>
                    <div className="order-prices">
                      {productData.selling_price && (
                        <p>
                          Selling Price
                          <span>₹{productData.selling_price}.00</span>
                        </p>
                      )}
                      {donationData.shipping_cost && (
                        <p>
                          Shipping Cost
                          <span>₹{donationData.shipping_cost}.00</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {productData.selling_price && donationData.shipping_cost && (
                  <div className="order-subtotal">
                    <p className="order-amount">
                      Total:{" "}
                      <span>
                        ₹
                        {productData.selling_price + donationData.shipping_cost}
                        .00
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="order-quick-info">
                {ngoData && (
                  <div className="bottom-div">
                    <h4 className="box-heading">NGO Info</h4>
                    <div>
                      <p>{ngoData.org_name}</p>
                      <p>{ngoData.org_email}</p>
                      <p>{ngoData.org_phone}</p>

                      <p style={{ fontWeight: 500, marginTop: "25px" }}>
                        Contact Person
                      </p>
                      <p>{ngoData.contact_person_name}</p>
                      <p>{ngoData.contact_person_email}</p>
                      <p>{ngoData.contact_person_phone}</p>
                    </div>
                  </div>
                )}
                {shippingAddress && (
                  <div className="bottom-div">
                    <h4 className="box-heading">Shipping Address</h4>
                    <div>
                      <p>{shippingAddress.address1}</p>
                      <p>{shippingAddress.address2}</p>
                      <p>{shippingAddress.city}</p>
                      <p>{shippingAddress.state}</p>
                      <p>{shippingAddress.country}</p>
                      <p>{shippingAddress.pincode}</p>
                      <p>{shippingAddress.pickup_location}</p>
                    </div>
                  </div>
                )}
                {donationData && donorData && (
                  <div className="bottom-div">
                    <h4 className="box-heading">
                      {donationData.donated_by &&
                      donationData.donated_by === "admin"
                        ? "Admin"
                        : "Seller"}{" "}
                      Info
                    </h4>
                    <div>
                      <p>
                        {donorData.f_name} {donorData.l_name}
                      </p>
                      <p>{donorData.phone}</p>
                      <p>{donorData.email}</p>
                    </div>
                  </div>
                )}
                {pickupAddress && (
                  <div className="bottom-div">
                    <h4 className="box-heading">Pick-up Address</h4>
                    <div>
                      <p>{pickupAddress.street_address}</p>
                      <p>
                        {pickupAddress.city}, {pickupAddress.state} -{" "}
                        {pickupAddress.pincode}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {shipmentData.length > 0 && (
                <div className="shipment-details">
                  <div
                    className="shipment-heading"
                    onClick={() => {
                      setShipmentOpen(!shipmentOpen);
                    }}
                  >
                    <h4>Shipment Details</h4>
                    <div className="shipment-up-down-icon">
                      {shipmentOpen ? (
                        <FontAwesomeIcon icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon icon={faAngleDown} />
                      )}
                    </div>
                  </div>
                  {shipmentOpen &&
                    shipmentData.map((data) => (
                      <div className="single-shipment" key={data.id}>
                        <div className="shipment-basic-info">
                          <p>
                            ID: <b>{data.id}</b>
                          </p>
                          <p>
                            Shipping For: <span>{data.shipment_to}</span>
                          </p>
                          <p>
                            Shipping Method: <span>{data.shipping_method}</span>
                          </p>
                          {data.shipping_method === "JOYREJOY" && (
                            <p>
                              Shipping Cost:
                              <span>₹{data.shipping_cost}.00</span>
                            </p>
                          )}
                          <p>
                            Estimated Pickup Scheduled Date:
                            <span>{getDate(data.pickup_scheduled_date)}</span>
                          </p>
                          <p>
                            Created at:
                            <span>{getDateTime(data.created_at)}</span>
                          </p>
                        </div>
                        {data.shipping_method === "Shiprocket" && (
                          <div className="shiprocket-info">
                            <p>
                              Shiprocket Order ID
                              <span>{data.shiprocket_order_id}</span>
                            </p>
                            <p>
                              Shiprocket Shipment ID
                              <span>{data.shiprocket_shipment_id}</span>
                            </p>
                            <p>
                              AWB Code <span>{data.awb_code}</span>
                            </p>
                            <p>
                              Courier Company ID:{" "}
                              <b>{data.courier_company_id}</b>
                            </p>
                            <p>
                              Courier Company Name
                              <span>{data.courier_name}</span>
                            </p>
                            <p>
                              Pickup Location Code:
                              <span>{data.pickup_location}</span>
                            </p>
                            <p>
                              Shipping Cost:{" "}
                              <span>
                                {data.shipping_cost && `₹${data.shipping_cost}`}
                              </span>
                            </p>
                          </div>
                        )}
                        <div className="shipment-status">
                          <p>
                            Status: <span>{data.status}</span>
                          </p>
                          {data.pause_reason && (
                            <p>
                              Pause Reason:
                              <span>{data.pause_reason}</span>
                            </p>
                          )}
                        </div>
                        <div className="shipment-action">
                          <div className="shipment-docs">
                            {data.label && <a href={data.label}>Label</a>}
                            {data.manifest && (
                              <a href={data.manifest}>Manifest</a>
                            )}
                            {data.invoice && <a href={data.invoice}>Invoice</a>}
                          </div>
                          <div>
                            {data.status !== "Cancelled" &&
                              data.is_pause !== "Yes" && (
                                <button
                                  className="info-btn"
                                  onClick={() => {
                                    setUpdateShipmentId(data.id);
                                    // can be delivered or cancelled
                                    if (
                                      donationData.status ===
                                        "Admin to Seller Pickup Processing" ||
                                      donationData.status ===
                                        "Admin to Seller Return Processing" ||
                                      donationData.status ===
                                        "NGO to Seller Return Processing" ||
                                      donationData.status ===
                                        "NGO to Admin Return Processing" ||
                                      donationData.status ===
                                        "Failed in Sanitizing Return Processing"
                                    ) {
                                      setShipmentStatusList([
                                        "Cancelled",
                                        "Delivered",
                                      ]);

                                      // Only delivered
                                    } else if (
                                      donationData.status ===
                                        "Admin to NGO Out For Delivery" ||
                                      donationData.status ===
                                        "Seller to NGO Out For Delivery"
                                    ) {
                                      setShipmentStatusList([
                                        "Delivered",
                                        "Undelivered",
                                      ]);

                                      // only cancelled
                                    } else {
                                      setShipmentStatusList(["Cancelled"]);
                                    }
                                    openShipmentUpdateModal();
                                  }}
                                >
                                  Update
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="right-div">
              <div className="top-div order-action">
                <h4 className="box-heading">Donation Action</h4>
                <ul>
                  {donationData.status === "Pending" && (
                    <li className="confirm-order">
                      Confirm donation?
                      {donationActionLoading ? (
                        <button className="waiting-btn">Please wait...</button>
                      ) : (
                        <>
                          <button
                            className="create-btn"
                            onClick={() => {
                              setDonationActionLoading(true);
                              updateDonationStatus("Confirmed");
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            className="cancel-btn"
                            onClick={openDonationDeclineModal}
                          >
                            Decline
                          </button>
                        </>
                      )}
                    </li>
                  )}

                  {/* Donation delivered info */}
                  {donationData.status === "Delivered" && (
                    <li>🎉 Donation delivered successfully!</li>
                  )}

                  {/* Donation returned info */}
                  {(donationData.donated_by === "admin" &&
                    donationData.status === "NGO Return Completed") ||
                    (donationData.status === "Seller Return Completed" && (
                      <li>Donation returned successfully!</li>
                    ))}

                  {donationData.donated_by === "admin" &&
                    donationData.status === "NGO to Admin Return Completed" && (
                      <li>Donation returned successfully!</li>
                    )}
                  {donationData.donated_by === "seller" &&
                    (donationData.status ===
                      "Admin to Seller Return Completed" ||
                      donationData.status ===
                        "NGO to Seller Return Completed") && (
                      <li>Donation returned successfully!</li>
                    )}

                  {/* Create shipment */}
                  {!anyActiveShipment &&
                    donationData.status !== "Pending" &&
                    donationData.status !== "Cancelled" &&
                    !(
                      donationData.donated_by === "admin" &&
                      donationData.status === "NGO to Admin Return Completed"
                    ) &&
                    donationData.status !==
                      "Admin to Seller Return Completed" &&
                    donationData.status !==
                      "NGO to Seller Return Completed" && (
                      <li>
                        Create shipment
                        <button
                          className="create-btn"
                          onClick={() => {
                            // product donated by: seller
                            // status: Confirmed || Seller Pickup Processing
                            // create shipment for "Seller To Admin"

                            // product donated by: admin
                            // status: Confirmed || NGO Delivery Processing
                            // create shipment for "Admin To NGO"
                            if (donationData.status === "Confirmed") {
                              if (donationData.donated_by === "seller") {
                                setShipmentToList([
                                  "Seller to Admin",
                                  "Seller to NGO",
                                ]);
                              } else if (donationData.donated_by === "admin") {
                                setShipmentToList(["Admin to NGO"]);
                              }

                              // status: Admin to Seller Pickup Completed
                              // create shipment for "Admin to NGO"
                            } else if (
                              donationData.status ===
                              "Admin to Seller Pickup Completed"
                            ) {
                              setShipmentToList([
                                "Admin to NGO",
                                "Admin to Seller",
                              ]);

                              // status: Delivered
                              // create shipment for "NGO to Admin"
                            } else if (donationData.status === "Delivered") {
                              if (donationData.donated_by === "seller") {
                                setShipmentToList([
                                  "NGO to Admin",
                                  "NGO to Seller",
                                ]);
                              } else if (donationData.donated_by === "admin") {
                                setShipmentToList(["NGO to Admin"]);
                              }

                              // status: NGO to Admin Return Completed
                              // donated_by: seller
                            } else if (
                              donationData.status ===
                                "NGO to Admin Return Completed" &&
                              donationData.donated_by === "seller"
                            ) {
                              setShipmentToList(["Admin to Seller"]);

                              // Failed in Delivery
                            } else if (
                              donationData.status ===
                              "Admin to NGO Delivery Failed"
                            ) {
                              setShipmentToList(["Admin to NGO"]);
                            } else if (
                              donationData.status ===
                              "Seller to NGO Delivery Failed"
                            ) {
                              setShipmentToList([
                                "Seller to Admin",
                                "Seller to NGO",
                              ]);
                            }

                            openShipmentModal();
                          }}
                        >
                          Create
                        </button>
                      </li>
                    )}

                  {/* shipment status */}
                  {anyActiveShipment && <li>Shipment is in-process</li>}

                  {/* mark as Out For Delivery */}
                  {(donationData.status ===
                    "Admin to NGO Delivery Processing" ||
                    donationData.status ===
                      "Seller to NGO Delivery Processing") &&
                    anyActiveShipment && (
                      <li>
                        Out for delivery?
                        {donationActionLoading ? (
                          <button className="waiting-btn">
                            Please wait...
                          </button>
                        ) : (
                          <button
                            className="info-btn"
                            onClick={() => {
                              if (
                                donationData.status ===
                                "Admin to NGO Delivery Processing"
                              ) {
                                updateDonationStatus(
                                  "Admin to NGO Out For Delivery"
                                );
                              } else if (
                                donationData.status ===
                                "Seller to NGO Delivery Processing"
                              ) {
                                updateDonationStatus(
                                  "Seller to NGO Out For Delivery"
                                );
                              }
                            }}
                          >
                            Yes
                          </button>
                        )}
                      </li>
                    )}

                  {/* cancel order */}
                  {donationData.status === "Confirmed" && (
                    <li className="cancel-order">
                      Cancel donation?
                      <button
                        className="cancel-btn"
                        onClick={openDonationDeclineModal}
                      >
                        Cancel
                      </button>
                    </li>
                  )}

                  {/* order cancelled info */}
                  {donationData.status === "Cancelled" && (
                    <li
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      Donation has been cancelled
                      {donationData.pause_reason && (
                        <span className="od-cancelled-reason">
                          <b style={{ fontSize: "14px", fontWeight: "500" }}>
                            Reason:
                          </b>{" "}
                          {donationData.pause_reason}
                        </span>
                      )}
                    </li>
                  )}
                </ul>
              </div>

              <div className="bottom-div order-action">
                <h4 className="box-heading">Donation Activity</h4>
                <ul>
                  {donationData.status_tracker && (
                    <>
                      {donationStatusActivity.map((data, index) => (
                        <div key={index} className="order-status-track">
                          <div className="circle">
                            <div className="inner-circle"></div>
                          </div>
                          <li className="order-status-tracker">
                            <p>
                              {data.status}
                              <br />
                              <span>{getDateTime(data.timestamp)}</span>
                            </p>
                          </li>
                        </div>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Refund modal */}
      <div id="refundModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={closeRefundModal}
          >
            &times;
          </button>
          <div className="order-modal-header-refund">
            <h1 className="Initiate-refund-heading">Initiate refund</h1>

            <p className="modal-heading-refund">
              Are you sure,
              <br />
              want to refund the payment?
            </p>
          </div>
          <div className="modal-body-refund">
            <label className="Add_category_label">
              <textarea
                className="form-control Add_category_input decline-input"
                value={refundNote}
                placeholder="Refund note"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setRefundNote(inputValue);
                }}
              ></textarea>
            </label>
            {refundNoteError && (
              <label htmlFor="error" className="error">
                {refundNoteError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    initiateRefund();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={closeRefundModal}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Failed in Sanitizing and Packaging Modal */}
      <div id="sanitizationFailModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={() => {
              closeSanitizationFailModal();
            }}
          >
            &times;
          </button>
          <div className="order-modal-header">
            <p className="modal-heading">Product failed in sanitization?</p>
          </div>
          <div className="modal-body">
            <label>Order cancel reason</label>
            <input
              className="form-control"
              type="text"
              value={donationCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setDonationCancelNote(inputValue);
                setDonationCancelNoteError("");
              }}
            />
            {donationCancelNoteError && (
              <label htmlFor="error" className="error">
                {donationCancelNoteError}
              </label>
            )}
            <label>Product failed note</label>
            <textarea
              className="form-control decline-input"
              value={productCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setProductCancelNote(inputValue);
                setProductCancelNoteError("");
              }}
            ></textarea>
            {productCancelNoteError && (
              <label htmlFor="error" className="error">
                {productCancelNoteError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    updateDonationStatus("Failed in Sanitizing and Packaging");
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={closeSanitizationFailModal}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Order Declined/Cancelled modal */}
      <div id="orderDeniedModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={() => {
              closeOrderDeclineModal();
            }}
          >
            &times;
          </button>
          <div className="order-modal-header">
            <p className="modal-heading">Cancel the donation?</p>
          </div>
          <div className="modal-body">
            <label>Donation cancel reason</label>
            <input
              className="form-control"
              type="text"
              value={donationCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setDonationCancelNote(inputValue);
                setDonationCancelNoteError("");
              }}
            />
            {donationCancelNoteError && (
              <label htmlFor="error" className="error">
                {donationCancelNoteError}
              </label>
            )}
            <label className="product-back-in-stock">
              <input
                type="checkbox"
                name="product_back_in_stock"
                checked={productBackInStock}
                onChange={(e) => {
                  setProductBackInStock(!productBackInStock);
                }}
              />{" "}
              Roll back product stock
            </label>
            {productBackInStockError && (
              <label htmlFor="error" className="error">
                {productBackInStockError}
              </label>
            )}
            {!productBackInStock && (
              <>
                <label>Product cancel reason</label>
                <textarea
                  className="form-control decline-input"
                  value={productCancelNote}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setProductCancelNote(inputValue);
                    setProductCancelNoteError("");
                  }}
                ></textarea>
                {productCancelNoteError && (
                  <label htmlFor="error" className="error">
                    {productCancelNoteError}
                  </label>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    donationStatusToDeclined();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeOrderDeclineModal();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Create shipment modal */}
      <div id="shipmentModal" className="order modal fade">
        <div className="modal-content">
          <span
            className="order modal-close-icon"
            onClick={() => {
              closeShipmentModal();
            }}
          >
            &times;
          </span>
          <div className="order-modal-header">
            <p className="modal-heading">Create shipment</p>
          </div>
          <div className="modal-body">
            <label>Shipment for</label>
            <select
              className="form-control shipment-for-input"
              value={shipmentFor}
              onChange={(e) => {
                e.preventDefault();
                handleShipmentForChange(e);
                const val = e.target.value;
                if (
                  val === "Seller to Admin" ||
                  val === "Admin to Seller" ||
                  val === "Admin to NGO" ||
                  val === "NGO to Admin"
                ) {
                  setEnableWarehouse(true);
                } else {
                  setEnableWarehouse(false);
                }
              }}
            >
              <option value="">Select</option>
              {shipmentToList.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
            {shipmentForError && (
              <label htmlFor="error" className="error">
                {shipmentForError}
              </label>
            )}
            {enableWarehouse && (
              <div>
                <label>Warehouse</label>
                <select
                  className="form-control warehouse-select"
                  name="warehouse_id"
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.target.value;
                    setWarehouseId(val);
                  }}
                  value={warehouseId}
                >
                  <option value="">Select</option>
                  {warehouseOptionsValue.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.warehouse_name}
                      </option>
                    );
                  })}
                </select>
                {warehouseError && (
                  <label htmlFor="error" className="error">
                    {warehouseError}
                  </label>
                )}
              </div>
            )}
            <label>Shipping method</label>
            <select
              className="form-control shipping-method-input"
              value={shippingMethod}
              onChange={handleShippingMethodChange}
            >
              <option value="">Select</option>
              {shippingMethods.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
            {shippingMethodError && (
              <label htmlFor="error" className="error">
                {shippingMethodError}
              </label>
            )}
            {enableScheduleDate && (
              <>
                <label>Estimated pickup date</label>
                <input
                  type="date"
                  className="form-control"
                  value={pickupScheduleDate}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setPickupScheduleDate(inputValue);
                    setPickupScheduleError("");
                  }}
                />
                {pickupScheduleError && (
                  <label htmlFor="error" className="error">
                    {pickupScheduleError}
                  </label>
                )}
              </>
            )}
            {enableShippingCost && (
              <>
                <label>Shipping cost</label>
                <input
                  type="number"
                  className="form-control"
                  value={shippingCost}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setShippingCost(inputValue);
                    setShippingCostError("");
                  }}
                />
                {shippingCostError && (
                  <label htmlFor="error" className="error">
                    {shippingCostError}
                  </label>
                )}
              </>
            )}
            {shipmentCreateError && (
              <label htmlFor="error" className="error">
                {shipmentCreateError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    createShipment();
                  }}
                >
                  Create
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeShipmentModal();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* update shipment status modal */}
      <div id="shipmentUpdateModal" className="order modal fade">
        <div className="modal-content">
          <span
            className="order modal-close-icon"
            onClick={() => {
              closeShipmentUpdateModal();
              setShipmentCancelError("");
              setShipmentCancelNote("");
              setShipmentUpdateError("");
              setShipmentStatus("");
            }}
          >
            &times;
          </span>
          <div className="order-modal-header">
            <p className="modal-heading">Shipment update</p>
          </div>
          <div className="modal-body">
            <label>Status</label>
            <select
              className="form-control"
              value={shipmentStatus}
              onChange={handleShipmentStatus}
            >
              <option value="">Select</option>
              {shipmentStatusList.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
            {shipmentStatusError && (
              <label htmlFor="error" className="error">
                {shipmentStatusError}
              </label>
            )}
            {enableShipmentCancel && (
              <>
                <label>Cancel note</label>
                <textarea
                  className="form-control decline-input"
                  value={shipmentCancelNote}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setShipmentCancelNote(inputValue);
                  }}
                ></textarea>
                {shipmentCancelError && (
                  <label htmlFor="error" className="error">
                    {shipmentCancelError}
                  </label>
                )}
              </>
            )}
            {shipmentUpdateError && (
              <label htmlFor="error" className="error">
                {shipmentUpdateError}
              </label>
            )}
            <label htmlFor="" className="shipment-delivered-status">
              Note: Status as <span>Delivered</span> will not affect on actual
              shipment
            </label>
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    setLoadingModalBtn(true);
                    updateShipmentStatus();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeShipmentUpdateModal();
                    setShipmentCancelError("");
                    setShipmentCancelNote("");
                    setShipmentUpdateError("");
                    setShipmentStatus("");
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationDetails;
