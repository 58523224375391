import { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import "./Users.css";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import LoaderButton from "../../../../Components/LoaderButton";
import InputContext from "../../../../Components/InputContext";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../../Components/Cookie";
import UserCreate from "./userCreate";
import Toast from "../../../../Components/Toast";
import Paginate from "../../../../Components/Pagination/Paginate";
import { formatDateTime } from "../../../../utils/utils";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Users = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterStatus = queryParams.get("pageNo");

  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [adminUsers, setAdminUsers] = useState([]);
  const [isSearchApply, setIsSearchApply] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const authorizationBearerToken = getDataFromCookies("7b9KpQ3rW6");
  const [addAdminUserModel, showAddAdminUserModel] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const AdminUserPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [totalCount, setTotalCount] = useState("");
  // Get List of All Admin Users .

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getAdminUserList(data);
  };

  const getAdminUserList = async (url) => {
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    await fetch(`${url}&per_page=${AdminUserPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorizationBearerToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else if (data.status === 401) {
          removeDataFromCookie("7b9KpQ3rW6");
          removeDataFromCookie("loggedIn");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
        } else if (data.status === 404) {
          setAdminUsers([]);
          setPagination("");
        } else if (data.status === 200) {
          const newdataArray = [...new Set(data.data.data)];
          setAdminUsers(newdataArray);
          setCurrentPage(data.data.current_page);
          setLastPage(data.data.last_page);
          setTotalCount(data.data.total);
          setPagination(data.data);
        }
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // UseEffects Calls
  useEffect(() => {
    if (filterStatus) {
      currentPage = filterStatus;
    }
    getAdminUserList(
      `${API_HOST}/${APIEndpoint.getAdminList}?page_no=${currentPage}`
    );
  }, [filterStatus]);

  // handle search api calls
  useEffect(() => {
    if (isSearchApply) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        getAdminUserList(
          `${API_HOST}/${APIEndpoint.getAdminList}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);
  // Handel Add Products Page
  const handelAddAdminUser = () => {
    showAddAdminUserModel(true);
    setLoadingButton(false);
  };
  const handleCloseAddModelPopup = () => {
    const isAdded = getDataFromCookies("isAddedUsers");
    if (isAdded) {
      lastPage = totalCount % AdminUserPerPage === 0 ? lastPage + 1 : lastPage;
      showAddAdminUserModel(false);
      getAdminUserList(
        `${API_HOST}/${APIEndpoint.getAdminList}?page_no=${lastPage}`
      );
      removeDataFromCookie("isAddedUsers");
    } else {
      showAddAdminUserModel(false);
    }
  };
  // Handel Edit Page Calls

  const handelEditPageNavigation = (editId) => {
    navigate(`${Navigation.adminListEdit}/${editId}`);
  };

  // Delete Admin Users API Calls
  const handleCancelDelete = () => {
    setDeleteItemId(null);
  };

  const handleDeleteClickpopup = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteAdminUser = (deleteId) => {
    if (deleteId === 1) {
      alert("You are Not Allowed To Delete Master Admin!!!");
    } else {
      let your_access_token = getDataFromCookies("7b9KpQ3rW6");
      if (!your_access_token) {
        window.location.href = `${Navigation.login}`;
        alert("Login expired!");
        return;
      }

      fetch(`${API_HOST}/${APIEndpoint.deleteAdmin}/${deleteId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${your_access_token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 200) {
            setDeleteItemId(null);
            setShowDeleteSuccessModal(true);
            if (currentPage === lastPage) {
              currentPage =
                totalCount % AdminUserPerPage === 1
                  ? currentPage - 1
                  : currentPage;
              getAdminUserList(
                `${API_HOST}/${APIEndpoint.getAdminList}?page_no=${currentPage}`
              );
            } else {
              getAdminUserList(
                `${API_HOST}/${APIEndpoint.getAdminList}?page_no=${currentPage}`
              );
            }
          } else if (response.status === 401) {
            removeDataFromCookie("7b9KpQ3rW6");
            removeDataFromCookie("loggedIn");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            navigate(Navigation.login);
          } else if (response.status === 401 && response.message !== "") {
            showToast("error", response.message);
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(
            `Failed to delete product with id ${deleteId}. Error: ${error}`
          );
        });
    }
  };

  // ==================================== React Table Logic==========================//

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * AdminUserPerPage,
      },
      {
        Header: "Name",
        accessor: (row) => `${row.f_name} ${row.l_name}`,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Role",
        accessor: "role_name",
      },
      {
        Header: "Created At",
        Footer: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Updated At",
        Footer: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Status",
        accessor: (row) => (row.status === 1 ? "Active" : "Blocked"),
        Cell: ({ value }) => (
          <div
            className={value === "Active" ? "active-status" : "blocked-status"}
          >
            {value}
          </div>
        ),
      },
      // {
      //   Header: "Block/UnBlock",
      //   accessor: "",
      // },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = `${row.original.f_name} ${row.original.l_name}`;
          const isDeleting = deleteItemId === id;
          const isStatusDisabled = row.original.id === 1;
          return isDeleting ? (
            <div>
              <>
                {/* Product delete modal */}
                {showDeleteModal && (
                  <div
                    id="deleteModal"
                    className="modal fade"
                    style={{ display: showDeleteModal ? "block" : "none" }}
                  >
                    <div className="modal-content">
                      <span
                        className="modal-close"
                        onClick={handleCancelDelete}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      <h3 className="modal-header">Delete Confirmation</h3>
                      <p className="modal-line">
                        Are you sure you want to delete{" "}
                        <span className="deleteitemsNameappPages">{name}</span>{" "}
                        user?
                      </p>
                      <div className="modal-buttons">
                        <button
                          className="btn primary-btn"
                          onClick={() => handleDeleteAdminUser(row.original.id)}
                        >
                          Yes, Delete It!
                        </button>
                        <button
                          className="btn secondary-btn"
                          onClick={handleCancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <span>
                  <button
                    onClick={() => handelEditPageNavigation(row.original.id)}
                    className={` ${
                      isStatusDisabled
                        ? "edit-button-disabled"
                        : "action-icon edit-button"
                    }`}
                    disabled={isStatusDisabled}
                    title={isStatusDisabled ? "Disabled" : "Edit"}
                    style={{
                      opacity: isStatusDisabled ? 0.5 : 1,
                      cursor: isStatusDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>

                  <button
                    onClick={() => handleDeleteClickpopup(id)}
                    className={` ${
                      isStatusDisabled
                        ? "trash-button-disabled"
                        : "action-icon trash-button"
                    }`}
                    title={isStatusDisabled ? "Disabled" : "Delete"}
                    style={{
                      opacity: isStatusDisabled ? 0.5 : 1,
                      cursor: isStatusDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={isStatusDisabled}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </span>
              </>
            </div>
          ) : (
            <span>
              <button
                onClick={() => handelEditPageNavigation(row.original.id)}
                className={` ${
                  isStatusDisabled
                    ? "edit-button-disabled"
                    : "action-icon edit-button"
                }`}
                disabled={isStatusDisabled}
                title={isStatusDisabled ? "Disabled" : "Edit"}
                style={{
                  opacity: isStatusDisabled ? 0.5 : 1,
                  cursor: isStatusDisabled ? "not-allowed" : "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>

              <button
                onClick={() => handleDeleteClickpopup(id)}
                className={` ${
                  isStatusDisabled
                    ? "trash-button-disabled"
                    : "action-icon trash-button"
                }`}
                title={isStatusDisabled ? "Disabled" : "Delete"}
                style={{
                  opacity: isStatusDisabled ? 0.5 : 1,
                  cursor: isStatusDisabled ? "not-allowed" : "pointer",
                }}
                disabled={isStatusDisabled}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </span>
          );
        },
      },
    ],
    [deleteItemId, showDeleteSuccessModal, currentPage] // Add deleteItemId as a dependency
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: adminUsers, // Rename 'categoryData' to 'data'
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Users</li>
        </div>
      </div>

      <div className="page-container">
        <div className="AdminUserPageMainContainer">
          <div className="AdminUserSearchandButtonbox">
            <div>
              <button
                onClick={() => {
                  setLoadingButton(true);
                  handelAddAdminUser();
                }}
                className="adminUsersPageButton"
              >
                {" "}
                {loadingButton ? <LoaderButton /> : "Add User"}
              </button>
            </div>
            <div className="search-bar">
              <div className="fontAwsomeDiv">
                {searchLoading ? (
                  <LoaderButton />
                ) : (
                  <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
                )}
              </div>
              <input
                id="seller-searchForProducts"
                placeholder="Search..."
                className="search-input-order"
                name="search-query"
                value={searchQuery}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.target.value;
                  setSearchQuery(val);
                  setIsSearchApply(true);
                }}
              />
            </div>
          </div>
          <div className="Admin-Users-Table">
            <table id="categoryTable" {...getTableProps()}>
              <thead className="Category-grid-container-item2-tableHead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className="Category-grid-container-item2-tableHead">
                        {" "}
                        <div className="header-cell">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">Loading...</div>
                      </td>
                    </tr>
                  ) : page.length > 0 ? (
                    // Check if there is data in the current page
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="TableBodyData" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">No users available.</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              }
            </table>
            {/* Pagination */}
            <div className="pagination-container">
              {pagination && (
                <Paginate
                  pagination={pagination}
                  pageChange={handleURLChange}
                  pageName={"Users"}
                />
              )}
            </div>
          </div>
        </div>
        {addAdminUserModel && (
          <UserCreate handleCloseAddModelPopup={handleCloseAddModelPopup} />
        )}
      </div>
    </div>
  );
};

export default Users;
