import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputContext from "../../../Components/InputContext";
import {
  faPenToSquare,
  faTrashCan,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../Assets/style.css";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import placeHolder from "../../../Assets/image-placeholder.png";
import "../product.css";
import LoaderButton from "../../../Components/LoaderButton";
import { formatDateTime } from "../../../utils/utils";
let API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellersProductList = () => {
  const { showToast } = Toast();
  const location = useLocation();
  const [deleting, setDeleting] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const filterStatus = queryParams.get("status");
  const contextObj = useContext(InputContext);
  const navigate = new useNavigate();
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  let [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [deletingItemName, setDeletingItemName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productStatus, setProductStatus] = useState("All");
  let [productCount, setProductCount] = useState({});
  const [pagination, setPagination] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  const sellerProductPerPage = 10;
  const openDeleteModal = (id, name) => {
    setDeletingItemId(id);
    setDeletingItemName(name);
    setShowDeleteModal(true);
    setDeleting(false);
  };

  const closeDeleteModal = () => {
    setDeletingItemId("");
    setDeletingItemName("");
    setShowDeleteModal(false);
    setDeleting(false);
  };

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    if (filterStatus !== "" && filterStatus !== "All") {
      data = data + `&status=${filterStatus}`;
    }
    if (searchQuery !== "") {
      data = data + "&search=" + searchQuery;
    }
    data = data + `&per_page=${sellerProductPerPage}`;
    getAllSellerProducts(data);
  };

  const getAllSellerProducts = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();
    setSearchLoading(false);
    if (res.status === 200) {
      setData(res.data.products.data);
      setProductCount(res.data.count);
      setCurrentPage(res.data.products.current_page);
      setPagination(res.data.products);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setData([]);
      setPagination(res.data.products);
      setPagination("");
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (contextObj.inputValue.sellerProductStatus !== filterStatus) {
      contextObj.setInput("sellerProductStatus", filterStatus);
    }
    currentPage = 1;

    let url = `${API_HOST}/${APIEndpoint.getSellerProducts}?page=${currentPage}&per_page=${sellerProductPerPage}`;
    if (filterStatus !== "" && filterStatus !== "All") {
      url = url + `&status=${filterStatus}`;
    }
    setSearchQuery("");
    getAllSellerProducts(url);
    setProductStatus(filterStatus);
  }, [
    contextObj.inputValue.activeSellersProductsFilter,
    contextObj.inputValue.sellerProductStatus,
  ]);

  const handleConfirmDelete = async () => {
    try {
      setDeleting(true);
      let token = getDataFromCookies("7b9KpQ3rW6");

      if (!token) {
        setDeleting(false);
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }

      const response = await fetch(
        `${API_HOST}/${APIEndpoint.DeleteSellerProducts}/${deletingItemId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const res = await response.json();

      if (res.status === 200) {
        showToast("success", "Product deleted successfully");
        closeDeleteModal();
        setData(data.filter((product) => product.id !== deletingItemId));
      } else if (
        response.status === 401 &&
        response.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (response.status === 401 && response.message !== "") {
        showToast("error", response.message);
      } else if (res.status === 400) {
        showToast("error", response.message);
      } else {
        showToast("info", "Something went wrong");
      }
    } catch (error) {
      showToast("error", "An error occurred while deleting the product");
    } finally {
      setDeleting(false);
    }
  };

  const openProductDetails = (slug, action) => {
    if (action === "view") {
      navigate(`${"/seller/products-variant"}/${slug}`);
    } else if (action === "edit") {
      navigate(`${"/seller/products/newedit"}/${slug}`);
    }
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      let url = `${API_HOST}/${APIEndpoint.getSellerProducts}?&per_page=${sellerProductPerPage}`;
      if (filterStatus !== "" && filterStatus !== "All") {
        url = url + `&status=${filterStatus}`;
      }
      if (searchQuery !== "") {
        // currentPage = 1;
        url = url + "&search=" + searchQuery;

        getAllSellerProducts(url);
      } else {
        getAllSellerProducts(url);
      }
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * sellerProductPerPage,
      },
      {
        Header: "Image",
        accessor: "default_stock.thumb_image", // get default product price and thumb img
        Cell: ({ value }) => (
          <img
            src={imgHost + "/" + value}
            onError={(e) => {
              e.target.src = placeHolder;
            }}
            alt="Image"
          />
        ),
      },
      {
        Header: "Product Name",
        Footer: "Product Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className="text-color-adminPanel">
            {" "}
            {value.length > 60 ? `${value.substring(0, 55)}...` : value}
          </div>
        ),
      },
      {
        Header: "Product Price",
        Footer: "Product Price",
        accessor: "default_stock.product_price", // get default product price and thumb img
        Cell: ({ value }) => (
          <div className="text-color-adminPanel">
            ₹{formatNumberWithCommas(Math.round(value))}
          </div>
        ),
      },
      {
        Header: "Created At",
        Footer: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => <>{<div>{formatDateTime(value)}</div>}</>,
      },
      {
        Header: "Updated At",
        Footer: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => <>{<div>{formatDateTime(value)}</div>}</>,
      },
      {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          if (value === "Unverified") {
            backgroundColor = "#F8D294";
            color = "#000000";
          } else if (value === "Verified and Published") {
            backgroundColor = "#4CAF50";
            color = "white";
          } else if (value === "Rejected") {
            backgroundColor = "#FF0000";
            color = "white";
          } else if (value === "Hold") {
            backgroundColor = "#FFA500";
            color = "white";
          } else if (value === "Sold") {
            backgroundColor = "#2196F3";
            color = "white";
          } else if (value === "Donated") {
            backgroundColor = "#9C27B0";
            color = "white";
          } else if (value === "Disabled") {
            backgroundColor = "#0D47A1";
            color = "white";
          } else if (value === "Unavailable") {
            backgroundColor = "#606060";
            color = "white";
          } else if (value === "Blocked") {
            backgroundColor = "#ea5e3d";
            color = "white";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const slug = row.original.slug;
          const isDeletable = row.original.product_deletable;

          return (
            <>
              <button
                type="button"
                onClick={() => openProductDetails(slug, "edit")}
                className="action-icon edit-button"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>

              {isDeletable && (
                <button
                  type="button"
                  onClick={() => openDeleteModal(id, name)}
                  className="action-icon trash-button"
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              )}
            </>
          );
        },
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">{productStatus} Products</li>
        </div>
      </div>

      <div className="page-container">
        {/* Product Counter */}

        <div
          className="data-counter"
          style={{
            display: productStatus !== "All" ? "none" : "",
          }}
        >
          <div className={`counter-item`}>
            <p>All Products</p>
            <p>{productCount.all_seller_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Unverified Products</p>
            <p>{productCount.unverified_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Verified and Published Products</p>
            <p>{productCount.verified_and_published_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Rejected Products</p>
            <p>{productCount.rejected_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Donated Products</p>
            <p>{productCount.donated_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Unavailable Products</p>
            <p>{productCount.unavailable_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Hold Products</p>
            <p>{productCount.hold_products}</p>
          </div>
          <div className={`counter-item`}>
            <p>Sold Products</p>
            <p>{productCount.sold_products}</p>
          </div>
        </div>

        <div className="search-bar-container">
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              // id="searchForProducts"
              placeholder="Search..."
              className="search-input"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
              }}
            />
          </div>
        </div>
        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th style={{ width: column.width }}>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ overflow: "auto" }}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={
                contextObj.inputValue.activeSellersProductsFilter + " Products"
              }
            />
          )}
        </div>

        {/* Delete Modal */}
        {showDeleteModal && (
          <div
            id="deleteModal"
            className="modal fade"
            style={{ display: showDeleteModal ? "block" : "none" }}
          >
            <div className="modal-content">
              <span className="modal-close" onClick={closeDeleteModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>

              <h3 className="modal-header">Delete Confirmation</h3>
              <p className="modal-line">
                Are you sure you want to delete{" "}
                <span className="deleteitemsNameappPages">
                  {deletingItemName}
                </span>{" "}
                item?
              </p>
              <div className="modal-buttons">
                <button
                  type="button"
                  className="btn primary-btn"
                  onClick={() => handleConfirmDelete()}
                  style={{
                    minWidth: "70px",
                  }}
                >
                  {deleting ? <LoaderButton /> : "Delete"}
                </button>
                <button
                  type="button"
                  className="btn secondary-btn"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SellersProductList;
