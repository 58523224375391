import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";

// import components
import "./userCreate.css";
import imgHost from "../../../../Components/ReUsed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import InputContext from "../../../../Components/InputContext";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import loadingImgPath from "../../../../Assets/loading.gif";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../../Components/Cookie";
import Toast from "../../../../Components/Toast";
import StringConstant from "../../../../config/ProjectConstants.json";
import LoaderButton from "../../../../Components/LoaderButton";
import GetPermissionList from "./GetPermissionList";
import { TbArrowDown, TbArrowUp } from "react-icons/tb";
import { formatDateTime } from "../../../../utils/utils";

//constant
const API_URL = process.env.REACT_APP_HOST_API_URL;
const API_KEY = process.env.REACT_APP_POSTOFFICE_API_KEY;

const EditUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminId } = useParams();
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const contextObj = useContext(InputContext);
  const [permissionIdValue, setPermissionIdValue] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  
  // error usestates
  const [allListPermission, setAllListPermissson] = useState([]);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showPermissionList, setShowPermissionList] = useState(true);
  const [profileImageError, setProfileImageError] = useState("");
  const [upiIdError, setUpiIdError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderNameError, setAccountHolderNameError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [isUnreachablePincode, setIsUnreachablePincode] = useState(false);
  const [roleNameError, setRoleNameError] = useState("");
  const { showToast } = Toast();
  
  //image upload
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });

  const [hasMoreRoles, setHasMoreRoles] = useState(null);
  const [loadingMoreRoles, setLoadingMoreRoles] = useState(false);
  const adminRolesURL = `${API_URL}/${APIEndpoint.adminRoles}`;
  const fetchMoreAdminRoles = async (url) => {
    await getAdminRole(url);
  };
  const handleSelect = (selectedId) => {
    handleChange({ target: { name: "roleId", value: selectedId } });
    setIsDataChanged(true);
  };
  const token = getDataFromCookies("7b9KpQ3rW6");
  
  // Get Admin Roles API calls
  const getAdminRole = async (url) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const res = await response.json();

      if (res.status === 401) {
        sessionStorage.removeItem("7b9KpQ3rW6");
        sessionStorage.removeItem("loggedIn");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 200) {
        let data = res.data;

        

        if (data.current_page === 1) {
          setOptionsValue(data.data);
        } else {
          setOptionsValue((prev) => [...prev, ...data.data]);
        }
        setHasMoreRoles(data.next_page_url);
      }
      setLoadingMoreRoles(false);
    } catch (err) {
      setLoadingMoreRoles(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (optionsValue.length === 0) {
      getAdminRole(adminRolesURL);
    }
  }, []);


  const GetPermissionList = () => {
    const id = permissionIdValue;
    const option = optionsValue.find((option) => option.id === id);
    if (option) {
      const permissions = option.permissions;
      return (
        <div>
          {permissions.map((permission) => (
            <li className="listOfPermission" key={permission}>
              {permission}
            </li>
          ))}
        </div>
      );
    }
    return null;
  };

  const [profileData, setProfileData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    password: "",
    role_id: 0,
    image: null,
    upi_id: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
    street_address: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
    created_at: null,
    updated_at: null,
  });

  const toCamelCase = (text) => {
    const words = text.split(" ");
    const result = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return result.join(" ");
  };
  useEffect(() => {
    const url = `${API_URL}/${APIEndpoint.getAdminUser}/${adminId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const fetchProfileData = async () => {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        let data = res.data;
        // setProfileData(data);
        setProfileData((prevData) => ({
          ...prevData,
          account_holder_name: data.account_holder_name,
          account_number: data.account_number,
          bank_name: data.bank_name,
          branch_name: data.branch_name,
          city: data.city,
          email: data.email,
          f_name: data.f_name,
          ifsc_code: data.ifsc_code,
          image: data.image,
          l_name: data.l_name,
          phone: data.phone,
          pincode: data.pincode,
          role_id: data.role_id,
          role_name: data.role_name,
          state: data.state,
          status: data.status,
          street_address: data.street_address,
          created_at: data.created_at,
          updated_at: data.updated_at,
        }));
        setContainer1({
          image: `${imgHost + "/" + data.image}`,
        });

        setAllListPermissson(data.permissions);
       
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        sessionStorage.removeItem("7b9KpQ3rW6");
        sessionStorage.removeItem("loggedIn");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else {
        console.error(res);
      }
      setDataLoading(false);
    };
    fetchProfileData();
  }, []);

  // get city and state
  const getCityAndState = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.data.gov.in/resource/7eca2fa3-d6f5-444e-b3d6-faa441e35294?api-key=${API_KEY}&format=json&filters[pincode]=${pincode}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (pincode.length >= 0 && pincode.length === 6) {
        if (data.records.length === 0) {
          setPincodeLoading(false);
          setPincodeError("Apologies! We're unable to reach this address.");
          setIsUnreachablePincode(true);
          setProfileData((prevData) => ({
            ...prevData,
            city: "",
            state: "",
          }));
        } else {
          const pinCodeDetails = data.records;
          // let country = pinCodeDetails[0].Country;
          const state = toCamelCase(data.records[0]._statename);
          const city = toCamelCase(data.records[0].districtname);
          setPincodeLoading(false);
          setPincodeError("");
          setCityError("");
          setStateError("");
          setIsUnreachablePincode(false);
          setProfileData((prevData) => ({
            ...prevData,
            city: city,
            state: state,
          }));
        }
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChange = (event) => {
    let { name, value } = event.target;
    setRoleNameError("");
    setLastNameError("");
    setPaymentError("");
    // button disabled and enabled by input events
    if (
      name === "f_name" ||
      name === "l_name" ||
      name === "phone" ||
      name === "email" ||
      name === "role_id" ||
      name === "upi_id" ||
      name === "bank_name" ||
      name === "branch_name" ||
      name === "ifsc_code" ||
      name === "account_holder_name" ||
      name === "account_number" ||
      name === "street_address" ||
      name === "pincode" ||
      name === "city" ||
      name === "state"
    ) {
      setIsDataChanged(true);
    }

    // update profiledata useState value while updating input fields
    if (name === "phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "role_id") {
      setShowPermissionList(false);
      setPermissionIdValue(value);
      setRoleNameError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "image") {
      const file = event.target.files[0];
      setProfileData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (name === "account_number") {
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 16); // Limit to a maximum of 16 digits

      setProfileData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "pincode") {
      setPincodeError("");
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 6); // Limit to a maximum of 6 digits
      if (limitedValue.length === 6) {
        setPincodeLoading(true);
        getCityAndState(limitedValue); //fetch the city and state using pincode
      }

      setProfileData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value === "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "city") {
      value = event.target.value.replace(
        /[#.'@!?><,~|\\[\]{:;"/+\-$%^&*()_=}\d]/g,
        ""
      );
      setCityError("");
      if (value.trim() !== "" || value === "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "state") {
      setStateError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "street_address") {
      setStreetAddressError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const url = `${API_URL}/${APIEndpoint.updateAdminUser}/${adminId}`;

  if (!token) {
    contextObj.setInput("prevPath", location.pathname);
    navigate(`${Navigation.login}`);
    return;
  }
  // update product pickup location
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const productPickUpAddressSubmit = async () => {
    setLoading(true);
    let errorHas = false;
    const fnameRegex = /^[a-zA-Z\s]+$/;
    const isFirstName = fnameRegex.test(profileData.f_name);

    if (profileData.f_name === "" || profileData.f_name === null) {
      setFirstNameError("First name is required!");
      errorHas = true;
    } else if (!isFirstName) {
      setFirstNameError("Please enter a valid first name!");
      errorHas = true;
    } else if (profileData.f_name.length > 50) {
      setFirstNameError("First name should not exceed 50 characters!");
      errorHas = true;
    } else {
      setFirstNameError("");
    }

    const lnameRegex = /^[a-zA-Z\s]+$/;
    const isLastName = lnameRegex.test(profileData.l_name);
    if (profileData.l_name === "" || profileData.l_name === null) {
      setLastNameError("Last name is required!");
      errorHas = true;
    } else if (!isLastName) {
      setLastNameError("Please enter a valid last name!");
      errorHas = true;
    } else {
      setLastNameError("");
    }
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(profileData.phone);
    if (profileData.phone === "" || profileData.phone === null) {
      setPhoneError("Phone is required!");
      errorHas = true;
    } else if (!isPhoneNumber) {
      setPhoneError("Please enter a valid phone number!");
      errorHas = true;
    } else {
      setPhoneError("");
    }
    const isvalidEmailAddress = validateEmail(profileData.email);
    if (profileData.email === "" || profileData.email === null) {
      setEmailError("Email name is required!");
      errorHas = true;
    } else if (!isvalidEmailAddress) {
      setEmailError("Please enter a valid Email!");
      errorHas = true;
    } else {
      setEmailError("");
    }
    if (
      !profileData.upi_id &&
      !profileData.bank_name &&
      !profileData.branch_name &&
      !profileData.ifsc_code &&
      !profileData.account_holder_name &&
      !profileData.account_number
    ) {
      setPaymentError("UPI ID or Bank information is required!");
    }

    let upi_flag = false;
    let bank_flag = false;

    if (
      profileData.bank_name ||
      profileData.branch_name ||
      profileData.ifsc_code ||
      profileData.account_holder_name ||
      profileData.account_number
    ) {
      bank_flag = true;
    }

    const bankNameRegex = /^[A-Za-z ]+$/;
    const isBankName = bankNameRegex.test(profileData.bank_name);

    if (bank_flag && !profileData.bank_name) {
      setBankNameError("Bank name is required!");
      errorHas = true;
    } else if (profileData.bank_name && !isBankName) {
      setBankNameError("Please enter a valid Bank name!");
      errorHas = true;
    } else if (profileData.bank_name && profileData.bank_name.length > 60) {
      // Maximum length check
      setBankNameError("Bank name should not exceed 60 characters!");
      errorHas = true;
    } else {
      setBankNameError("");
    }

    // Branch Name.
    const branhNameRegex = /^[a-zA-Z0-9-_\/ ]+$/;
    const isBranchName = branhNameRegex.test(profileData.branch_name);

    if (bank_flag && !profileData.branch_name) {
      setBranchNameError("Branch name is required!");
      errorHas = true;
    } else if (profileData.branch_name && !isBranchName) {
      setBranchNameError("Please enter a valid Branch name!");
      errorHas = true;
    } else if (profileData.branch_name && profileData.branch_name.length > 60) {
      // Maximum length check
      setBranchNameError("Branch name should not exceed 60 characters!");
      errorHas = true;
    } else {
      setBranchNameError("");
    }

    //IFSC Code
    const ifscRegex = /^[A-Z0-9 ]{11}$/;
    const isIFSC_code = ifscRegex.test(profileData.ifsc_code);

    if (bank_flag && !profileData.ifsc_code) {
      setIfscCodeError("IFSC code is required!");
      errorHas = true;
    } else if (profileData.ifsc_code && !isIFSC_code) {
      setIfscCodeError("Please enter a valid IFSC code!");
      errorHas = true;
    } else if (profileData.ifsc_code && profileData.ifsc_code.length > 11) {
      // Maximum length check
      setIfscCodeError("IFSC code should not exceed 11 characters!");
      errorHas = true;
    } else {
      setIfscCodeError("");
    }

    //Account Holder Name
    const isAccountHolderName = /^[a-zA-Z0-9-_.\s]+$/;
    const accountHolderName = isAccountHolderName.test(
      profileData.account_holder_name
    );

    if (bank_flag && !profileData.account_holder_name) {
      setAccountHolderNameError("Account Holder Name is required!");
      errorHas = true;
    } else if (profileData.account_holder_name && !accountHolderName) {
      setAccountHolderNameError("Please enter a valid Account Holder Name!");
      errorHas = true;
    } else if (
      profileData.account_holder_name &&
      (profileData.account_holder_name.length < 0 ||
        profileData.branch_name.length > 60)
    ) {
      // Maximum length check
      setAccountHolderNameError(
        "Account Holder Name should not exceed 60 characters!"
      );
      errorHas = true;
    } else {
      setAccountHolderNameError("");
    }

    // Account Number
    const accountNumberRegex = /^[0-9\s]+$/;
    const isAccountNumber = accountNumberRegex.test(profileData.account_number);

    if (bank_flag && !profileData.account_number) {
      setAccountNumber("Account number is required!");
      errorHas = true;
    } else if (profileData.account_number && !isAccountNumber) {
      setAccountNumber("Please enter a valid Account number!");
      errorHas = true;
    } else if (
      profileData.account_number &&
      (profileData.account_number.length < 6 ||
        profileData.account_number.length > 18)
    ) {
      setAccountNumber("Account number should contain 6 to 18 characters!");
      errorHas = true;
    } else {
      setAccountNumber("");
    }

    const addressRegex =
      /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9,.-/&:'\s\/\\\\\-_]+$/;
    const isSellerAddress = addressRegex.test(profileData.street_address);

    if (
      profileData.street_address === "" ||
      profileData.street_address === null
    ) {
      setStreetAddressError("Address is required!");
      errorHas = true;
    } else if (!isSellerAddress) {
      setStreetAddressError("Please enter a valid Address!");
      errorHas = true;
    } else if (
      profileData.street_address.length < 0 ||
      profileData.street_address.length > 100
    ) {
      // Maximum length check
      setStreetAddressError("Address should contain 60 characters!");
      errorHas = true;
    } else {
      setStreetAddressError("");
    }

    //Sellers Pincode.
    const pincodeRegex = /^[0-9]{6}$/;
    const isPincode = pincodeRegex.test(profileData.pincode);

    if (profileData.pincode === "" || profileData.pincode === null) {
      setPincodeError("Pincode is required!");
      errorHas = true;
    } else if (!isPincode) {
      setPincodeError("Please enter a valid Pincode!");
      errorHas = true;
    } else if (
      profileData.pincode.length > 6 ||
      profileData.pincode.length < 6
    ) {
      // Maximum length check
      setPincodeError("Pincode contain 6 characters!");
      errorHas = true;
    } else {
      setPincodeError("");
    }

    //Seller City.

    if (profileData.city === "" || profileData.city === null) {
      setCityError("City is required!");
      errorHas = true;
    } else if (profileData.city.length < 0 || profileData.city.length > 50) {
      // Maximum length check
      setCityError("City should contain 50 characters!");
      errorHas = true;
    } else {
      setCityError("");
    }

    // Seller State.

    if (profileData.state === "" || profileData.state === null) {
      setStateError("State is required!");
      errorHas = true;
    } else if (profileData.state.length < 0 || profileData.state.length > 50) {
      // Maximum length check
      setStateError("State should contain 50 characters!");
      errorHas = true;
    } else {
      setStateError("");
    }
    if (
      profileData.role_id === "" ||
      profileData.role_id === null ||
      profileData.role_id === "Please choose one option"
    ) {
      setRoleNameError(StringConstant.errorMessages.errorForBlankRoleName);
      errorHas = true;
    }
    // if any errors are there don't call the api
    if (errorHas === true) {
      setLoading(false);
      return;
    }
    const formData = new FormData();

    if (profileData.f_name !== null) {
      formData.append("f_name", profileData.f_name);
    }
    if (profileData.l_name !== null) {
      formData.append("l_name", profileData.l_name);
    }
    if (profileData.phone !== null) {
      formData.append("phone", profileData.phone);
    }
    if (profileData.role_id !== null) {
      formData.append("role_id", profileData.role_id);
    }
    if (profileData.image !== null && profileData.image !== "users/def.png") {
      formData.append("image", profileData.image);
    }
    formData.append("email", profileData.email);

    if (profileData.upi_id) {
      formData.append("upi_id", profileData.upi_id);
    }

    if (
      profileData.bank_name &&
      profileData.branch_name &&
      profileData.ifsc_code &&
      profileData.account_holder_name &&
      profileData.account_number
    ) {
      formData.append("bank_name", profileData.bank_name);
      formData.append("branch_name", profileData.branch_name);
      formData.append("ifsc_code", profileData.ifsc_code);
      formData.append("account_holder_name", profileData.account_holder_name);
      formData.append("account_number", profileData.account_number);
    }

    if (profileData.street_address !== null) {
      formData.append("street_address", profileData.street_address);
    }
    if (profileData.pincode !== null) {
      formData.append("pincode", profileData.pincode);
    }
    if (profileData.city !== null) {
      formData.append("city", profileData.city);
    }
    if (profileData.country !== null) {
      formData.append("country", profileData.country);
    }
    if (profileData.state !== null) {
      formData.append("state", profileData.state);
    }

    formData.append("_method", "PUT");
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const response = await res.json();

      if (res.status === 200) {
        setIsDataChanged(false);
        showToast("success", "Admin User Update Successful.");
        window.history.back();
      } else if (res.status === 400) {
        if (response.errors.email) {
          setEmailError(response.errors.email[0]);
        }
        if (response.errors.phone) {
          setPhoneError(response.errors.phone[0]);
        }
        if (response.errors.upi_id) {
          setUpiIdError(response.errors.upi_id[0]);
        }
      } else if (
        res.status === 401 &&
        response.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6");
        removeDataFromCookie("loggedIn");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
      } else if (res.status === 401 && response.message !== "") {
        showToast("error", response.message);
      } else if (res.status === 500) {
        console.error(response);
        window.location.href = "/error";
      } else {
        const error = await res.text();
        console.error(error);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
      showToast("error", "An unexpected error occurred.");
      // Optionally, handle other cases or rethrow the error
    }
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  // Allow only alphanumeric characters
  const onlyAlphanumeric = (e) => {
    const { key } = e;
    const regex = /^[a-zA-Z0-9]+$/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  const handelGoToUserList = () => {
    // navigate(Navigation.adminList);
    window.history.back();
  };

  return (
    <>
      {dataLoading && (
        <div className="modal">
          <div className="modal-pageLoading">
            <LoaderButton />
          </div>
        </div>
      )}
      <div className="page-data">
        <div className="EditUserheading">
          <h2 className="headingedituser">Edit User</h2>

          <div className="timeContainerHolder ">
            
            {profileData.created_at && (
              <div className="timeContainer">
                <p className="timeTitle">Created At :</p>
                <p className="">{formatDateTime(profileData.created_at)} </p>
              </div>
            )}

            {profileData.updated_at && (
              <div className="timeContainer">
                <p className="timeTitle">Updated At :</p>
                <p className="">{formatDateTime(profileData.updated_at)} </p>
              </div>
            )}

            <button
              onClick={() => handelGoToUserList()}
              className="createUserButtonListElement"
            >
              {"  "}
              <FontAwesomeIcon icon={faList} style={{ color: "#ffffff" }} />
              {"  "} User List
            </button>
          </div>
        </div>
        <div className="profile-body">
          {sellerStatus && (
            <div className="seller-verification-line">
              <p>
                Your account is not verified yet! If you're not complete your
                profile,{" "}
                <span
                  className="complete-your-profile"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  complete your profile
                </span>{" "}
                and wait for admin verification call.
              </p>
            </div>
          )}
          <div className="Edit_user_container">
            <div className="profile-row-container">
              <div className="userEdit-basic-info">
                <h3 className="edit-user-heading">Basic Information</h3>
                <div className="profile-input">
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="f_name"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.f_name}
                          placeholder="First Name"
                        />

                        <span className="Admin_user_register_span">
                          {" "}
                          First Name
                        </span>
                      </label>
                      {firstNameError && (
                        <span className="profile-error-red">
                          {firstNameError}
                        </span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="l_name"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.l_name}
                        />
                        <span className="Admin_user_register_span">
                          Last Name
                        </span>
                      </label>
                      {lastNameError && (
                        <span className="profile-error-red">
                          {lastNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="email"
                          name="email"
                          className="Admin_user_register_input"
                          value={profileData.email}
                          // readOnly={true}
                          onChange={handleChange}
                        />
                        <span className="Admin_user_register_span">Email</span>
                      </label>
                      {/* <span className="profile-email-edit">Edit</span> */}

                      {emailError && (
                        <span className="profile-error-red">{emailError}</span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="phone"
                          maxLength={10}
                          onChange={handleChange}
                          className="Admin_user_register_input"
                          value={profileData.phone}
                          placeholder="Phone"
                        />
                        <span className="Admin_user_register_span">Phone</span>
                      </label>

                      {phoneError && (
                        <span className="profile-error-red">{phoneError}</span>
                      )}
                    </div>
                  </div>
                  {/* <div>
                    <div className="input-row">
                      <button className="Edit_user_changePassword_button">
                        Change Password
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="userEdit-bank-info">
                <h3 className="edit-user-heading">Payment Information</h3>
                <p className="profile-sub-heading" style={{ color: "#bf7d00" }}>
                  UPI ID or Bank Details is require for receive successful order
                  amount.
                </p>

                <div className="bank-input">
                  <div className="input-row">
                    <label className="Admin_user_register_label">
                      <input
                        type="text"
                        name="upi_id"
                        className="Admin_user_register_input"
                        onChange={handleChange}
                        value={profileData.upi_id}
                        minLength={0}
                        maxLength={60}
                        placeholder="UPI ID"
                      />
                      <span className="Admin_user_register_span">UPI ID</span>
                    </label>
                    {upiIdError && (
                      <span className="profile-error-red">{upiIdError}</span>
                    )}
                  </div>
                  <p className="or-payment-info">OR</p>
                  <div className="input-row">
                    <label className="Admin_user_register_label">
                      <input
                        type="text"
                        name="bank_name"
                        className="Admin_user_register_input"
                        onChange={handleChange}
                        value={profileData.bank_name}
                        minLength={0}
                        maxLength={60}
                        placeholder=" Bank Name"
                      />
                      <span className="Admin_user_register_span">
                        {" "}
                        Bank Name
                      </span>
                    </label>
                    {bankNameError && (
                      <span className="profile-error-red">{bankNameError}</span>
                    )}
                  </div>
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="ifsc_code"
                          onChange={handleChange}
                          value={profileData.ifsc_code}
                          onKeyPress={onlyAlphanumeric}
                          minLength={11}
                          maxLength={11}
                          placeholder="IFSC Code"
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">
                          IFSC Code
                        </span>
                      </label>
                      {ifscCodeError && (
                        <span className="profile-error-red">
                          {ifscCodeError}
                        </span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="branch_name"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.branch_name}
                          minLength={0}
                          maxLength={60}
                          placeholder="Branch Name"
                        />
                        <span className="Admin_user_register_span">
                          Branch Name
                        </span>
                      </label>
                      {branchNameError && (
                        <span className="profile-error-red">
                          {branchNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="number"
                          name="account_number"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.account_number}
                          minLength={6}
                          maxLength={18}
                          onKeyPress={handleKeyPress}
                          placeholder="Account Number"
                        />
                        <span className="Admin_user_register_span">
                          Account Number
                        </span>
                      </label>
                      {accountNumber && (
                        <span className="profile-error-red">
                          {accountNumber}
                        </span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="account_holder_name"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.account_holder_name}
                          minLength={0}
                          maxLength={60}
                          placeholder="Account Holder Name"
                        />
                        <span className="Admin_user_register_span">
                          Account Holder Name
                        </span>
                      </label>
                      {accountHolderNameError && (
                        <span className="profile-error-red">
                          {accountHolderNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  {paymentError && (
                    <p className="profile-error-red payment-error-line">
                      {paymentError}
                    </p>
                  )}

                  <h3 className="edit-user-heading">Address</h3>

                  <div className="pickup-input">
                    <div className="same-line">
                      <div className="input-row">
                        <label className="Admin_user_register_label">
                          <input
                            type="number"
                            name="pincode"
                            className="Admin_user_register_input"
                            onChange={handleChange}
                            value={profileData.pincode}
                            minLength={6}
                            maxLength={6}
                            placeholder="Pincode"
                          />
                          <span className="Admin_user_register_span">
                            Pincode
                          </span>
                        </label>
                        {pincodeLoading && (
                          <img
                            src={loadingImgPath}
                            className="pincode-loading-image"
                          />
                        )}

                        {pincodeError && (
                          <span className="profile-error-red">
                            {pincodeError}
                          </span>
                        )}
                      </div>
                      <div className="input-row">
                        <label className="Admin_user_register_label">
                          <input
                            type="text"
                            name="city"
                            className="Admin_user_register_input"
                            onChange={handleChange}
                            value={profileData.city}
                            placeholder="City"
                          />
                          <span className="Admin_user_register_span">
                            {" "}
                            City
                          </span>
                        </label>
                        {cityError && (
                          <span className="profile-error-red">{cityError}</span>
                        )}
                      </div>
                    </div>
                    <div className="same-line">
                      <div className="input-row">
                        <label className="Admin_user_register_label">
                          <input
                            type="text"
                            name="country"
                            className="Admin_user_register_input"
                            onChange={handleChange}
                            value="India"
                            readOnly
                            disabled
                            style={{ backgroundColor: "#F0F0F0" }}
                          />
                          <span className="Admin_user_register_span">
                            {" "}
                            Country{" "}
                          </span>
                        </label>
                        {countryError && (
                          <span className="profile-error-red">
                            {countryError}
                          </span>
                        )}
                      </div>
                      <div className="input-row">
                        <label className="Admin_user_register_label">
                          <select
                            name="state"
                            className="Admin_user_register_input"
                            value={profileData.state}
                            onChange={handleChange}
                          >
                            <option
                              id="sellers-stateSelectionFeilds"
                              disabled
                              value=""
                            >
                              Select
                            </option>
                            <option value="Andaman and Nicobar">
                              Andaman & Nicobar
                            </option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli">
                              Dadra & Nagar Haveli
                            </option>
                            <option value="Daman and Diu">Daman & Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jammu and Kashmir">
                              Jammu & Kashmir
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                          <span className="Admin_user_register_span">
                            {" "}
                            State
                          </span>
                        </label>
                        {stateError && (
                          <span className="profile-error-red">
                            {stateError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="street_address"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          value={profileData.street_address}
                          placeholder="Address (e.g. House number, apartment name, area and
                        street address)"
                        />
                        <span className="Admin_user_register_span">
                          Address
                          {/* (e.g. House number, apartment name, area and
                          street address) */}
                        </span>
                      </label>
                      {streetAddressError && (
                        <span className="profile-error-red">
                          {streetAddressError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="edit-user-button-row">
                  <button
                    disabled={!isDataChanged}
                    className="btn primary-btn"
                    style={{
                      opacity: isDataChanged ? 1 : 0.5,
                      width: "80px",
                    }}
                    onClick={productPickUpAddressSubmit}
                  >
                    {loading ? <LoaderButton /> : "Update"}
                  </button>
                </div>
              </div>
            </div>

            <div className="password_container">
              <div className="same-line-password">
                <div className="input-row">
                  <div className="role_edit_c1">
                    <label className="Admin_user_register_label">
                      <CustomDropdown
                        options={optionsValue}
                        onSelect={handleSelect}
                        selectedRole={profileData.role_id}
                        hasMoreRoles={hasMoreRoles}
                        fetchMoreAdminRoles={fetchMoreAdminRoles}
                        loadingMoreRoles={loadingMoreRoles}
                        setLoadingMoreRoles={setLoadingMoreRoles}
                        setProfileData={setProfileData}
                        setPermissionIdValue={setPermissionIdValue}
                      />
                      <span
                        className="Admin_user_register_span"
                        id="AddEditUserRoleName"
                      >
                        Role Name
                      </span>
                    </label>

                    {roleNameError && (
                      <span className="profile-error-red">{roleNameError}</span>
                    )}
                    <div>
                      <li className="permision_Heading_user_create">
                        Permissions
                      </li>
                      <GetPermissionList
                        optionsValue={optionsValue}
                        permissionIdValue={permissionIdValue}
                      />
                      {showPermissionList && (
                        <div>
                          {allListPermission.map((permission) => (
                            <li className="listOfPermission" key={permission}>
                              {permission}
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditUser;
