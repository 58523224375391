import { faArrowLeft, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./blog.css";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import { useContext, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import APIEndpoint from "../../../config/APIEndpoints.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import stringConstant from "../../../config/ProjectConstants.json";
import Navigation from "../../../config/Navigation.json";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const AddBlog = () => {
  const location = useLocation();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [state, setState] = useState([]);
  // options to select
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(false);
  const [blogTitleError, setblogTitleError] = useState("");
  const [BlogStatusError, setBlogStatusError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [blogAuthorError, setblogAuthorError] = useState("");
  const [blogDescriptionError, setblogDescriptionError] = useState("");

  const [blogMetaTitleError, setBlogMetaTitleError] = useState("");
  const [blogMetaDescriptionError, setBlogMetaDescriptionError] = useState("");
  const [blogMetaKeywordsError, setBlogMetaKeywordsError] = useState("");

  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [sortDescriptionError, setSortDescriptionError] = useState("");
  const navigate = useNavigate();
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });
  const [blog, setBlog] = useState({
    thumb_image: null,
    status: "",
    title: "",
    category: "",
    description: "",
    short_description: "",
    author: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
  });
  const [thumbnailError, setThumbnailError] = useState("");
  const [imageError, setImageError] = useState("");
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);

  const resetError = (name) => {
    if (name === "thumb_image") {
      setThumbnailError("");
      setImageError("");
    }
    if (name === "status") {
      setBlogStatusError("");
    }
    if (name === "title") {
      setblogTitleError("");
    }
    if (name === "short_description") {
      setSortDescriptionError("");
    }
    if (name === "author") {
      setblogAuthorError("");
    }

    if (name === "meta_title") {
      setBlogMetaTitleError("");
    }
    if (name === "meta_description") {
      setBlogMetaDescriptionError("");
    }
    if (name === "meta_keywords") {
      setBlogMetaKeywordsError("");
    }
  };
  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "thumb_image") {
      const file = event.target.files[0];
      if (value.trim() !== "" || value === "") {
        setBlog((prevData) => ({
          ...prevData,
          [name]: file,
        }));
      }
    } else {
      resetError(name);
      setBlog((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  //
  useEffect(() => {
    setCategoryError("");
    setBlog((prev) => ({
      ...prev,
      category: state,
    }));
  }, [state]);
  const handleDescriptionValueChange = (value) => {
    setblogDescriptionError("");
    setBlog((prev) => ({
      ...(prev || {}),
      description: value,
    }));
  };
  // Get Blog Categories list
  const GetBlogCategoryListData = async () => {
    await fetch(`${API_HOST}/${APIEndpoint.blogCategoriesCrud}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const newdataArray = res.data.data;
          setBlogCategoryList(newdataArray);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    GetBlogCategoryListData();
  }, []);
  // new logic for thumb image upload starts from here.
  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          setThumbnailError(
            "Invalid file type. Only JPG, JPEG, PNG, and WEBP files are allowed."
          );
          return;
        }
        if (file.size > maxFileSize) {
          compressImage(file, maxFileSize);
        } else {
          readFile(file);
        }
        setThumbnailError("");
        setImageError("");
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800;
          const maxHeight = 800;
          let width = image.width;
          let height = image.height;

          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                return;
              }
              setBlog((prevData) => ({
                ...prevData,
                thumb_image: compressedFile,
              }));
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  // img remove after upload
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setBlog((prevData) => ({
      ...prevData,
      thumb_image: null,
    }));

    // Clear the value of the input file element
    const thumbImageInput = document.getElementById("thumb-img-input");
    if (thumbImageInput.value) {
      thumbImageInput.value = null;
    }
  };

  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  // transform the data to fit the component requirements [utils]
  const formatEntitiesCodes = (codes) => {
    return codes
      .map((el) => {
        return el.name;
      })
      .flat();
  };

  // code for count the string
  const countWordsWithoutHtmlTags = (inputString) => {
    // Remove HTML tags from the input string
    const cleanedString = inputString.replace(/<[^>]*>/g, "");
    const wordCount = cleanedString
      ? cleanedString.match(/\b\w+\b/g).length
      : 0;
    return wordCount;
  };

  const newEntities = formatEntitiesCodes(blogCategoryList);
  // render options
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="red" />;

  const renderOptions = (option, selected) => {
    return (
      <>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          // id="Blog_Category_checkbox"
          checked={selected}
        />
        {option}
      </>
    );
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label="Blog Categories"
        placeholder="Blog Categories"
      />
    );
  };

  // Code for Add Blog.
  const addBlog = async () => {
    setIsAddButtonDisable(true);
    let errorHas = false;
    const titleRegex = /^[a-zA-Z0-9-_.\s]+$/;

    if (blog.status === "Draft") {
      if (blog.status === "" || blog.status === null) {
        setBlogStatusError(stringConstant.errorMessages.alertForBlogStatus);
        errorHas = true;
      }

      if (blog.title === "" || blog.title === null) {
        setblogTitleError(stringConstant.errorMessages.alertForBlogTitle);
        errorHas = true;
      } else if (blog.title.length < 5 || blog.title.length > 150) {
        setblogTitleError(stringConstant.errorMessages.alertForValidBlogLength);
        errorHas = true;
      } else {
        setblogTitleError("");
      }
      
    } else {
      if (blog.status === "" || blog.status === null) {
        setBlogStatusError(stringConstant.errorMessages.alertForBlogStatus);
        errorHas = true;
      }

      if (blog.title === "" || blog.title === null) {
        setblogTitleError(stringConstant.errorMessages.alertForBlogTitle);
        errorHas = true;
      } else if (blog.title.length < 5 || blog.title.length > 150) {
        setblogTitleError(stringConstant.errorMessages.alertForValidBlogLength);
        errorHas = true;
      } else {
        setblogTitleError("");
      }
      if (blog.thumb_image === "" || blog.thumb_image === null) {
        setImageError(stringConstant.errorMessages.alertForBlogImage);
        errorHas = true;
      }
      if (
        blog.category.length < 1 ||
        blog.category === "" ||
        blog.category == null
      ) {
        setCategoryError(stringConstant.errorMessages.alertForBlogCategory);
        errorHas = true;
      }
      if (blog.description === "" || blog.description === null) {
        setblogDescriptionError(
          stringConstant.errorMessages.alertForBlogDescription
        );
        errorHas = true;
      }
      

      if (blog.author === "" || blog.author === null) {
        setblogAuthorError(stringConstant.errorMessages.alertForAuthorName);
        errorHas = true;
      } else if (blog.author.length < 2 || blog.author.length > 30) {
        setblogAuthorError(
          stringConstant.errorMessages.alertForValidAuthorLength
        );
        errorHas = true;
      } else {
        setblogAuthorError("");
      }
      if (blog.short_description === "" || blog.short_description === null) {
        setSortDescriptionError(stringConstant.errorMessages.alertForSummry);
        errorHas = true;
      } else if (
        blog.short_description.length < 10 ||
        blog.short_description.length > 350
      ) {
        setSortDescriptionError(
          stringConstant.errorMessages.alertForSummarylength
        );
        errorHas = true;
      }
    }
    // if any errors are there don't call the api
    if (errorHas === true) {
      setIsAddButtonDisable(false);
      return;
    }
    const formData = new FormData();

    formData.append("status", blog.status);
    formData.append("title", blog.title);
    formData.append("thumbnail", blog.thumb_image);
    formData.append("description", blog.description);
    formData.append("category", blog.category);
    formData.append("author", blog.author);
    formData.append("short_description", blog.short_description);

    if (blog.meta_title.length && blog.meta_title.length > 0) {
      formData.append("meta_title", blog.meta_title);
    }
    if (blog.meta_description && blog.meta_description.length > 0) {
      formData.append("meta_description", blog.meta_description);
    }
    if (blog.meta_keywords && blog.meta_keywords.length > 0) {
      formData.append("meta_keywords", blog.meta_keywords);
    }
    await fetch(`${API_HOST}/${APIEndpoint.createBlogByAdmin}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 201) {
          showToast(
            "success",
            stringConstant.alertErrorMessage.alertforBlogCreated
          );

          navigate("/blogs");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else if (data.status === 400) {
          if (data.errors.description) {
            setblogDescriptionError(
              data.errors.description[0]
            );
          }
        } else {
          console.error("Data is not an array");
        }
        setIsAddButtonDisable(false);
      })
      .catch((err) => {
        setIsAddButtonDisable(false);
        console.error(err);
      });
  };

  return (
    <div className="page-data">
      <div className="order-details-heading">
        <h2
          onClick={() => {
            window.history.back();
          }}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon className="back-icon" icon={faArrowLeft} />
          Add Blog
        </h2>
      </div>

      <div className="page-container">
        <div className="Add_Blog_Container">
          <div className="Add_Blog_Left_c1">
            <div className="banner_Image_c1">
              <label className="add-Blog_label_tag">Image</label>
              <div
                onClick={thumbImgUpload}
                className="modal-dropzone"
                onMouseEnter={handleMouseEnterThumbImg}
                onMouseLeave={handleMouseLeaveThumbImg}
              >
                {container1.image ? (
                  <div className="Category_image-placeholderForThumbnail">
                    <img
                      className="Category_image-storing-containerForThumbnail"
                      src={container1.image}
                      loading="lazy"
                    />
                    {container1.hovering && (
                      <button
                        className="delete-button"
                        onClick={handleDeleteThumbClick}
                      >
                        Remove Image
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="click-to-upload">Click to upload</p>
                )}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  name="thumb_image"
                  onChange={handleInputChange}
                  id="thumb-img-input"
                  hidden
                />
              </div>
              <label className="ErrorFor_Banner_url">
                {thumbnailError}
                {imageError}
              </label>
            </div>
            <div className="input-row">
              <label className="Admin_Profile_Lable_tag">
                <select
                  name="status"
                  className="Admin_Profile_input_tag"
                  value={blog.status}
                  onChange={handleInputChange}
                  placeholder="Status"
                >
                  <option value="">Select</option>
                  {/* <option value="Disabled">Disabled</option> */}
                  <option value="Draft">Draft</option>
                  <option value="Published">Published</option>
                </select>
                <span className="Admin_Profile_span_tag">Status </span>
              </label>
              <span className="Add_Blog_error">{BlogStatusError}</span>
            </div>
          </div>
          <div className="Add_Blog_Right_c1">
            <div className="input-row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="title"
                  className="Admin_Profile_input_tag"
                  value={blog.title}
                  onChange={handleInputChange}
                  placeholder="Blog Title"
                />
                <span className="Admin_Profile_span_tag">Blog Title</span>
              </label>
              <span className="Add_Blog_error">{blogTitleError}</span>
            </div>
            <div>
              <label className="Admin_Profile_Lable_tag">
                <textarea
                  type="text"
                  name="short_description"
                  className="Admin_Profile_input_tag add_Blog_textfield"
                  placeholder="Summary"
                  onChange={handleInputChange}
                  value={blog.short_description}
                />
                <span className="Admin_Profile_span_tag"> Summary</span>
              </label>
              <span className="Add_Blog_error"> {sortDescriptionError} </span>
            </div>
            <div className="Add_Blog_Category_c1">
              <Autocomplete
                multiple
                disablePortal
                disableCloseOnSelect
                id="entities-autocomplete"
                options={newEntities}
                // getOptionLabel={(option) => option as string}
                onChange={(event, newInputValue) => setState(newInputValue)}
                renderOption={(option, { selected }) =>
                  renderOptions(option, selected)
                }
                style={{ width: "100%" }}
                renderInput={(params) => renderInput(params)}
              />
              <span className="Add_Blog_error"> {categoryError}</span>
            </div>
            <div>
              <label className="add-Blog_label_tag">Blog Description</label>
              <ReactQuill
                style={{ height: "370px" }}
                value={blog.description}
                name="description"
                onChange={handleDescriptionValueChange}
              />
              <li className="Add_Blog_des_error"> {blogDescriptionError} </li>
            </div>
            <div className="Author_Name_input_row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="author"
                  className="Admin_Profile_input_tag"
                  value={blog.author}
                  onChange={handleInputChange}
                  placeholder="Author Name"
                />
                <span className="Admin_Profile_span_tag">Author Name</span>
              </label>
              <span className="Add_Blog_error"> {blogAuthorError}</span>
            </div>

            {/* meta */}

            <div className="Author_Name_input_row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="meta_title"
                  className="Admin_Profile_input_tag"
                  value={blog.meta_title}
                  onChange={handleInputChange}
                  placeholder="Meta Title"
                />
                <span className="Admin_Profile_span_tag">Meta Title</span>
              </label>
              <span className="Add_Blog_error"> {blogMetaTitleError}</span>
            </div>

            {/*  */}
            <div className="Author_Name_input_row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="meta_description"
                  className="Admin_Profile_input_tag"
                  value={blog.meta_description}
                  onChange={handleInputChange}
                  placeholder="Meta Description"
                />
                <span className="Admin_Profile_span_tag">Meta Description</span>
              </label>
              <span className="Add_Blog_error">
                {" "}
                {blogMetaDescriptionError}
              </span>
            </div>
            {/*  */}
            <div className="Author_Name_input_row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="meta_keywords"
                  className="Admin_Profile_input_tag"
                  value={blog.meta_keywords}
                  onChange={handleInputChange}
                  placeholder="Meta Keywords"
                />
                <span className="Admin_Profile_span_tag">Meta Keywords</span>
              </label>
              <span className="Add_Blog_error"> {blogMetaKeywordsError}</span>
            </div>
            {/* meta */}
            <div className="Blog_Add_Button_Container">
              <button
                className="Add_Blog_submit_Button"
                disabled={isAddButtonDisable}
                onClick={addBlog}
              >
                {isAddButtonDisable ? <LoaderButton /> : "Add"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddBlog;
