import React, { useContext } from "react";
import { useEffect, useState, useMemo } from "react";
import "./customer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@mui/material";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import InputContext from "../../../Components/InputContext";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import { formatDateTime } from "../../../utils/utils";
const apiHost = process.env.REACT_APP_HOST_API_URL;

const Customers = () => {
  const location = useLocation();
  let [data, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  const navigate = useNavigate();
  const CustomerPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");

  // Get All Customers
  const GetCustomersList = async (url) => {
    try {
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }

      let token = getDataFromCookies("7b9KpQ3rW6");
      const response = await fetch(`${url}&per_page=${CustomerPerPage}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();

      if (res.status === 200) {
        const CustomerListData = res.data.data;
        setCustomerData(CustomerListData);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalCount(res.data.total);
        setPagination(res.data);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setCustomerData([]);
        setPagination("");
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error fetching customer list:", error);
    } finally {
      setLoading(false);
      setUpdating(false);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    GetCustomersList(
      `${apiHost}/${APIEndpoint.getCustomerList}?page_no=${currentPage}`
    );
  }, []);

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    GetCustomersList(data);
  };

  const handleCheckboxChange = async (id, status) => {
    setUpdating(true);
    let token = getDataFromCookies("7b9KpQ3rW6");
    const url =
      status === 2
        ? `${apiHost}/${APIEndpoint.unblockCustomer}/${id}`
        : `${apiHost}/${APIEndpoint.blockCustomer}/${id}`;

    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const res = await response.json();

      if (res.status === 200) {
        const user = res.data;
        // Do something with the user data if needed
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else {
        console.error(res);
      }
    } catch (error) {
      setUpdating(false);
      console.error("Error during fetch:", error);
    } finally {
      // setLoading(false);
    }

    try {
      await GetCustomersList(
        `${apiHost}/${APIEndpoint.getCustomerList}?page_no=${currentPage}`
      );
      // setUpdating(false);
    } catch (error) {
      setUpdating(false);
      console.error("Error fetching customer list:", error);
    }
  };

  const handleEditCustomer = (id) => {
    navigate(`${Navigation.customerList}/${id}`);
  };

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      data = [];
      currentPage = 1;
      let url = `${apiHost}/${APIEndpoint.getCustomerList}?page_no=${currentPage}`;
      if (searchQuery !== "") {
        GetCustomersList(url);
      } else {
        GetCustomersList(url);
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * CustomerPerPage,
      },
      {
        Header: "Name",
        accessor: (row) => {
          if (row.f_name === null && row.l_name === null) {
            return "";
          }
          return `${row.f_name || ""} ${row.l_name || ""}`;
        },
        Cell: ({ value }) => (
          <span>
            {value && value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </span>
        ),
      },
      {
        Header: "Contact",
        accessor: (row) => (
          <span>
            {row.email}
            <br />
            {row.phone}
          </span>
        ),
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: "Total Orders",
        accessor: "orders_count",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={
              value === 1
                ? "activecustomer"
                : value === 2
                ? "blockedcustomer"
                : "deactivecustomer"
            }
          >
            {value === 1 ? "Verified" : value === 2 ? "Blocked" : "Deactivated"}
          </span>
        ),
      },
      {
        Header: "Blocked",
        Cell: ({ row }) => (
          <span>
            {(row.original.status === 1 || row.original.status === 2) && (
              <Switch
                checked={row.original.status === 2 ? true : false}
                onChange={() =>
                  handleCheckboxChange(row.original.id, row.original.status)
                }
              />
            )}
          </span>
        ),
      },
      {
        Header: "Joined At",
        Footer: "Joined At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Updated At",
        Footer: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Action",
        accessor: "id",

        Cell: ({ value }) => (
          <button
            onClick={() => handleEditCustomer(value)}
            className="action-icon eye-button"
            title="View"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div>
      <div className="page-data">
        <div className="AdminCustomerSearchBox">
          <p className="AdminCustomersheadingbrandcust">Customers</p>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
              }}
            />
          </div>
        </div>
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound"> No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={"Customers"}
              />
            )}
          </div>
        </div>
      </div>
      {updating && (
        <div className="modal">
          <div className="update-modal-content">Updating..</div>
        </div>
      )}
    </div>
  );
};

export default Customers;
