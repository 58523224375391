import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
  const showToast = (type, message, options = {}) => {
    switch (type) {
      case "info":
        toast.info(message, options);
        break;
      case "success":
        toast.success(message, options);
        break;
      case "warning":
        toast.info(message, options);
        break;
      case "error":
        toast.error(message, options);
        break;
      default:
        toast(message, options);
    }
  };

  return {
    showToast,
    ToastContainer,
  };
};

export default Toast;
