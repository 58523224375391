import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";

import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Navigation from "../../../config/Navigation.json";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const ProductsCollection = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();

  let URL = `${API_HOST}/api/admin/collections`;
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [showCollectionDeleteModal, setShowCollectionDeleteModal] =
    useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [productsCollection, setProductsCollection] = useState([]);
  const [loading, setLoading] = useState(true);

  // delete collection
  const handleDeleteCollection = async (id) => {
    await fetch(`${API_HOST}/api/admin/collection/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        setCollectionToDelete(null);
        setShowCollectionDeleteModal(false);

        if (data.status === 200) {
          showToast("success", data.message);
          await GetProductsCollectionData();
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
        setLoading(false);
      })
      .catch((err) => {
        setCollectionToDelete(null);
        setShowCollectionDeleteModal(false);
        console.error(err);
      });
  };
  // Get List Of collection Data
  const GetProductsCollectionData = async () => {
    await fetch(URL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;

        if (Array.isArray(dataArray)) {
          setProductsCollection(dataArray);
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status === 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    GetProductsCollectionData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1,
      },

      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Visible",
        accessor: "visible",
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <div>
              <button
                onClick={() => navigate(`/products/collections/${id}`)}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                onClick={() => {
                  setCollectionToDelete(id);
                  setShowCollectionDeleteModal(true);
                }}
                className="action-icon trash-button"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: productsCollection,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="page-data">
      <div className="collectionListPageHeader">
        <div className="">
          <p className="collectionProductsListTitle">All Collections </p>
        </div>
        <div
          className="primary-btn"
          onClick={() => {
            navigate("/products/collections/create");
          }}
        >
          {" "}
          Create Collection
        </div>
      </div>
      <div className="price_table_container">
        <table {...getTableProps()}>
          <thead className="Category-grid-container-item2-tableHead">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="Category-grid-container-item2-tableHead">
                    <div className="header-cell">
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  <div className="nomatchfound">Loading...</div>
                </td>
              </tr>
            ) : page.length > 0 ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="TableBodyData" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  <div className="nomatchfound">No collection available.</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* delete collection */}
      {showCollectionDeleteModal && (
        <div
          id="deleteModal"
          className="modal fade"
          style={{ display: showCollectionDeleteModal ? "block" : "none" }}
        >
          <div className="modal-content">
            <span
              className="modal-close"
              onClick={() => {
                setShowCollectionDeleteModal(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <h3 className="modal-header">Delete Confirmation</h3>
            <p className="modal-line">
              Are you sure you want to delete collection?
            </p>
            <div className="modal-buttons">
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => handleDeleteCollection(collectionToDelete)}
              >
                Yes, Delete It!
              </button>

              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowCollectionDeleteModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsCollection;
