import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Navigation from "../../../config/Navigation.json";
import "./bussiness-setup.css";
import Wallet from "./Wallet";
import Toast from "../../../Components/Toast";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import InputContext from "../../../Components/InputContext";
import InputControl from "../../../Components/InputControl/InputControl";

const Api_Host = process.env.REACT_APP_HOST_API_URL;

const BusinessSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  const [editModal, setEditModal] = useState({
    editDetails: "",
    showEditModal: false,
  });
  useEffect(() => {
    if (editModal.showEditModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [editModal.showEditModal]);
  const [businessData, setBusinessData] = useState({});
  const [updatedData, setupdatedData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [productData, setProductData] = useState({});
  const [errors, setErrors] = useState({});
  const updateBusinessSettings = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
      return;
    }
    let payload = {
      contact_info: editModal.editDetails === "Contact Information" ? 1 : 0,
      application_info:
        editModal.editDetails === "Application Information" ? 1 : 0,
      account_info: editModal.editDetails === "Account Information" ? 1 : 0,
      shipping_info: editModal.editDetails === "Shipping Information" ? 1 : 0,
    };
    if (editModal.editDetails === "Contact Information") {
      payload.business_name = updatedData.business_name;
      payload.business_contact_phone = updatedData.business_contact_phone;
      payload.seller_support_phone = updatedData.seller_support_phone;
      payload.business_contact_email = updatedData.business_contact_email;
      payload.seller_support_email = updatedData.seller_support_email;
    } else if (editModal.editDetails === "Shipping Information") {
      payload.default_warehouse_id = updatedData.default_warehouse_id;
      payload.default_shipping_cost = updatedData.default_shipping_cost;
      payload.default_estimated_delivery_days =
        updatedData.default_estimated_delivery_days;
    } else if (editModal.editDetails === "Account Information") {
      for (const key in updatedData.account_info) {
        if (key !== "upi_id") {
          payload[key] = updatedData.account_info[key];
        } else if (key === "upi_id" && updatedData.account_info[key]) {
          payload[key] = updatedData.account_info[key]
            ? updatedData.account_info[key]
            : "";
        }
      }
    } else if (editModal.editDetails === "Application Information") {
      payload.seller_registration = updatedData.seller_registration;
      payload.maintenance_mode = updatedData.maintenance_mode;
      payload.api_docs_username = updatedData.api_docs_username;
      payload.api_docs_password = updatedData.api_docs_password;
    }

    fetch(`${Api_Host}/api/admin/business-settings`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (res.status === 200) {
          await getBusinessSettings();
          showToast("success", "Data updated successfully");
          setEditModal((prev) => ({
            ...prev,
            editDetails: "",
            showEditModal: false,
          }));
          setErrors({});
        } else if (res.status === 400) {
          if (res.errors) {
            let errors = res.errors;
            if (errors.upi_id) {
              setErrors((prev) => ({
                ...prev,
                upi_id: errors.upi_id[0],
              }));
            }
            if (errors.corporate_id) {
              setErrors((prev) => ({
                ...prev,
                corporate_id: errors.corporate_id[0],
              }));
            }
            if (errors.bank_name) {
              setErrors((prev) => ({
                ...prev,
                bank_name: errors.bank_name[0],
              }));
            }
            if (errors.branch_name) {
              setErrors((prev) => ({
                ...prev,
                branch_name: errors.branch_name[0],
              }));
            }
            if (errors.upi_id) {
              setErrors((prev) => ({
                ...prev,
                upi_id: errors.upi_id[0],
              }));
            }
            if (errors.ifsc_code) {
              setErrors((prev) => ({
                ...prev,
                ifsc_code: errors.ifsc_code[0],
              }));
            }
            if (errors.account_number) {
              setErrors((prev) => ({
                ...prev,
                account_number: errors.account_number[0],
              }));
            }
            if (errors.account_holder_name) {
              setErrors((prev) => ({
                ...prev,
                account_holder_name: errors.account_holder_name[0],
              }));
            }

            //
            if (errors.default_warehouse_id) {
              setErrors((prev) => ({
                ...prev,
                default_warehouse_id: errors.default_warehouse_id[0],
              }));
            }
            if (errors.default_shipping_cost) {
              setErrors((prev) => ({
                ...prev,
                default_shipping_cost: errors.default_shipping_cost[0],
              }));
            }
            if (errors.default_estimated_delivery_days) {
              setErrors((prev) => ({
                ...prev,
                default_estimated_delivery_days:
                  errors.default_estimated_delivery_days[0],
              }));
            }
            //
            if (errors.business_name) {
              setErrors((prev) => ({
                ...prev,
                business_name: errors.business_name[0],
              }));
            }
            if (errors.business_contact_phone) {
              setErrors((prev) => ({
                ...prev,
                business_contact_phone: errors.business_contact_phone[0],
              }));
            }
            if (errors.business_contact_email) {
              setErrors((prev) => ({
                ...prev,
                business_contact_email: errors.business_contact_email[0],
              }));
            }
            if (errors.seller_support_phone) {
              setErrors((prev) => ({
                ...prev,
                seller_support_phone: errors.seller_support_phone[0],
              }));
            }
            if (errors.seller_support_email) {
              setErrors((prev) => ({
                ...prev,
                seller_support_email: errors.seller_support_email[0],
              }));
            }

            //
            if (errors.api_docs_username) {
              setErrors((prev) => ({
                ...prev,
                api_docs_username: errors.api_docs_username[0],
              }));
            }
            if (errors.api_docs_password) {
              setErrors((prev) => ({
                ...prev,
                api_docs_password: errors.api_docs_password[0],
              }));
            }
            if (errors.maintenance_mode) {
              setErrors((prev) => ({
                ...prev,
                maintenance_mode: errors.maintenance_mode[0],
              }));
            }
            if (errors.seller_registration) {
              setErrors((prev) => ({
                ...prev,
                seller_registration: errors.seller_registration[0],
              }));
            }
          }
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getBusinessSettings = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
    }

    fetch(`${Api_Host}/api/admin/business-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((obj) => {
            setBusinessData((prev) => ({
              ...prev,
              [obj.type]: obj.value,
            }));
          });
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getWalletSetting = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
    }

    fetch(`${Api_Host}/api/admin/wallet`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setWalletData(res.data);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const updateWalletSetting = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
    }
    let payload = {
      bonus: updatedData.bonus,
      referral: updatedData.referral,
    };

    fetch(`${Api_Host}/api/admin/wallet`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (res.status === 200) {
          await getWalletSetting();
          showToast("success", "Data updated successfully");
          setEditModal((prev) => ({
            ...prev,
            editDetails: "",
            showEditModal: false,
          }));
          setErrors({});
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const getProductSetting = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
    }

    fetch(`${Api_Host}/api/admin/product-on-hold-duration`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setProductData(res.data);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const updateProductSetting = async () => {
    const Authorization_Bearer_Token = getDataFromCookies("7b9KpQ3rW6");

    if (!Authorization_Bearer_Token) {
      window.location.href = `${Navigation.login}`;
    }
    let payload = {
      time_in: updatedData.time_in,
      duration: updatedData.duration,
    };
    fetch(`${Api_Host}/api/admin/product-on-hold-duration`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Authorization_Bearer_Token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (res.status === 200) {
          await getProductSetting();
          showToast("success", "Data updated successfully");
          setEditModal((prev) => ({
            ...prev,
            editDetails: "",
            showEditModal: false,
          }));
          setErrors({});
        } else if (res.status === 400 && res.errors) {
          setErrors(res.errors);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status === 401 && res.message !== "") {
          showToast("error", res.message);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  useEffect(() => {
    getBusinessSettings();
    getProductSetting();
    getWalletSetting();
  }, []);

  return (
    <div>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active">Business Setting</li>
          </div>
        </div>

        <div className="page-container">
          <div className="BusinessSettingContainer">
            <div className="BusinessDetailsSection">
              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">
                      Contact Information
                    </p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        business_name: businessData.business_name,
                        business_contact_phone:
                          businessData.business_contact_phone,
                        seller_support_phone: businessData.seller_support_phone,
                        business_contact_email:
                          businessData.business_contact_email,
                        seller_support_email: businessData.seller_support_email,
                        default_estimated_delivery_days:
                          businessData.default_estimated_delivery_days,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Contact Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Business Name"
                    type="text"
                    value={
                      businessData.business_name
                        ? businessData.business_name
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Business Contact Phone"
                    type="text"
                    value={
                      businessData.business_contact_phone
                        ? businessData.business_contact_phone
                        : ""
                    }
                    readOnly
                  />

                  <InputControl
                    label="Business Contact Email"
                    type="text"
                    value={
                      businessData.business_contact_email
                        ? businessData.business_contact_email
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Seller Support Phone"
                    type="text"
                    value={
                      businessData.seller_support_phone
                        ? businessData.seller_support_phone
                        : ""
                    }
                    readOnly
                  />

                  <InputControl
                    label="Seller Support Email"
                    type="text"
                    value={
                      businessData.seller_support_email
                        ? businessData.seller_support_email
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">
                      Shipping Information
                    </p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        default_warehouse_id: businessData.default_warehouse_id,
                        default_shipping_cost:
                          businessData.default_shipping_cost,
                        default_estimated_delivery_days:
                          businessData.default_estimated_delivery_days,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Shipping Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Default Warehouse"
                    type="text"
                    value={
                      businessData.default_warehouse_id
                        ? businessData.default_warehouse_id
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Default Shipping Cost"
                    type="text"
                    value={
                      businessData.default_shipping_cost
                        ? businessData.default_shipping_cost
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Default Estimated Delivery Days"
                    type="text"
                    value={
                      businessData.default_estimated_delivery_days
                        ? businessData.default_estimated_delivery_days
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>

              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">
                      Account Information
                    </p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        account_info: businessData.account_info,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Account Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Corporate Id"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.corporate_id
                        ? businessData.account_info.corporate_id
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Bank Name"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.bank_name
                        ? businessData.account_info.bank_name
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Branch Name"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.branch_name
                        ? businessData.account_info.branch_name
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="IFSC Code"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.ifsc_code
                        ? businessData.account_info.ifsc_code
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Account Number"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.account_number
                        ? businessData.account_info.account_number
                        : ""
                    }
                    readOnly
                  />

                  <InputControl
                    label="Account Holder Name"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.account_holder_name
                        ? businessData.account_info.account_holder_name
                        : ""
                    }
                    readOnly
                  />

                  <InputControl
                    label="UPI Id"
                    type="text"
                    value={
                      businessData.account_info &&
                      businessData.account_info.upi_id
                        ? businessData.account_info.upi_id
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>

              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">
                      Application Information
                    </p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        seller_registration: businessData.seller_registration,
                        maintenance_mode: businessData.maintenance_mode,
                        api_docs_username: businessData.api_docs_username,
                        api_docs_password: businessData.api_docs_password,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Application Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Seller Registration"
                    type="text"
                    value={
                      businessData.seller_registration
                        ? businessData.seller_registration
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="Maintenance Mode"
                    type="text"
                    value={
                      businessData.maintenance_mode
                        ? businessData.maintenance_mode
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="API Docs Username"
                    type="text"
                    value={
                      businessData.api_docs_username
                        ? businessData.api_docs_username
                        : ""
                    }
                    readOnly
                  />
                  <InputControl
                    label="API Docs Password"
                    type="text"
                    value={
                      businessData.api_docs_password
                        ? businessData.api_docs_password
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>

              {/* wallet */}
              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">Wallet Information</p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        bonus: walletData.bonus,
                        referral: walletData.referral,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Wallet Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Referral Bonus"
                    type="text"
                    value={walletData.referral ? walletData.referral : ""}
                    readOnly
                  />
                  <InputControl
                    label="Welcome Bonus"
                    type="text"
                    value={walletData.bonus ? walletData.bonus : ""}
                    readOnly
                  />
                </div>
              </div>

              {/* product on Hold duration */}
              <div className="Business_Details_Holder">
                <div className="BusinessDetailsHeadingContainer">
                  <div>
                    {" "}
                    <p className="BusinessDetailsHeading">
                      Product Information
                    </p>
                  </div>
                  <div
                    className="EditBusinessDetailsBtn"
                    onClick={(e) => {
                      setupdatedData({
                        time_in: productData.time_in,
                        duration: productData.duration,
                      });
                      setEditModal((prev) => ({
                        ...prev,
                        editDetails: "Product Information",
                        showEditModal: true,
                      }));
                    }}
                  >
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>{" "}
                      Edit
                    </p>
                  </div>
                </div>
                <div className="BusinessAccountDetailsContainer">
                  <InputControl
                    label="Time Format"
                    type="text"
                    value={productData.time_in ? productData.time_in : ""}
                    readOnly
                  />
                  <InputControl
                    label="On Hold Duration"
                    type="text"
                    value={productData.duration ? productData.duration : ""}
                    readOnly
                  />
                </div>
              </div>
              {/*  */}
            </div>
          </div>

          {/* modal */}
          {editModal.showEditModal && editModal.editDetails !== "" && (
            <div className="editBusinessModalContainer">
              <div className="editBusinessModalHolder">
                <div className="editBusinessModal">
                  <div className="editBusinessModalHeaderContainer">
                    <div>
                      <p>{editModal.editDetails}</p>
                    </div>
                    <div
                      className="edit_Cross_Button"
                      onClick={(e) => {
                        setupdatedData({});
                        setEditModal((prev) => ({
                          ...prev,
                          editDetails: "",
                          showEditModal: false,
                        }));
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                          color: "#aaaaaa",
                          fontSize: "18px",
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {editModal.editDetails === "Contact Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <InputControl
                            label="Business Name"
                            type="text"
                            value={updatedData.business_name}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                business_name: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                business_name: event.target.value,
                              }));
                            }}
                            error={errors.business_name}
                          />
                          <InputControl
                            label="Business Contact Phone"
                            type="text"
                            value={updatedData.business_contact_phone}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                business_contact_phone: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                business_contact_phone: event.target.value,
                              }));
                            }}
                            error={errors.business_contact_phone}
                          />

                          <InputControl
                            label="Business Contact Email"
                            type="text"
                            value={updatedData.business_contact_email}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                business_contact_email: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                business_contact_email: event.target.value,
                              }));
                            }}
                            error={errors.business_contact_email}
                          />
                          <InputControl
                            label="Seller Support Phone"
                            type="text"
                            value={updatedData.seller_support_phone}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                seller_support_phone: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                seller_support_phone: event.target.value,
                              }));
                            }}
                            error={errors.seller_support_phone}
                          />
                          <InputControl
                            label="Seller Support Email"
                            type="text"
                            value={updatedData.seller_support_email}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                seller_support_email: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                seller_support_email: event.target.value,
                              }));
                            }}
                            error={errors.seller_support_email}
                          />
                        </div>
                      </div>
                    )}
                    {editModal.editDetails === "Shipping Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <InputControl
                            label="Default Warehouse"
                            type="text"
                            value={updatedData.default_warehouse_id}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                default_warehouse_id: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                default_warehouse_id: event.target.value,
                              }));
                            }}
                            error={errors.default_warehouse_id}
                          />
                          <InputControl
                            label="Default Shipping Cost"
                            type="text"
                            value={updatedData.default_shipping_cost}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                default_shipping_cost: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                default_shipping_cost: event.target.value,
                              }));
                            }}
                            error={errors.default_shipping_cost}
                          />
                          <InputControl
                            label="Default Estimated Delivery Days"
                            type="text"
                            value={updatedData.default_estimated_delivery_days}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                default_estimated_delivery_days: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                default_estimated_delivery_days:
                                  event.target.value,
                              }));
                            }}
                            error={errors.default_estimated_delivery_days}
                          />
                        </div>
                      </div>
                    )}
                    {editModal.editDetails === "Account Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <InputControl
                            label="Corporate Id"
                            type="text"
                            value={updatedData.account_info.corporate_id}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                corporate_id: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  corporate_id: event.target.value,
                                },
                              }));
                            }}
                            error={errors.corporate_id}
                          />

                          <InputControl
                            label="Bank Name"
                            type="text"
                            value={updatedData.account_info.bank_name}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                bank_name: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  bank_name: event.target.value,
                                },
                              }));
                            }}
                            error={errors.bank_name}
                          />
                          <InputControl
                            label="Branch Name"
                            type="text"
                            value={updatedData.account_info.branch_name}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                branch_name: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  branch_name: event.target.value,
                                },
                              }));
                            }}
                            error={errors.branch_name}
                          />

                          <InputControl
                            label="IFSC Code"
                            type="text"
                            value={updatedData.account_info.ifsc_code}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                ifsc_code: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  ifsc_code: event.target.value,
                                },
                              }));
                            }}
                            error={errors.ifsc_code}
                          />
                          <InputControl
                            label="Account Number"
                            type="text"
                            value={updatedData.account_info.account_number}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                account_number: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  account_number: event.target.value,
                                },
                              }));
                            }}
                            error={errors.account_number}
                          />

                          <InputControl
                            label="Account Holder Name"
                            type="text"
                            value={updatedData.account_info.account_holder_name}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                account_holder_name: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  account_holder_name: event.target.value,
                                },
                              }));
                            }}
                            error={errors.account_holder_name}
                          />

                          <InputControl
                            label="UPI Id"
                            type="text"
                            value={updatedData.account_info.upi_id}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                upi_id: "",
                              }));
                              setupdatedData((prevData) => ({
                                ...prevData,
                                account_info: {
                                  ...prevData.account_info,
                                  upi_id: event.target.value,
                                },
                              }));
                            }}
                            error={errors.upi_id}
                          />
                        </div>
                      </div>
                    )}
                    {editModal.editDetails === "Application Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <InputControl
                            label="Seller Registration"
                            type="text"
                            value={updatedData.seller_registration}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                seller_registration: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                seller_registration: event.target.value,
                              }));
                            }}
                            error={errors.seller_registration}
                          />
                          <InputControl
                            label="Maintenance Mode"
                            type="text"
                            value={updatedData.maintenance_mode}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                maintenance_mode: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                maintenance_mode: event.target.value,
                              }));
                            }}
                            error={errors.maintenance_mode}
                          />
                          <InputControl
                            label="API Docs Username"
                            type="text"
                            value={updatedData.api_docs_username}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                api_docs_username: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                api_docs_username: event.target.value,
                              }));
                            }}
                            error={errors.api_docs_username}
                          />
                          <InputControl
                            label="API Docs Password"
                            type="text"
                            value={updatedData.api_docs_password}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                api_docs_password: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                api_docs_password: event.target.value,
                              }));
                            }}
                            error={errors.api_docs_password}
                          />
                        </div>
                      </div>
                    )}{" "}
                    {/* wallet */}
                    {editModal.editDetails === "Wallet Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <InputControl
                            label="Referral Bonus"
                            type="text"
                            value={updatedData.referral}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                referral: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                referral: event.target.value,
                              }));
                            }}
                            error={errors.referral}
                          />
                          <InputControl
                            label="Welcome Bonus"
                            type="text"
                            value={updatedData.bonus}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                bonus: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                bonus: event.target.value,
                              }));
                            }}
                            error={errors.bonus}
                          />
                        </div>
                      </div>
                    )}
                    {/* product */}
                    {editModal.editDetails === "Product Information" && (
                      <div>
                        <div className="BusinessAccountDetailsContainerModal">
                          <div>
                            <label className="Admin_user_register_label">
                              <select
                                name="roleId"
                                className="Admin_user_register_input"
                                placeholder="Role Name"
                                onChange={(event) => {
                                  setErrors((prev) => ({
                                    ...prev,
                                    time_in: "",
                                  }));
                                  setupdatedData((prev) => ({
                                    ...prev,
                                    time_in: event.target.value,
                                  }));
                                }}
                                value={updatedData.time_in}
                              >
                                <option key={1} value={"MINUTE"}>
                                  MINUTE
                                </option>
                                <option key={2} value={"HOUR"}>
                                  HOUR
                                </option>
                              </select>
                              <span className="Admin_user_register_span">
                                Role Name
                              </span>
                            </label>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.7rem",
                                color: "#ff3300",
                                margin: 0,
                              }}
                            >
                              {errors.time_in}
                            </p>
                          </div>
                          <InputControl
                            label="On Hold Duration"
                            type="text"
                            value={updatedData.duration}
                            onChange={(event) => {
                              setErrors((prev) => ({
                                ...prev,
                                duration: "",
                              }));
                              setupdatedData((prev) => ({
                                ...prev,
                                duration: event.target.value,
                              }));
                            }}
                            error={errors.duration}
                          />
                        </div>
                      </div>
                    )}
                    {/*  */}
                    <div className="updateBusinessBtnContainer">
                      <div
                        className="updateBusinessBtn"
                        onClick={() => {
                          if (editModal.editDetails === "Wallet Information") {
                            updateWalletSetting();
                          } else if (
                            editModal.editDetails === "Product Information"
                          ) {
                            updateProductSetting();
                          } else {
                            updateBusinessSettings();
                          }
                        }}
                      >
                        Update
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessSetup;
