import React, { useEffect, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../Components/Toast";
import Navigation from "../../../config/Navigation.json";

// import css
import "./order.css";
import "../../../Assets/style.css";

// define constants
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";

const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;

const OrderDetails = ({ isToggleOn, selectedStatus, handleClickOrder }) => {
  const contextObj = useContext(InputContext);
  const navigate = new useNavigate();
  const location = useLocation();
  const { showToast } = Toast();
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState();
  const [orderStatusActivity, setOrderStatusActivity] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [productData, setProductData] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState();
  const [pickupAddress, setPickupAddress] = useState();
  const [sellerData, setSellerData] = useState(null);
  const [odActionLoading, setOdActionLoading] = useState(false);

  // order update
  const [orderCancelNote, setOrderCancelNote] = useState("");
  const [orderCancelNoteError, setOrderCancelNoteError] = useState("");
  const [productBackInStock, setProductBackInStock] = useState(false);
  const [productBackInStockError, setProductBackInStockError] = useState("");
  const [productCancelNote, setProductCancelNote] = useState("");
  const [productCancelNoteError, setProductCancelNoteError] = useState("");

  // initiate refund
  const [refundNote, setRefundNote] = useState("");
  const [refundNoteError, setRefundNoteError] = useState("");

  // create shipment
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingMethodError, setShippingMethodError] = useState("");
  const [shipmentFor, setShipmentFor] = useState("");
  const [shipmentForError, setShipmentForError] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseError, setWarehouseError] = useState("");
  const [loadingModalBtn, setLoadingModalBtn] = useState(false);
  const [anyActiveShipment, setAnyActiveShipment] = useState(false);
  const [enableScheduleDate, setEnableScheduleDate] = useState(false);
  const [pickupScheduleDate, setPickupScheduleDate] = useState("");
  const [pickupScheduleError, setPickupScheduleError] = useState("");
  const [enableShippingCost, setEnableShippingCost] = useState(false);
  const [shippingCost, setShippingCost] = useState("");
  const [shippingCostError, setShippingCostError] = useState("");
  const [shipmentCreateError, setShipmentCreateError] = useState("");
  const [shipmentOpen, setShipmentOpen] = useState(false);

  // shipment update
  const [updateShipmentId, setUpdateShipmentId] = useState("");
  const [enableShipmentCancel, setEnableShipmentCancel] = useState(false);
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [shipmentStatusError, setShipmentStatusError] = useState("");
  const [shipmentCancelNote, setShipmentCancelNote] = useState("");
  const [shipmentCancelError, setShipmentCancelError] = useState("");
  const [shipmentUpdateError, setShipmentUpdateError] = useState("");

  // modal dropdown values
  const shippingMethods = ["JOYREJOY", "Shiprocket"];
  const [shipmentToList, setShipmentToList] = useState([]);
  const [shipmentStatusList, setShipmentStatusList] = useState([]);

  // get order details by orderId
  const fetchOrderDetails = async () => {
    const url = `${API_HOST}/api/admin/orders/${orderId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const data = await res.data;
      setOrderData(data.order);
      setCustomerData(data.customer);
      setShippingAddress(data.shipping_address);
      setProductData(data.product);
      if (data.seller) {
        setSellerData(data.seller);
      }
      setPickupAddress(data.pickup_address);
      setOrderStatusActivity(data.order.status_tracker);
      setAnyActiveShipment(data.has_active_shipment);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      showToast(
        "error",
        "Oops Sorry! Order you are looking for does not exist."
      );
      navigate(Navigation.orders);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // get order details by orderId
  const fetchShipments = async () => {
    const url = `${API_HOST}/api/admin/shipments-of-order/${orderId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const data = await res.data;
      setShipmentData(data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setShipmentData([]);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const [enableWarehouse, setEnableWarehouse] = useState(false);
  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchOrderDetails();
    fetchShipments();
    getWereHouseAddress();
  }, []);

  const updateOrderStatus = async (orderStatus) => {
    let payload = {
      status: orderStatus,
    };
    if (orderStatus === "Failed in Sanitizing and Packaging") {
      setLoadingModalBtn(true);

      let hasError = false;
      if (orderCancelNote === "") {
        setOrderCancelNoteError("This field is required");
        hasError = true;
      }
      if (!productBackInStock && productCancelNote === "") {
        setProductCancelNoteError("This field is required");
        hasError = true;
      }
      if (hasError) {
        setLoadingModalBtn(false);
        return;
      }

      payload["order_cancel_reason"] = orderCancelNote;
      payload["product_cancel_reason"] = productCancelNote;
    } else {
      setOdActionLoading(true);
    }

    const url = `${API_HOST}/api/admin/orders/${orderId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 200) {
      fetchOrderDetails();
      showToast("success", res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }

    if (orderStatus === "Failed in Sanitizing and Packaging") {
      setLoadingModalBtn(false);
      closeSanitizationFailModal();
    } else {
      setOdActionLoading(false);
    }
  };

  // Order decline modal open
  const openSanitizationFailModal = () => {
    document.getElementById("sanitizationFailModal").style.display = "block";
  };

  // Order decline modal close
  const closeSanitizationFailModal = () => {
    document.getElementById("sanitizationFailModal").style.display = "none";

    setOrderCancelNote("");
    setOrderCancelNoteError("");
    setProductCancelNote("");
    setProductCancelNoteError("");
  };

  // Order decline modal open
  const openOrderDeclineModal = () => {
    document.getElementById("orderDeniedModal").style.display = "block";
  };

  // Order decline modal close
  const closeOrderDeclineModal = () => {
    document.getElementById("orderDeniedModal").style.display = "none";

    setOrderCancelNote("");
    setOrderCancelNoteError("");
    setProductBackInStock(false);
    setProductBackInStockError("");
    setProductCancelNote("");
    setProductCancelNoteError("");
  };

  // Refund modal open
  const openRefundModal = () => {
    document.getElementById("refundModal").style.display = "block";
  };

  // Order decline modal close
  const closeRefundModal = () => {
    document.getElementById("refundModal").style.display = "none";
    setRefundNote("");
    setRefundNoteError("");
  };

  // status change from Pending to Decline.
  const initiateRefund = async () => {
    setLoadingModalBtn(true);
    let hasError = false;

    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    if (refundNote === "") {
      setRefundNoteError("This field is required");
      hasError = true;
    }

    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    const payload = {
      order_id: orderId,
      refund_reason: refundNote,
    };

    const response = await fetch(`${API_HOST}/api/admin/initiate-refund`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 201) {
      fetchOrderDetails();
      showToast("success", res.message);
      closeRefundModal();
    } else if (res.status === 404 || res.status === 422) {
      showToast("error", res.message);
      closeRefundModal();
    } else if (res.status === 400) {
      let errors = "";
      if (res.errors) {
        errors = res.errors;
      } else {
        errors = res.message;
      }
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setRefundNoteError(errorMessage);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setLoadingModalBtn(false);
  };

  // status change from Pending to Decline.
  const orderStatusToDeclined = async () => {
    setLoadingModalBtn(true);

    const url = `${API_HOST}/api/admin/orders/${orderId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    let hasError = false;
    if (orderCancelNote === "") {
      setOrderCancelNoteError("This field is required");
      hasError = true;
    }
    if (!productBackInStock && productCancelNote === "") {
      setProductCancelNoteError("This field is required");
      hasError = true;
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    const payload = {
      order_id: orderId,
      status: "Cancelled",
      product_back_in_stock: productBackInStock ? 1 : 0,
      order_cancel_reason: orderCancelNote,
      product_cancel_reason: productCancelNote,
    };
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    if (res.status === 200) {
      fetchOrderDetails();
      showToast("success", "Order cancelled successfully");
    } else if (res.status === 400 && res.message) {
      showToast("error", res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    closeOrderDeclineModal();
    setLoadingModalBtn(false);
  };

  // Shipment modal open
  const openShipmentModal = () => {
    document.getElementById("shipmentModal").style.display = "block";
  };

  // Order decline modal close
  const closeShipmentModal = () => {
    document.getElementById("shipmentModal").style.display = "none";
    setShippingMethod("");
    setShippingMethodError("");
    setShipmentFor("");
    setShipmentForError("");
    setEnableScheduleDate(false);
    setEnableShippingCost(false);
    setPickupScheduleDate("");
    setPickupScheduleError("");
    setShipmentCreateError("");
    setEnableWarehouse(false);
    setWarehouseId("");
    setWarehouseError("");
  };

  // call create forward shipment for this order
  const createForwardShipment = async (payload) => {
    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(`${API_HOST}/api/admin/shipment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 201) {
      fetchOrderDetails();
      fetchShipments();
      showToast("success", "Shipment created successfully");
      closeShipmentModal();
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      if (errorMessage === "") {
        errorMessage = res.message;
      }
      setShipmentCreateError(errorMessage);
    } else if (res.status === 409) {
      setShipmentCreateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // create return shipment for this order
  const createReturnShipment = async (payload) => {
    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(`${API_HOST}/api/admin/return-shipment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 201) {
      fetchOrderDetails();
      fetchShipments();
      showToast("success", "Shipment created successfully");
      closeShipmentModal();
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      if (errorMessage === "") {
        errorMessage = res.message;
      }
      setShipmentCreateError(errorMessage);
    } else if (res.status === 409) {
      setShipmentCreateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // create shipment for this order
  const createShipment = async () => {
    setLoadingModalBtn(true);

    // check validation
    let hasError = false;
    if (shipmentFor === "") {
      hasError = true;
      setShipmentForError("This field is required");
    }
    if (enableWarehouse && (warehouseId === null || warehouseId === "")) {
      setWarehouseError("This field is required");
      hasError = true;
    } else {
      setWarehouseError("");
    }
    if (shippingMethod === "") {
      hasError = true;
      setShippingMethodError("This field is required");
    } else if (enableScheduleDate && pickupScheduleDate === "") {
      hasError = true;
      setPickupScheduleError("This field is required");
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    // build payload
    let payload = {
      order_id: orderId,
      shipment_to: shipmentFor,
      shipping_method: shippingMethod,
    };
    if (enableWarehouse && warehouseId !== "") {
      payload["warehouse_id"] = warehouseId;
    }

    if (enableScheduleDate) {
      const date = new Date(pickupScheduleDate);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      payload["pickup_scheduled_date"] = formattedDate;
    }
    if (enableShippingCost) {
      payload["shipping_cost"] = shippingCost;
    }

    if (
      shipmentFor === "Admin to Buyer" ||
      shipmentFor === "Seller to Admin" ||
      shipmentFor === "Seller to Buyer"
    ) {
      createForwardShipment(payload);
    } else if (
      shipmentFor === "Buyer to Admin" ||
      shipmentFor === "Admin to Seller" ||
      shipmentFor === "Buyer to Seller"
    ) {
      createReturnShipment(payload);
    }
  };

  const handleShippingMethodChange = (e) => {
    const val = e.target.value;
    setShippingMethod(val);
    if (val === "JOYREJOY") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      setPickupScheduleDate(`${year}-${month}-${day}`);

      setEnableScheduleDate(true);
      setEnableShippingCost(true);
    } else {
      setEnableScheduleDate(false);
      setEnableShippingCost(false);
    }
    setShippingMethodError("");
    setShipmentCreateError("");
    setPickupScheduleError("");
    setShippingCost("");
    setShippingCostError("");
  };

  const handleShipmentForChange = (e) => {
    setShipmentFor(e.target.value);
    setShipmentForError("");
    setShipmentCreateError("");
  };

  // Shipment modal open
  const openShipmentUpdateModal = () => {
    document.getElementById("shipmentUpdateModal").style.display = "block";
  };

  // Order decline modal close
  const closeShipmentUpdateModal = () => {
    document.getElementById("shipmentUpdateModal").style.display = "none";
    setEnableShipmentCancel(false);
  };

  const handleShipmentStatus = (e) => {
    const val = e.target.value;
    setShipmentStatus(val);
    if (val === "Cancelled" || val === "Undelivered")
      setEnableShipmentCancel(true);
    else {
      setEnableShipmentCancel(false);
      setShipmentCancelError("");
    }
    setShipmentUpdateError("");
    setShipmentStatusError("");
  };

  // update shipment for this order
  const updateShipmentStatus = async () => {
    // check validation
    let hasError = false;
    if (shipmentStatus === "") {
      setShipmentStatusError("This field is required");
      hasError = true;
    }
    if (enableShipmentCancel && shipmentCancelNote === "") {
      setShipmentCancelError("This field is required");
      hasError = true;
    }
    if (hasError) {
      setLoadingModalBtn(false);
      return;
    }

    // check user login
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    // build payload
    const payload = {
      shipment_id: updateShipmentId,
      status: shipmentStatus,
    };
    if (enableShipmentCancel) {
      payload["cancel_reason"] = shipmentCancelNote;
    }

    const response = await fetch(`${API_HOST}/api/admin/update-shipment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setLoadingModalBtn(false);
    if (res.status === 200) {
      showToast("success", res.message);
      fetchOrderDetails();
      fetchShipments();
      closeShipmentUpdateModal();
      setShipmentStatus("");
      setShipmentStatusError("");
      setShipmentCancelNote("");
      setShipmentCancelError("");
      setShipmentUpdateError("");
      setEnableShipmentCancel(false);
    } else if (res.status === 400 || res.status === 409) {
      setShipmentUpdateError(res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const getDateTime = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(timestamp));
  };

  const getDate = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date(timestamp));
  };

  const handleGoBack = () => {
    sessionStorage.setItem("selectedStatus", selectedStatus);
    window.history.back();
  };

  return (
    <>
      <div className="page-data">
        <div className="order-details-heading">
          <h2 onClick={handleGoBack} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon className="back-icon" icon={faArrowLeft} />
            Order Details
          </h2>
        </div>

        {orderData && (
          <div className="orderdetails-body">
            <div className="left-div">
              {orderData && (
                <div className="order-info">
                  <p>
                    Order Number <span>{orderData.order_number}</span>
                  </p>
                  <p>
                    Order Status <span>{orderData.order_status}</span>
                  </p>
                  <p>
                    Payment Status <span>{orderData.payment_status}</span>
                  </p>
                  <p>
                    Order Created
                    <span>{getDateTime(orderData.created_at)}</span>
                  </p>
                </div>
              )}
              <div className="product-all-details">
                <div>
                  <div className="image-details">
                    <img
                      className="product-image"
                      src={imgHost + "/" + productData.thumb_image}
                      alt="image"
                      loading="lazy"
                    />
                  </div>
                  <div className="product-details">
                    <div className="product-names">
                      <p>
                        Name<span>{productData.name}</span>
                      </p>
                      <p>
                        Sold By{" "}
                        <p className="sold-by-label">
                          {orderData.sold_by === "seller"
                            ? sellerData
                              ? sellerData.is_owner
                                ? "Business Seller"
                                : "Domestic Seller"
                              : null
                            : "Admin"}
                        </p>
                      </p>
                    </div>
                    <div className="order-prices">
                      {productData.selling_price && (
                        <p>
                          Selling Price
                          <span>₹{productData.selling_price}.00</span>
                        </p>
                      )}
                      {orderData.shipping_cost >= 0 && (
                        <p>
                          Shipping Cost
                          <span>₹{orderData.shipping_cost}.00</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {productData.selling_price && orderData.shipping_cost >= 0 && (
                  <div className="order-subtotal">
                    <p className="order-amount">
                      Total:{" "}
                      <span>
                        ₹{productData.selling_price + orderData.shipping_cost}
                        .00
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="order-quick-info">
                {customerData && (
                  <div className="bottom-div">
                    <h4 className="box-heading">Customer Info</h4>
                    <div>
                      <p>
                        {customerData.f_name} {customerData.l_name}
                      </p>
                      <p>{customerData.phone}</p>
                      <p>{customerData.email}</p>
                    </div>
                  </div>
                )}
                {shippingAddress && (
                  <div className="bottom-div">
                    <h4 className="box-heading">Shipping Address</h4>
                    <div>
                      <p>{shippingAddress.contact_person_name}</p>
                      <p>{shippingAddress.contact_person_phone}</p>
                      <p className="margin-bottom-info">
                        {shippingAddress.contact_person_email}
                      </p>
                      <p>
                        {shippingAddress.address_1} (
                        {shippingAddress.address_type})
                      </p>
                      <p>
                        {shippingAddress.city}, {shippingAddress.state} -{" "}
                        {shippingAddress.pincode}
                      </p>
                    </div>
                  </div>
                )}
                {orderData && sellerData && (
                  <div className="bottom-div">
                    <h4 className="box-heading">
                      {orderData.sold_by && orderData.sold_by === "seller"
                        ? sellerData
                          ? sellerData.is_owner
                            ? "Business Seller"
                            : "Domestic Seller"
                          : null
                        : "Admin Info"}
                    </h4>
                    <div>
                      {sellerData.is_owner === "1" && (
                        <p style={{ fontWeight: "500" }}>
                          {sellerData.shop_name} {" - "}
                          <a
                            href={`${BUYER_APP}/shop/${sellerData.shop_url}`}
                            target="_blank"
                          >
                            {sellerData.shop_url}
                          </a>
                        </p>
                      )}
                      <p>
                        {sellerData.f_name} {sellerData.l_name}
                      </p>
                      <p>{sellerData.phone}</p>
                      <p>{sellerData.email}</p>
                    </div>
                  </div>
                )}
                {pickupAddress && (
                  <div className="bottom-div">
                    <h4 className="box-heading">Pick-up Address</h4>
                    <div>
                      <p>{pickupAddress.street_address}</p>
                      <p>
                        {pickupAddress.city}, {pickupAddress.state} -{" "}
                        {pickupAddress.pincode}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {shipmentData.length > 0 && (
                <div className="shipment-details">
                  <div
                    className="shipment-heading"
                    onClick={() => {
                      setShipmentOpen(!shipmentOpen);
                    }}
                  >
                    <h4>Shipment Details</h4>
                    <div className="shipment-up-down-icon">
                      {shipmentOpen ? (
                        <FontAwesomeIcon icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon icon={faAngleDown} />
                      )}
                    </div>
                  </div>
                  {shipmentOpen &&
                    shipmentData.map((data) => (
                      <div className="single-shipment" key={data.id}>
                        <div className="shipment-basic-info">
                          <p>
                            ID: <b>{data.id}</b>
                          </p>
                          <p>
                            Shipping For: <span>{data.shipment_to}</span>
                          </p>
                          <p>
                            Shipping Method: <span>{data.shipping_method}</span>
                          </p>
                          {data.shipping_method === "JOYREJOY" && (
                            <p>
                              Shipping Cost:
                              <span>₹{data.shipping_cost}.00</span>
                            </p>
                          )}
                          <p>
                            Estimated Pickup Scheduled Date:
                            <span>{getDate(data.pickup_scheduled_date)}</span>
                          </p>
                          <p>
                            Created at:
                            <span>{getDateTime(data.created_at)}</span>
                          </p>
                        </div>
                        {data.shipping_method === "Shiprocket" && (
                          <div className="shiprocket-info">
                            <p>
                              Shiprocket Order ID
                              <span>{data.shiprocket_order_id}</span>
                            </p>
                            <p>
                              Shiprocket Shipment ID
                              <span>{data.shiprocket_shipment_id}</span>
                            </p>
                            <p>
                              AWB Code <span>{data.awb_code}</span>
                            </p>
                            <p>
                              Courier Company ID:{" "}
                              <b>{data.courier_company_id}</b>
                            </p>
                            <p>
                              Courier Company Name
                              <span>{data.courier_name}</span>
                            </p>
                            <p>
                              Pickup Location Code:
                              <span>{data.pickup_location}</span>
                            </p>
                            <p>
                              Shipping Cost:{" "}
                              <span>
                                {data.shipping_cost && `₹${data.shipping_cost}`}
                              </span>
                            </p>
                          </div>
                        )}
                        <div className="shipment-status">
                          <p>
                            Status: <span>{data.status}</span>
                          </p>
                          {data.pause_reason && (
                            <p>
                              Pause Reason:
                              <span>{data.pause_reason}</span>
                            </p>
                          )}
                        </div>
                        <div className="shipment-action">
                          <div className="shipment-docs">
                            {data.label && <a href={data.label}>Label</a>}
                            {data.manifest && (
                              <a href={data.manifest}>Manifest</a>
                            )}
                            {data.invoice && <a href={data.invoice}>Invoice</a>}
                          </div>
                          <div>
                            {data.status !== "Cancelled" &&
                              data.is_pause !== "Yes" && (
                                <button
                                  className="info-btn"
                                  onClick={() => {
                                    setUpdateShipmentId(data.id);

                                    // can be delivered or cancelled
                                    if (
                                      orderData.order_status ===
                                        "Admin to Seller Pickup Processing" ||
                                      orderData.order_status ===
                                        "Admin to Seller Return Processing" ||
                                      orderData.order_status ===
                                        "Buyer to Seller Return Processing" ||
                                      orderData.order_status ===
                                        "Buyer to Admin Return Processing" ||
                                      orderData.order_status ===
                                        "Failed in Sanitizing Return Processing"
                                    ) {
                                      setShipmentStatusList([
                                        "Cancelled",
                                        "Delivered",
                                      ]);

                                      // Only delivered
                                    } else if (
                                      orderData.order_status ===
                                        "Admin to Buyer Out For Delivery" ||
                                      orderData.order_status ===
                                        "Seller to Buyer Out For Delivery"
                                    ) {
                                      setShipmentStatusList([
                                        "Delivered",
                                        "Undelivered",
                                      ]);

                                      // only cancelled
                                    } else {
                                      setShipmentStatusList(["Cancelled"]);
                                    }
                                    openShipmentUpdateModal();
                                  }}
                                >
                                  Update
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="right-div">
              <div className="top-div order-action">
                <h4 className="box-heading">Order Action</h4>
                <ul>
                  {orderData.order_status === "Pending" && (
                    <li className="confirm-order">
                      Confirm order?
                      {odActionLoading ? (
                        <button className="waiting-btn">Please wait...</button>
                      ) : (
                        <>
                          <button
                            className="create-btn"
                            onClick={() => {
                              setOdActionLoading(true);
                              updateOrderStatus("Confirmed");
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            className="cancel-btn"
                            onClick={openOrderDeclineModal}
                          >
                            Decline
                          </button>
                        </>
                      )}
                    </li>
                  )}

                  {/* payment is pending */}
                  {orderData.order_status === "Confirmed" &&
                    orderData.payment_status === "Unpaid" && (
                      <li>Payment is pending</li>
                    )}

                  {/* order mark as Failed in Sanitizing */}
                  {orderData.order_status === "Sanitizing and Packaging" && (
                    <li>
                      Sanitizing status?
                      {odActionLoading ? (
                        <button className="waiting-btn">Please wait...</button>
                      ) : (
                        <>
                          <button
                            className="create-btn"
                            onClick={() => {
                              updateOrderStatus(
                                "Passed in Sanitizing and Packaging"
                              );
                            }}
                          >
                            Pass
                          </button>
                          <button
                            className="cancel-btn"
                            onClick={openSanitizationFailModal}
                          >
                            Fail
                          </button>
                        </>
                      )}
                    </li>
                  )}

                  {/* order delivered info */}
                  {orderData.order_status === "Delivered" && (
                    <li>🎉 Order delivered successfully!</li>
                  )}

                  {/* order returned info */}
                  {orderData.sold_by === "admin" &&
                    orderData.order_status ===
                      "Buyer to Admin Return Completed" && (
                      <li>Order returned successfully!</li>
                    )}
                  {orderData.sold_by === "seller" &&
                    (orderData.order_status ===
                      "Admin to Seller Return Completed" ||
                      orderData.order_status ===
                        "Failed in Sanitizing Return Completed" ||
                      orderData.order_status ===
                        "Buyer to Seller Return Completed") && (
                      <li>Order returned successfully!</li>
                    )}

                  {/* Create shipment */}
                  {!anyActiveShipment &&
                    orderData.payment_status !== "Unpaid" &&
                    orderData.order_status !== "Pending" &&
                    orderData.order_status !== "Cancelled" &&
                    orderData.order_status !== "Sanitizing and Packaging" &&
                    !(
                      orderData.sold_by === "admin" &&
                      orderData.order_status ===
                        "Buyer to Admin Return Completed"
                    ) &&
                    orderData.order_status !==
                      "Admin to Seller Return Completed" &&
                    orderData.order_status !==
                      "Buyer to Seller Return Completed" &&
                    orderData.order_status !==
                      "Failed in Sanitizing Return Completed" &&
                    !(
                      (orderData.order_status ===
                        "Failed in Sanitizing and Packaging" ||
                        orderData.order_status ===
                          "Buyer to Admin Return Completed") &&
                      orderData.payment_status === "Paid"
                    ) && (
                      <li>
                        Create shipment
                        <button
                          className="create-btn"
                          onClick={() => {
                            // order_status: Confirmed
                            // product sold by: seller
                            // create shipment for "Seller To Admin || Seller to Buyer"

                            // order_status: Confirmed
                            // product sold by: admin
                            // create shipment for "Admin To Buyer"
                            if (orderData.order_status === "Confirmed") {
                              if (orderData.sold_by === "seller") {
                                setShipmentToList([
                                  "Seller to Admin",
                                  "Seller to Buyer",
                                ]);
                              } else if (orderData.sold_by === "admin") {
                                setShipmentToList(["Admin to Buyer"]);
                              }

                              // order_status: Passed in Sanitizing and Packaging
                              // create shipment for "Admin to Buyer"
                            } else if (
                              orderData.order_status ===
                              "Passed in Sanitizing and Packaging"
                            ) {
                              setShipmentToList(["Admin to Buyer"]);

                              // order_status: Failed in Sanitizing and Packaging
                              // create shipment for "Admin to Seller"
                            } else if (
                              orderData.order_status ===
                              "Failed in Sanitizing and Packaging"
                            ) {
                              setShipmentToList(["Admin to Seller"]);

                              // order_status: Delivered
                              // create shipment for "Buyer to Admin"
                            } else if (orderData.order_status === "Delivered") {
                              if (orderData.sold_by === "seller") {
                                setShipmentToList([
                                  "Buyer to Admin",
                                  "Buyer to Seller",
                                ]);
                              } else if (orderData.sold_by === "admin") {
                                setShipmentToList(["Buyer to Admin"]);
                              }

                              // order_status: Buyer to Admin Return Completed
                              // sold_by: seller
                            } else if (
                              orderData.order_status ===
                                "Buyer to Admin Return Completed" &&
                              orderData.sold_by === "seller"
                            ) {
                              setShipmentToList(["Admin to Seller"]);

                              // Failed in Delivery
                            } else if (
                              orderData.order_status ===
                              "Admin to Buyer Delivery Failed"
                            ) {
                              setShipmentToList(["Admin to Buyer"]);
                            } else if (
                              orderData.order_status ===
                              "Seller to Buyer Delivery Failed"
                            ) {
                              setShipmentToList(["Seller to Buyer"]);
                            }

                            openShipmentModal();
                          }}
                        >
                          Create
                        </button>
                      </li>
                    )}

                  {/* shipment status */}
                  {anyActiveShipment && <li>Shipment is in-process</li>}

                  {/* mark as Out For Delivery */}
                  {(orderData.order_status ===
                    "Admin to Buyer Delivery Processing" ||
                    orderData.order_status ===
                      "Seller to Buyer Delivery Processing") &&
                    anyActiveShipment && (
                      <li>
                        Out for delivery?
                        {odActionLoading ? (
                          <button className="waiting-btn">
                            Please wait...
                          </button>
                        ) : (
                          <button
                            className="info-btn"
                            onClick={() => {
                              if (
                                orderData.order_status ===
                                "Admin to Buyer Delivery Processing"
                              ) {
                                updateOrderStatus(
                                  "Admin to Buyer Out For Delivery"
                                );
                              } else if (
                                orderData.order_status ===
                                "Seller to Buyer Delivery Processing"
                              ) {
                                updateOrderStatus(
                                  "Seller to Buyer Out For Delivery"
                                );
                              }
                            }}
                          >
                            Yes
                          </button>
                        )}
                      </li>
                    )}

                  {/* cancel order */}
                  {orderData.order_status === "Confirmed" && (
                    <li className="cancel-order">
                      Cancel order?
                      <button
                        className="cancel-btn"
                        onClick={openOrderDeclineModal}
                      >
                        Cancel
                      </button>
                    </li>
                  )}

                  {/* order cancelled info */}
                  {orderData.order_status === "Cancelled" && (
                    <li
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      Order has been cancelled
                      {orderData.pause_reason && (
                        <span className="od-cancelled-reason">
                          <b style={{ fontSize: "14px", fontWeight: "500" }}>
                            Reason:
                          </b>{" "}
                          {orderData.pause_reason.includes(
                            "PAYMENT_UNPAID_WITHIN_PRODUCT_ON_HOLD_DURATION"
                          )
                            ? orderData.pause_reason.split(
                                "PAYMENT_UNPAID_WITHIN_PRODUCT_ON_HOLD_DURATION: "
                              )[1]
                            : orderData.pause_reason}
                        </span>
                      )}
                    </li>
                  )}

                  {/* refund process is pending */}
                  {(orderData.order_status === "Cancelled" ||
                    orderData.order_status ===
                      "Buyer to Admin Return Completed" ||
                    orderData.order_status ===
                      "Buyer to Seller Return Completed" ||
                    orderData.order_status ===
                      "Failed in Sanitizing and Packaging") &&
                    orderData.payment_status === "Paid" && (
                      <li>Refund process is pending</li>
                    )}

                  {/* initiate refund */}
                  {(orderData.order_status === "Cancelled" ||
                    orderData.order_status ===
                      "Buyer to Admin Return Completed" ||
                    orderData.order_status ===
                      "Buyer to Seller Return Completed" ||
                    orderData.order_status ===
                      "Failed in Sanitizing and Packaging") &&
                    orderData.payment_status === "Paid" && (
                      <li>
                        Initiate refund?
                        <button
                          className="create-btn"
                          onClick={openRefundModal}
                        >
                          Create
                        </button>
                      </li>
                    )}

                  {/* refund is in-process */}
                  {orderData.payment_status === "Refund Initiated" && (
                    <li>Refund is in-process</li>
                  )}

                  {/* refund completed */}
                  {orderData.payment_status === "Refunded" && (
                    <li>Refund processed successfully</li>
                  )}
                </ul>
              </div>

              <div className="bottom-div order-action">
                <h4 className="box-heading">Order Activity</h4>
                <ul>
                  {orderData.status_tracker && (
                    <>
                      {orderStatusActivity.map((data, index) => (
                        <div key={index} className="order-status-track">
                          <div className="circle">
                            <div className="inner-circle"></div>
                          </div>
                          <li className="order-status-tracker">
                            <p>
                              {data.status}
                              <br />
                              <span>{getDateTime(data.timestamp)}</span>
                            </p>
                          </li>
                        </div>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Refund modal */}
      <div id="refundModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={closeRefundModal}
          >
            &times;
          </button>
          <div className="order-modal-header-refund">
            <h1 className="Initiate-refund-heading">Initiate refund</h1>

            <p className="modal-heading-refund">
              Are you sure, want to refund the payment?
            </p>
          </div>
          <div className="modal-body-refund">
            <label className="Add_category_label">
              <textarea
                className="form-control Add_category_input decline-input"
                value={refundNote}
                placeholder="Refund note"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setRefundNote(inputValue);
                }}
              ></textarea>
            </label>
            {refundNoteError && (
              <label htmlFor="error" className="error">
                {refundNoteError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    initiateRefund();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={closeRefundModal}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Failed in Sanitizing and Packaging Modal */}
      <div id="sanitizationFailModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={() => {
              closeSanitizationFailModal();
            }}
          >
            &times;
          </button>
          <div className="order-modal-header">
            <p className="modal-heading">Product failed in sanitization?</p>
          </div>
          <div className="modal-body">
            <label>Cancel Reason For Customer</label>
            <input
              className="form-control"
              type="text"
              value={orderCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setOrderCancelNote(inputValue);
                setOrderCancelNoteError("");
              }}
            />
            {orderCancelNoteError && (
              <label htmlFor="error" className="error">
                {orderCancelNoteError}
              </label>
            )}
            <label>Product Failed Note</label>
            <textarea
              className="form-control decline-input"
              value={productCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setProductCancelNote(inputValue);
                setProductCancelNoteError("");
              }}
            ></textarea>
            {productCancelNoteError && (
              <label htmlFor="error" className="error">
                {productCancelNoteError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    updateOrderStatus("Failed in Sanitizing and Packaging");
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={closeSanitizationFailModal}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Order Declined/Cancelled modal */}
      <div id="orderDeniedModal" className="order modal fade">
        <div className="modal-content">
          <button
            type="button"
            className="order modal-close-icon"
            onClick={() => {
              closeOrderDeclineModal();
            }}
          >
            &times;
          </button>
          <div className="order-modal-header">
            <p className="modal-heading">Cancel the order?</p>
          </div>
          <div className="modal-body">
            <label>Cancel Reason For Customer</label>
            <input
              className="form-control"
              type="text"
              value={orderCancelNote}
              onChange={(e) => {
                const inputValue = e.target.value;
                setOrderCancelNote(inputValue);
                setOrderCancelNoteError("");
              }}
            />
            {orderCancelNoteError && (
              <label htmlFor="error" className="error">
                {orderCancelNoteError}
              </label>
            )}
            <label className="product-back-in-stock">
              <input
                type="checkbox"
                name="product_back_in_stock"
                checked={productBackInStock}
                onChange={(e) => {
                  setProductBackInStock(!productBackInStock);
                }}
              />{" "}
              Roll back product stock
            </label>
            {productBackInStockError && (
              <label htmlFor="error" className="error">
                {productBackInStockError}
              </label>
            )}
            {!productBackInStock && (
              <>
                <label>Cancel Reason For Seller</label>
                <textarea
                  className="form-control decline-input"
                  value={productCancelNote}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setProductCancelNote(inputValue);
                    setProductCancelNoteError("");
                  }}
                ></textarea>
                {productCancelNoteError && (
                  <label htmlFor="error" className="error">
                    {productCancelNoteError}
                  </label>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    orderStatusToDeclined();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeOrderDeclineModal();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Create shipment modal */}
      <div id="shipmentModal" className="order modal fade">
        <div className="modal-content">
          <span
            className="order modal-close-icon"
            onClick={() => {
              closeShipmentModal();
            }}
          >
            &times;
          </span>
          <div className="order-modal-header">
            <p className="modal-heading">Create shipment</p>
          </div>
          <div className="modal-body">
            <div>
              <label>Shipment for</label>
              <select
                className="form-control shipment-for-input"
                value={shipmentFor}
                onChange={(e) => {
                  e.preventDefault();
                  handleShipmentForChange(e);
                  const val = e.target.value;
                  if (
                    val === "Seller to Admin" ||
                    val === "Admin to Seller" ||
                    val === "Admin to Buyer" ||
                    val === "Buyer to Admin"
                  ) {
                    setEnableWarehouse(true);
                  } else {
                    setEnableWarehouse(false);
                  }
                }}
              >
                <option value="">Select</option>
                {shipmentToList.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </select>
              {shipmentForError && (
                <label htmlFor="error" className="error">
                  {shipmentForError}
                </label>
              )}
            </div>
            {enableWarehouse && (
              <div>
                <label>Warehouse</label>
                <select
                  className="form-control warehouse-select"
                  name="warehouse_id"
                  onChange={(e) => {
                    e.preventDefault();
                    const val = e.target.value;
                    setWarehouseId(val);
                  }}
                  value={warehouseId}
                >
                  <option value="">Select</option>
                  {warehouseOptionsValue.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.warehouse_name}
                      </option>
                    );
                  })}
                </select>
                {warehouseError && (
                  <label htmlFor="error" className="error">
                    {warehouseError}
                  </label>
                )}
              </div>
            )}
            <div>
              <label>Shipping method</label>
              <select
                className="form-control shipping-method-input"
                value={shippingMethod}
                onChange={handleShippingMethodChange}
              >
                <option value="">Select</option>
                {shippingMethods.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </select>
              {shippingMethodError && (
                <label htmlFor="error" className="error">
                  {shippingMethodError}
                </label>
              )}
            </div>
            {enableScheduleDate && (
              <>
                <label>Estimated pickup date</label>
                <input
                  type="date"
                  className="form-control"
                  value={pickupScheduleDate}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setPickupScheduleDate(inputValue);
                    setPickupScheduleError("");
                  }}
                />
                {pickupScheduleError && (
                  <label htmlFor="error" className="error">
                    {pickupScheduleError}
                  </label>
                )}
              </>
            )}
            {enableShippingCost && (
              <>
                <label>Shipping cost</label>
                <input
                  type="number"
                  className="form-control"
                  value={shippingCost}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setShippingCost(inputValue);
                    setShippingCostError("");
                  }}
                />
                {shippingCostError && (
                  <label htmlFor="error" className="error">
                    {shippingCostError}
                  </label>
                )}
              </>
            )}
            {shipmentCreateError && (
              <label htmlFor="error" className="error">
                {shipmentCreateError}
              </label>
            )}
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    createShipment();
                  }}
                >
                  Create
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeShipmentModal();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* update shipment status modal */}
      <div id="shipmentUpdateModal" className="order modal fade">
        <div className="modal-content">
          <span
            className="order modal-close-icon"
            onClick={() => {
              closeShipmentUpdateModal();
              setShipmentCancelError("");
              setShipmentCancelNote("");
              setShipmentUpdateError("");
              setShipmentStatus("");
            }}
          >
            &times;
          </span>
          <div className="order-modal-header">
            <p className="modal-heading">Shipment update</p>
          </div>
          <div className="modal-body">
            <label>Status</label>
            <select
              className="form-control"
              value={shipmentStatus}
              onChange={handleShipmentStatus}
            >
              <option value="">Select</option>
              {shipmentStatusList.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
            {shipmentStatusError && (
              <label htmlFor="error" className="error">
                {shipmentStatusError}
              </label>
            )}
            {enableShipmentCancel && (
              <>
                <label>Cancel note</label>
                <textarea
                  className="form-control decline-input"
                  value={shipmentCancelNote}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setShipmentCancelNote(inputValue);
                  }}
                ></textarea>
                {shipmentCancelError && (
                  <label htmlFor="error" className="error">
                    {shipmentCancelError}
                  </label>
                )}
              </>
            )}
            {shipmentUpdateError && (
              <label htmlFor="error" className="error">
                {shipmentUpdateError}
              </label>
            )}
            <label htmlFor="" className="shipment-delivered-status">
              Note: Status as <span>Delivered</span> will not affect on actual
              shipment
            </label>
          </div>
          <div className="modal-footer">
            {loadingModalBtn ? (
              <button className="modal-btn modal-processing-btn">
                Processing...
              </button>
            ) : (
              <>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    setLoadingModalBtn(true);
                    updateShipmentStatus();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    closeShipmentUpdateModal();
                    setShipmentCancelError("");
                    setShipmentCancelNote("");
                    setShipmentUpdateError("");
                    setShipmentStatus("");
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
