import React, { useState, useEffect, useMemo, useContext } from "react";
import AddBrand from "./NewBrand";
import EditBrand from "./EditBrand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import stringConstant from "../../../config/ProjectConstants.json";
import { useNavigate, useLocation } from "react-router-dom";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import "./brand.css";
import LoaderButton from "../../../Components/LoaderButton";
let API_HOST = process.env.REACT_APP_HOST_API_URL;

const Brands = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const [reFetch, setReFetch] = useState(false);
  const navigate = useNavigate();
  const [showAddBrandPage, setshowAddBrandPage] = useState(false);
  const [showEditBrandPage, setshowEditBrandPage] = useState(false);
  let [brandlist, setBrandList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brandId, setbrandId] = useState(null);
  const [brandImage, setbrandImage] = useState("");
  const [brandName, setbrandName] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [totalCount, setTotalCount] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showToast } = Toast();
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const handelAddProductShow = () => {
    setshowAddBrandPage(true);
  };
  const BrandPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");

  const handleCloseModal = () => {
    let isChange = getDataFromCookies("isBrandCreate");
    if (isChange) {
      lastPage = totalCount % BrandPerPage === 0 ? lastPage + 1 : lastPage;
      setshowAddBrandPage(false);
      CheckGetBrands(
        `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${lastPage}`
      );
      removeDataFromCookie("isBrandCreate");
    } else {
      setshowAddBrandPage(false);
    }
  };

  const handelEditProductShow = (id, image, name) => {
    setshowEditBrandPage(true);
    setbrandId(id);
    setbrandImage(image);
    setbrandName(name);
  };

  const handleCloseModalEdit = () => {
    let data = getDataFromCookies("isEdited");
    if (data) {
      setshowEditBrandPage(false);
      CheckGetBrands(
        `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${currentPage}`
      );
      removeDataFromCookie("isEdited");
    } else {
      setshowEditBrandPage(false);
    }
  };
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    CheckGetBrands(data);
  };

  const CheckGetBrands = async (url) => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }
      const response = await fetch(`${url}&per_page=${BrandPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 401 && res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        navigate(Navigation.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setBrandList([]);
        setPagination("");
      } else if (res.status === 200) {
        setBrandList(res.data.data);
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalCount(res.data.total);
      }
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    CheckGetBrands(`${API_HOST}/${APIEndpoint.crudBrands}?page_no=1`);
  }, []);
  useEffect(() => {
    if (
      contextObj.inputValue.navigation === "true" &&
      contextObj.inputValue.navigationFrom === "addBrand"
    ) {
      showToast(
        stringConstant.alertErrorMessage.alertseveritysuccess,
        stringConstant.alertErrorMessage.alertForBrandAdd
      );

      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
    } else if (
      contextObj.inputValue.navigation === "true" &&
      contextObj.inputValue.navigationFrom === "EditBrand"
    ) {
      showToast(
        stringConstant.alertErrorMessage.alertseveritysuccess,
        stringConstant.alertErrorMessage.alertForBrandEdit
      );

      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
    }
  }, [contextObj.inputValue.navigation]);

  const handleConfirmDelete = (id) => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    fetch(`${API_HOST}/${APIEndpoint.crudBrands}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          showToast(
            stringConstant.alertErrorMessage.alertseveritysuccess,
            stringConstant.alertErrorMessage.alertForBrandDelete
          );
          handleCancelDelete();
        } else if (response.status === 409) {
          showToast(
            stringConstant.alertErrorMessage.alertseverityerror,
            response.message
          );
          handleCancelDelete();
        } else if (
          response.status === 401 &&
          response.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status === 401 && response.message !== "") {
          showToast("error", response.message);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };

  //

  const handleCancelDelete = () => {
    setDeleteItemId(null);
    setShowDeleteModal(false);
    if (currentPage === lastPage) {
      currentPage =
        totalCount % BrandPerPage === 1 ? currentPage - 1 : currentPage;
      CheckGetBrands(
        `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${currentPage}`
      );
    } else {
      CheckGetBrands(
        `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${currentPage}`
      );
    }
  };

  const handleDeleteClickpopup = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (row, index) => index + 1 + (currentPage - 1) * BrandPerPage,
      },
      {
        Header: "Brand Logo",
        accessor: "logo",
        Cell: ({ value }) => (
          <img
            className="AdminBrandSetupListDivallelementImageDivimage"
            src={imgHost + "/" + value}
            alt="Brand Image"
          />
        ),
      },
      {
        Header: "Brand Name",
        accessor: "name",
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const isDeleting = deleteItemId === id;
          return isDeleting ? (
            <div>
              {/* Product delete modal */}
              {showDeleteModal && (
                <>
                  <div
                    id="deleteModal"
                    className="modal fade"
                    style={{ display: showDeleteModal ? "block" : "none" }}
                  >
                    <div className="modal-content">
                      <span
                        className="modal-close"
                        onClick={handleCancelDelete}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      <h3 className="modal-header">Delete Confirmation</h3>
                      <p className="modal-line">
                        Are you sure you want to delete{" "}
                        <span className="deleteitemsNameappPages">{name}</span>{" "}
                        brand?
                      </p>
                      <p
                        style={{
                          opacity: 0.5,
                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        <b>NOTE :</b> Please make sure that the current brand is
                        NOT associated with any of the products.
                      </p>
                      <div className="modal-buttons">
                        <button
                          className="btn primary-btn"
                          onClick={() => handleConfirmDelete(id)}
                        >
                          Yes, Delete It!
                        </button>
                        <button
                          className="btn secondary-btn"
                          onClick={handleCancelDelete}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  <span>
                    <button className="action-icon edit-button" title="Edit">
                      <FontAwesomeIcon
                        onClick={() => {
                          handelEditProductShow(
                            row.original.id,
                            row.original.logo,
                            row.original.name
                          );
                        }}
                        id="checkPageOpen"
                        icon={faPenToSquare}
                      />
                    </button>
                    <button className="action-icon trash-button" title="Delete">
                      <FontAwesomeIcon
                        onClick={() => handleDeleteClickpopup(id)}
                        icon={faTrashCan}
                      />
                    </button>
                  </span>
                </>
              )}
            </div>
          ) : (
            <span>
              <button className="action-icon edit-button" title="Edit">
                <FontAwesomeIcon
                  onClick={() => {
                    handelEditProductShow(
                      row.original.id,
                      row.original.logo,
                      row.original.name
                    );
                  }}
                  id="checkPageOpen"
                  icon={faPenToSquare}
                />
              </button>
              <button className="action-icon trash-button" title="Delete">
                <FontAwesomeIcon
                  onClick={() => {
                    handleDeleteClickpopup(id);
                  }}
                  icon={faTrashCan}
                />
              </button>
            </span>
          );
        },
      },
    ],
    [deleteItemId, showDeleteModal, currentPage] // Add deleteItemId as a dependency
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: brandlist, // Rename 'brandlist' to 'data'
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      brandlist = [];
      currentPage = 1;
      let url = `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${currentPage}`;
      if (searchQuery !== "") {
        CheckGetBrands(url);
      } else {
        CheckGetBrands(url);
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    setLoading(true);
    brandlist = [];
    let url = `${API_HOST}/${APIEndpoint.crudBrands}?page_no=${currentPage}`;
    if (searchQuery !== "") {
      CheckGetBrands(url);
    } else {
      CheckGetBrands(url);
    }
  }, [reFetch]);

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Brands</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              handelAddProductShow();
            }}
            className="btn primary-btn"
          >
            New Brand
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
              }}
            />
          </div>
        </div>
        <div>
          {showAddBrandPage && <AddBrand handleCloseModal={handleCloseModal} />}
        </div>
        <div className="AdminBrandSetupListDiv">
          <table {...getTableProps()}>
            <thead className="hadingelementsbrandSetups">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No Brands Available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={"Brands"}
              />
            )}
          </div>
        </div>
        {showEditBrandPage && (
          <EditBrand
            handleCloseModalEdit={handleCloseModalEdit}
            BrandId={brandId}
            BrandImage={brandImage}
            BrandName={brandName}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        )}
      </div>
    </div>
  );
};

export default Brands;
