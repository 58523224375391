import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import { formatDateTime } from "../../../utils/utils";
let API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerList = () => {
  const { showToast } = Toast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterStatus = queryParams.get("status");

  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [pagination, setPagination] = useState("");
  let [data, setData] = useState([]);
  const [sellerCount, setSellerCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [sellerUserType, setSellerUserType] = useState("All");
  const NumberOfSellerPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [isSearchApply, setIsSearchApply] = useState(false);

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    const sellerUsersType = contextObj.inputValue.sellerType || "All";
    const array = [];
    let url = data;
    if (filterStatus !== "" && filterStatus !== "All") {
      url = url + `&status=${filterStatus}&per_page=${NumberOfSellerPerPage}`;
    } else {
      url = url + `&per_page=${NumberOfSellerPerPage}`;
    }
    fetchSellerList(url);
  };

  const fetchSellerList = async (url) => {
    setLoading(true);

    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      const resData = res.data;
      setPagination(resData.sellers);
      setData(resData.sellers.data);
      setCurrentPage(resData.sellers.current_page);
      setSellerCount(resData.count);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
      navigate(Navigation.login);
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setData([]);
      setPagination("");
    }
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    data = [];
    let url;
    if (filterStatus !== "All") {
      url =
        `${API_HOST}/${APIEndpoint.getSellersList}` +
        `?&status=${filterStatus}&per_page=${NumberOfSellerPerPage}`;
    } else {
      url =
        `${API_HOST}/${APIEndpoint.getSellersList}` +
        `?&per_page=${NumberOfSellerPerPage}`;
    }
    fetchSellerList(url);
    setSellerUserType(filterStatus);
  }, [
    contextObj.inputValue.sellerType,
    contextObj.inputValue.sellerListStatus,
  ]);

  const goToAccountDetailsPage = (sellerId, firstName, lastName) => {
    const fullName = `${firstName} ${lastName}`;
    contextObj.setInput("sellerUserName", fullName);
    sessionStorage.setItem("sellerUserName", fullName);
    navigate(`/user/sellers/${sellerId}/account`);
  };

  // handle search api calls
  useEffect(() => {
    if (isSearchApply) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        data = [];
        currentPage = 1;
        let url;
        if (filterStatus !== "All") {
          url =
            `${API_HOST}/${APIEndpoint.getSellersList}` +
            `?&status=${filterStatus}&per_page=${NumberOfSellerPerPage}`;
        } else {
          url =
            `${API_HOST}/${APIEndpoint.getSellersList}` +
            `?&per_page=${NumberOfSellerPerPage}`;
        }
        fetchSellerList(url);
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        Footer: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * NumberOfSellerPerPage,
      },
      {
        Header: "Name",
        accessor: (row) => {
          if (row.f_name === null && row.l_name === null) {
            return "";
          }
          return `${row.f_name || ""} ${row.l_name || ""}`;
        },
        Cell: ({ value }) => (
          <span>
            {value && value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </span>
        ),
      },
      {
        Header: "Contact",
        accessor: "contactInfo",
        Cell: ({ row }) => {
          const { email, phone } = row.original;

          return (
            <>
              <p>{email}</p>
              <p>{phone}</p>
            </>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const status =
            value === 0
              ? "Unverified"
              : value === 1
              ? "Verified"
              : value === 2
              ? "Blocked"
              : value === 3
              ? "Deactivated"
              : "Unknown Status";
          const bgColor =
            value === 0
              ? "#f5ae49"
              : value === 1
              ? "#28A745"
              : value === 2
              ? "#DC3545"
              : value === 3
              ? "#6C757D"
              : "#007BFF";

          return (
            <button
              style={{
                backgroundColor: bgColor,
                padding: "5px 10px",
                color: "#fff",
                borderRadius: "4px",
                border: "none",
              }}
            >
              {status}
            </button>
          );
        },
      },
      {
        Header: "Total Products",
        Footer: "Total Products",
        accessor: "products_count",
      },

      {
        Header: "Total Order",
        Footer: "Total Order",
        accessor: "orders_count",
      },

      {
        Header: "Joined At",
        Footer: "Joined At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Updated At",
        Footer: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => (
          <>{<div>{formatDateTime(value)}</div>}</>
        ),
      },
      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => (
          <button
            onClick={() => {
              goToAccountDetailsPage(
                row.original.id,
                row.original.f_name,
                row.original.l_name
              );
            }}
            className="action-icon eye-button"
            title="View"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: NumberOfSellerPerPage,
        globalFilter: "",
      },

      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">{sellerUserType} Sellers</li>
        </div>
      </div>

      <div className="page-container">
        {/* Data Counter */}
        <div
          className="data-counter"
          style={{
            display: sellerUserType !== "All" ? "none" : "",
          }}
        >
          <div className="counter-item">
            <p>All Sellers</p>
            <p>{sellerCount.all}</p>
          </div>
          <div className="counter-item">
            <p>Unverified Sellers</p>
            <p>{sellerCount.unverified}</p>
          </div>
          <div className="counter-item">
            <p>Verified Sellers</p>
            <p>{sellerCount.verified}</p>
          </div>
          <div className="counter-item">
            <p>Blocked Sellers</p>
            <p>{sellerCount.blocked}</p>
          </div>
          <div className="counter-item">
            <p>Deactivated Sellers</p>
            <p>{sellerCount.deactivated}</p>
          </div>
        </div>

        {/* Filter and Button */}
        <div className="sellerList-table-serach-area">
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApply(true);
              }}
            />
          </div>
        </div>

        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th style={{ width: column.width }}>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound"> No data</div>
                  </td>
                </tr>
              )}
            </tbody>

            {/* from here the footer of react-table starts */}
          </table>
        </div>

        {/* Pagination */}

        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={" Sellers"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerList;
