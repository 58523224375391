import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getDataFromCookies } from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import "../../../Assets/style.css";
import "cropperjs/dist/cropper.css";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgHost from "../../../Components/ReUsed";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;

const SellerProductEdit = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { showToast } = Toast();
  const token = getDataFromCookies("7b9KpQ3rW6");

  // State for product and variants
  const [product, setProduct] = useState({
    name: "",
    status: "",
    description: "",
    category: "",
    brand: "",
    condition: "",
    product_price: "",
    current_market_price: "",
    age_group: "",
    thumb_image: null,
    video_url: "",
    platform_charge_type: "",
    platform_charge: "",
    gst: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    warehouse: "",
    is_donatable: 0,
    is_negotiable: 0,
    sku: "",
    barcode: "",
    status: "",
    hsn: "",
    denied_note: "",
    meta_title: "",
    meta_description: "",
  });
  const [openSeoEdit, setOpenSeoEdit] = useState(false);
  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);
  const [variantsList, setVariantsList] = useState([]);
  const [variantsProductList, setVariantsProductList] = useState([]);

  // Get product details and variants in one API call
  const fetchProductAndVariants = async (productId) => {
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    try {
      const productDetailsResponse = await fetch(
        `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const variantsResponse = await fetch(
        `${API_HOST}/api/admin/product/variants`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Wait for both API calls to finish
      const [productRes, variantsRes] = await Promise.all([
        productDetailsResponse.json(),
        variantsResponse.json(),
      ]);

      if (productRes.status === 200 && variantsRes.status === 200) {
        // Set product and variant data
        const productData = productRes.data;
        const variantData = variantsRes.data;

        setProduct(productData);
        setVariantsList(variantData);

        // Process stock data if available
        if (productData.stocks && productData.stocks.length > 0) {
          setVariantsProductList([]);
          productData.stocks.forEach(async (product, index) => {
            await addNewVariant();

            updateVariantField(index, "stock_id", product.id);
            updateVariantField(index, "quantity", product.quantity);
            updateVariantField(index, "video_url", product.video_url);
            updateVariantField(index, "product_price", product.product_price);
            updateVariantField(
              index,
              "current_market_price",
              product.current_market_price
            );
            updateVariantField(index, "sku", product.sku);
            updateVariantField(index, "hsn", product.hsn);
            updateVariantField(index, "barcode", product.barcode);
            updateVariantField(index, "selling_price", product.selling_price);

            // Update images
            updateVariantField(index, "thumb_image", product.thumb_image);
            updateVariantField(index, "front_image", product.images_url[0]);
            updateVariantField(index, "left_image", product.images_url[1]);
            updateVariantField(index, "right_image", product.images_url[2]);
            updateVariantField(index, "back_image", product.images_url[3]);
            updateVariantField(index, "top_image", product.images_url[4]);

            if (product.variant_id && product.variant_value_id) {
              getSelectedVarient(
                index,
                product.variant_id,
                product.variant_value_id
              );
            }
          });
        }
      } else {
        showToast("info", "Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Selected variants
  const getSelectedVarient = (index, variant_id, variant_value_id) => {
    variantsList.forEach((variant) => {
      if (variant.id === variant_id) {
        updateVariantField(index, "selectedVariant", variant);
        variant.variant_values.forEach((value) => {
          if (value.id === variant_value_id) {
            updateVariantField(index, "variant_values", value);
          }
        });
      }
    });
  };

  // Add New Variant
  const addNewVariant = () => {
    return new Promise((resolve) => {
      let newVariant = new VariantProduct();
      setVariantsProductList((prevVariants) => {
        const updatedVariants = [...prevVariants, newVariant];
        resolve(updatedVariants); // Resolve the promise with the updated variants
        return updatedVariants;
      });
    });
  };

  // Update New Variant
  const updateVariantField = (index, field, value) => {
    return new Promise((resolve) => {
      setVariantsProductList((prevVariants) => {
        const updatedVariants = [...prevVariants];
        if (updatedVariants[index]) {
          updatedVariants[index][field] = value;
        }
        return updatedVariants;
      });
      // Resolve the promise after the state update is completed
      resolve();
    });
  };

  // useEffect to fetch data when component mounts
  useEffect(() => {
    const urls = slug.split("-");
    const productId = urls[urls.length - 2];
    fetchProductAndVariants(productId);
  }, [slug]);

  // Helper: Generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Helper: Remove HTML tags from string
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  class VariantProduct {
    constructor(
      variantListVisible = false,
      variantValuesListVisible = false,
      stock_id = null,
      selectedVariant = null,
      variant_values = null,
      product_price = null,
      current_market_price = null,
      selling_price = null,
      thumb_image = null,
      front_image = null,
      left_image = null,
      right_image = null,
      back_image = null,
      top_image = null,
      video_url = null,
      sku = null,
      hsn = null,
      barcode = null,
      quantity = null,
    ) {
      this.variantListVisible = variantListVisible;
      this.selectedVariant = selectedVariant;
      this.stock_id = stock_id;
      this.variantValuesListVisible = variantValuesListVisible;
      this.variant_values = variant_values;
      this.product_price = product_price;
      this.current_market_price = current_market_price;
      this.thumb_image = thumb_image;
      this.video_url = video_url;
      this.sku = sku;
      this.hsn = hsn;
      this.selling_price = selling_price;
      this.barcode = barcode;
      this.quantity = quantity;
      this.front_image = front_image;
      this.left_image = left_image;
      this.right_image = right_image;
      this.back_image = back_image;
      this.top_image = top_image;
    }
  }

  return (
    <div className="page-data">
      <div className="page-heading" style={{ marginBottom: "25px" }}>
        <div className="breadcrumb">
          <li
            onClick={() => {
              window.history.back();
            }}
          >
            Products
          </li>
          <li className="active">View Product</li>
        </div>
        <button
          type="button"
          onClick={() => window.history.back()}
          className="primary-btn"
        >
          Product List
        </button>
      </div>

      {/* Product read-only note */}
      {product &&
        (product.status === "Hold" ||
          product.status === "Sold" ||
          product.status === "Unavailable" ||
          product.status === "Donated") && (
          <div className="alert primary-alert">
            You can't save your changes right now because the product details is
            in read-only mode.
          </div>
        )}

      {/* Product denied note */}
      {product.denied_note && product.status === "Unavailable" && (
        <div className="alert danger-alert">{product.denied_note}</div>
      )}

      <div className="page-container">
        <div className="page-content">
          <div className="left-area">
            {/* name, description */}
            <div className="small-box">
              <label>
                Product Name <span className="red">*</span>
              </label>
              <input
                disabled={true}
                type="text"
                name="name"
                maxLength="150"
                value={product.name}
                className="form-control"
              />
              <label className="mt-10">Description </label>
              <ReactQuill
                readOnly
                disabled={true}
                name="description"
                value={product.description}
                theme="snow"
                style={{ height: "fitContent" }}
                className="custom-react-quill not_allowed"
              />
            </div>

            {/* GST, Platform Charge Type and Value */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    GST Percentage <span className="red">*</span>
                  </label>
                  <input
                  disabled={true}
                    value={product.gst}
                    name="gst"
                    className="form-control gst-percentag not_allowed"
                  >
                    {/* <option value="">{product.gst}</option> */}
                  </input>
                </div>
                <div>
                  <label>
                    Platform Charges{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div className="platform-charge-area not_allowed">
                    <input
                      value={product.platform_charge_type}
                      disabled={true}
                      name="platform_charge_type"
                      className="form-control platform-charge-type"
                    >
                    </input>
                    <input
                     disabled={true}
                      value={product.platform_charge}
                      name="platform_charge"
                      type="text"
                      className="form-control platform-charge not_allowed"
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Product Address{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    className="form-control"
                    disabled={true}
                    name="product_address"
                    value={product.product_address}
                  >
                    {/* <option value="">{product.product_address}</option> */}
                  </input>
                </div>

                <div>
                  <label>
                    Warehouse <span className="red">*</span>
                  </label>
                  <input
                   disabled={true}
                    className="form-control warehouse-select not_allowed"
                    name="warehouse_id"
                    value={product.warehouse ? product.warehouse : "" }
                  >
                  </input>
                </div>

                <div>
                  <label>HSN</label>
                  <input
                    disabled={true}
                    type="text"
                    name="hsn"
                    className="form-control not_allowed"
                    value={product.hsn}
                  />
                </div>
              </div>
            </div>

            {variantsProductList &&
              variantsProductList.map((products, index) => {
                return (
                  <div key={index}>
                    {products && (
                      <div className="small-box">
                        <div className="product-images">
                          <div>
                            <label>
                              Thumbnail{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              className={
                                "image-dropzone " +
                                (products.thumb_image && "dropzone-uploaded")
                              }
                            >
                              {products.thumb_image ? (
                                <>
                                  <img
                                   disabled={true}
                                    className="product-image not_allowed"
                                    src={
                                      products.thumb_image instanceof File
                                        ? URL.createObjectURL(
                                            products.thumb_image
                                          )
                                        : `${imgHost}/${products.thumb_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns not_allowed">
                                      <button
                                        type="button"
                                        className="image-edit-icon not_allowed"
                                        disabled={true}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>
                                      <button
                                        type="button"
                                        className="image-remove-icon not_allowed"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_thumb_image`}
                                id={`${index}_thumb_image`}
                                type="file"
                                disabled={true}
                                accept=".png, .jpg, .jpeg, .webp"
                                name="thumb_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "thumb_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "thumb_image", "");
                                }}
                                hidden
                              />
                            </div>

                            <label
                              id={`product_thumb_image_error_${index}`}
                              className="red"
                            >
                              {products.thumb_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Front View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              id={`font_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.front_image && "dropzone-uploaded")
                              }
                            >
                              {products.front_image ? (
                                <>
                                  <img
                                    className="product-image not_allowed"
                                    disabled={true}

                                    src={
                                      products.front_image instanceof File
                                        ? URL.createObjectURL(
                                            products.front_image
                                          )
                                        : `${imgHost}/${products.front_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      <button
                                        type="button"
                                        className="image-edit-icon"
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_front_image`}
                                id={`${index}_front_image`}
                                type="file"
                                disabled={true}
                                accept=".png, .jpg, .jpeg, .webp"
                                name="front_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "front_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "front_image", "");
                                }}
                                hidden
                              />
                            </div>

                            <label className="red">
                              {products.front_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Left View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              id={`left_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.left_image && "dropzone-uploaded")
                              }
                            >
                              {products.left_image ? (
                                <>
                                  <img
                                    className="product-image not_allowed"
                                    disabled={true}
                                    src={
                                      products.left_image instanceof File
                                        ? URL.createObjectURL(
                                            products.left_image
                                          )
                                        : `${imgHost}/${products.left_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      <button
                                        type="button"
                                        className="image-edit-icon"
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_left_image`}
                                id={`${index}_left_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="left_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "left_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "left_image", "");
                                }}
                                hidden
                              />
                            </div>
                            <label
                              id={`product_left_image_error_${index}`}
                              className="red"
                            >
                              {products.left_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Right View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              id={`right_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.right_image && "dropzone-uploaded")
                              }
                            >
                              {products.right_image ? (
                                <>
                                  <img
                                    className="product-image not_allowed"
                                    src={
                                      products.right_image instanceof File
                                        ? URL.createObjectURL(
                                            products.right_image
                                          )
                                        : `${imgHost}/${products.right_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      <button
                                        type="button"
                                        className="image-edit-icon"
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>
                                      {/* )} */}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_right_image`}
                                id={`${index}_right_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="right_image"
                                hidden
                              />
                            </div>
                            <label
                              id={`product_right_image_error_${index}`}
                              className="red"
                            >
                              {products.right_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Back View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              id={`Back_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.back_image && "dropzone-uploaded")
                              }
                            >
                              {products.back_image ? (
                                <>
                                  <img
                                    className="product-image not_allowed"
                                    src={
                                      products.back_image instanceof File
                                        ? URL.createObjectURL(
                                            products.back_image
                                          )
                                        : `${imgHost}/${products.back_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      <button
                                        type="button"
                                        className="image-edit-icon"
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>
                                      {/* )} */}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_back_image`}
                                id={`${index}_back_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="back_image"
                                hidden
                              />
                            </div>
                            <label
                              id={`product_back_image_error_${index}`}
                              className="red"
                            >
                              {products.back_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Top View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              id={`top_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.top_image && "dropzone-uploaded")
                              }
                            >
                              {products.top_image ? (
                                <>
                                  <img
                                    className="product-image not_allowed"
                                    src={
                                      products.top_image instanceof File
                                        ? URL.createObjectURL(
                                            products.top_image
                                          )
                                        : `${imgHost}/${products.top_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      <button
                                        type="button"
                                        className="image-edit-icon"
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                        <span>Edit</span>
                                      </button>

                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_top_image`}
                                id={`${index}_top_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="top_image"
                                hidden
                              />
                            </div>
                            <label
                              id={`products_top_image_error_${index}`}
                              className="red"
                            >
                              {products.top_image_error}
                            </label>
                          </div>
                        </div>

                        <div className="video-url not_allowed">
                          <label className="mt-10">Video URL</label>
                          <input
                          disabled={true}
                            type="text"
                            name="video_url"
                            value={products.video_url}
                            className="form-control not_allowed"
                          />
                        </div>

                        <div
                          className="multi-input-row"
                          style={{ marginTop: "15px" }}
                        >
                          <div>
                            <label>
                              Product Price (₹){" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              name="product_price"
                              min="100"
                              disabled={true}
                              value={products.product_price}
                              readOnly={product && product.is_negotiable === 0}
                              onChange={(e) => {
                                if (product && product.is_negotiable === 1) {
                                  let value = e.target.value;
                                  if (!/^\d*$/.test(value)) {
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      "Only numbers are allowed."
                                    );
                                  } else if (value > 100000) {
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      "product price must not be greater than 100,000."
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "product_price",
                                      value.trimStart()
                                    );
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      ""
                                    );
                                  }
                                }
                              }}
                              className="form-control not_allowed"
                            />
                            {products.product_price_error && (
                              <label className="red">
                                {products.product_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Estimated Original Price (₹){" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              name="current_market_price"
                              disabled={true}
                              value={products.current_market_price}
                              readOnly={product && product.is_negotiable === 0}
                              onChange={(e) => {
                                if (product && product.is_negotiable === 1) {
                                  let value = e.target.value;
                                  if (!/^\d*$/.test(value)) {
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      "Only numbers are allowed."
                                    );
                                  } else if (value > 200000) {
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      "Estimated Original price must not be greater than 200,000."
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "current_market_price",
                                      value.trimStart()
                                    );
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      ""
                                    );
                                  }
                                }
                              }}
                              min="200"
                              className="form-control not_allowed"
                            />
                            {products.current_market_price_error && (
                              <label className="red">
                                {products.current_market_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>Selling Price (₹)</label>
                            <div className="selling-price-line">
                              <input
                                disabled={true}
                                readOnly={true}
                                type="number"
                                name="selling_price"
                                value={products.selling_price}
                                className="form-control"
                                style={{ backgroundColor: "#e7e7e7" }}
                              />
                              <button
                                type="button"
                                className={`btn ${"secondary-btn"}`}
                              >
                                Calculate
                              </button>
                            </div>
                          </div>
                          <div>
                            <label>SKU</label>
                            <input
                              type="text"
                              name="sku"
                              disabled={true}
                              readOnly={true}
                              className="form-control"
                              value={products.sku}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                updateVariantField(index, "sku", value);
                                updateVariantField(index, "sku_error", "");
                              }}
                            />
                          </div>
                          <div>
                            <label>Barcode (ISBN, UPC, GTIN, etc.) </label>
                            <input
                              type="text"
                              name="barcode"
                              disabled={true}
                              readOnly={true}
                              className="form-control"
                              value={products.barcode}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                updateVariantField(index, "barcode", value);
                                updateVariantField(index, "barcode_error", "");

                                if (products.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }}
                            />
                          </div>
                          <div>
                            <label>Quantity</label>
                            <input
                              type="text"
                              name="quantity"
                              disabled={true}
                              readOnly={true}
                              className="form-control"
                              value={products.quantity}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                if (/^\d*$/.test(value)) {
                                  updateVariantField(index, "quantity", value);
                                  updateVariantField(
                                    index,
                                    "quantity_error",
                                    ""
                                  );
                                } else {
                                  // Optionally, you can set an error message if needed
                                  updateVariantField(
                                    index,
                                    "quantity_error",
                                    "Please enter a valid number."
                                  );
                                }
                              }}
                            />
                            <label className="red">
                              {products.quantity_error}
                            </label>
                          </div>{" "}
                          <div className="col">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <label>
                                Variant{" "}
                                {product.status ===
                                  "Verified and Published" && (
                                  <span className="red">*</span>
                                )}
                              </label>

                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  className="varientDropDownTitle"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <p>
                                    {products.selectedVariant &&
                                    products.selectedVariant.id
                                      ? products.selectedVariant.name
                                      : "Select"}
                                  </p>{" "}
                                </span>
                              </div>
                              {products.variantListVisible && (
                                <div className="variantDropdownList">
                                  {variantsList.length > 0 &&
                                    variantsList.map((variant) => {
                                      return (
                                        <div
                                          className="variantsListOptions"
                                        >
                                          <input
                                           disabled={true}
                                           readOnly={true}
                                            type="radio"
                                            checked={
                                              products.selectedVariant &&
                                              products.selectedVariant.id &&
                                              products.selectedVariant.id ===
                                                variant.id
                                            }
                                          />
                                          <label htmlFor="">
                                            {variant.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                              {products.selectedVariant_error && (
                                <label className="red">
                                  {products.selectedVariant_error}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <label>
                                Variant Value{" "}
                                {product.status ===
                                  "Verified and Published" && (
                                  <span className="red">*</span>
                                )}
                              </label>
                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  style={{ width: "100%" }}
                                  className="varientDropDownTitle"
                                >
                                  {" "}
                                  <p>
                                    {products.variant_values &&
                                    products.variant_values.value
                                      ? products.variant_values.value
                                      : "Select"}
                                  </p>{" "}
                                </span>
                              </div>
                              {products.variantValuesListVisible && (
                                <div className="variantDropdownList">
                                  {products.selectedVariant ? (
                                    <>
                                      {products.selectedVariant
                                        .variant_values &&
                                        products.selectedVariant.variant_values
                                          .length > 0 && (
                                          <>
                                            {products.selectedVariant.variant_values.map(
                                              (variantValue) => {
                                                return (
                                                  <div
                                                    className="variantsListOptions"
                                                    
                                                  >
                                                    <input
                                                      type="radio"
                                                      disabled={true}
                                                      readOnly={true}
                                                      checked={
                                                        products.variant_values &&
                                                        products.variant_values
                                                          .id === variantValue.id
                                                      }
                                                    />
                                                    <label htmlFor="">
                                                      {variantValue.value}
                                                    </label>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </>
                                  ) : (
                                    <label>Please select a variant</label>
                                  )}
                                </div>
                              )}

                              {product.variant_values_error && (
                                <label className="red">
                                  {products.variant_values_error}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

            {/* Length, Width, Height, Weight */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>Box Length (cm) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="length"
                    value={product.length}
                    className="form-control"
                    step="0.01"
                  />
                </div>
                <div>
                  <label>
                    Box width (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    name="width"
                    value={product.width}
                    className="form-control"
                    step="0.01"
                  />
                </div>
                <div>
                  <label>Box height (cm) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="height"
                    value={product.height}
                    className="form-control"
                  />
                </div>
                <div>
                  <label>Weight (kg) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="weight"
                    value={product.weight}
                    className="form-control"
                    step="0.01"
                  />
                </div>
              </div>
            </div>

            {/* Product SEO */}
            <div className="small-box">
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    className="form-control not_allowed"
                    type="text"
                    disabled={true}
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    disabled={true}
                    name="meta_description"
                    className="form-control meta-description-input not_allowed"
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="right-area">
            <div className="small-box">
              <label>
                Status <span className="red">*</span>
              </label>
              <input
                disabled={true}
                name="status"
                className="form-control not_allowed padding_remove"
                value={product.status}
              >
              </input>
            </div>
            <div className="small-box">
              <label>Category </label>
              <input
                disabled={true}
                className="form-control not_allowed padding_remove"
                name="category_id"
                value={product.category.name}
              >
              </input>
              <label className="mt-10">Brand</label>
              <input
                disabled={true}
                className="form-control not_allowed padding_remove"
                name="brand_id"
                value={product.brand.name}
              >
              </input>
              <label className="mt-10">Condition </label>
              <input
                disabled={true}
                name="condition"
                value={product.condition}
                className="form-control not_allowed padding_remove"
              >
              </input>
              <div>
                <label className="mt-10">Applicable Age Group </label>
                <input
                  disabled={true}
                  className="form-control not_allowed padding_remove"
                  name="age_group_id"
                  value={product.age_group ? product.age_group.title : ""}
                >
                </input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerProductEdit;
