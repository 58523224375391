import React, { useEffect, useState, useRef, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import Alert from "../../../config/ProjectConstants.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import "../../../Assets/style.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import loadingImgPath from "../../../Assets/rolling.svg";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faSortDown,
  faSortUp,
  faTimes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";
// import constant
import IMG_HOST from "../../../Components/ReUsed";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;
const SellerProductNewEdit = ({ page }) => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { showToast } = Toast();
  const [isLoading, setIsLoading] = useState(false);
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [productRejectModal, setProductRejectModal] = useState(false);
  const [disableCalculate, setDisableCalculate] = useState(true);
  const [openSeoEdit, setOpenSeoEdit] = useState(false);
  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);
  const [enableWarehouse, setEnableWarehouse] = useState(false);
  let [variantsProductList, setVariantsProductList] = useState([]);
  const [prodImageToEdit, setProdImageToEdit] = useState({
    imgFile: null,
    imgName: null,
    imgNameError: null,
    index: null,
  });
  let [variantsList, setVariantsList] = useState([]);
  // Replace blank spaces with "&nbsp;" and line breaks with "<br>"
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
    modal: false,
    variantIndex: null,
  });
  const [pageName, setPageName] = useState("");
  const [productId, setProductId] = useState("");
  const [sellerData, setSellerData] = useState("");
  const [product, setProduct] = useState({
    name: "",
    status: "",
    description: "",
    category_id: "",
    brand_id: "",
    condition: "",
    product_price: "",
    current_market_price: "",
    age_group_id: "",
    thumb_image: null,
    video_url: "",
    platform_charge_type: "",
    platform_charge: "",
    gst: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    product_address: "",
    warehouse_id: "",
    is_donatable: 0,
    is_negotiable: 0,
    sku: "",
    barcode: "",
    status: "",
    hsn: "",
    denied_note: "",
    meta_title: "",
    meta_description: "",
  });
  const variantsURL = `${API_HOST}/api/admin/product/variants`;
  const [errors, setErrors] = useState({
    has_error: false,
    name: "",
    description: "",
    sku: "",
    hsn: "",
    barcode: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    product_address: "",
    warehouse_id: "",
    is_negotiable: "",
    is_donatable: "",
    status: "",
    condition: "",
    age_group_id: "",
    brand_id: "",
    category_id: "",
    estimated_shipping_cost: "",
    denied_note: "",
  });

  const convertToAmpersand = (text) => {
    return text.replace(/\s/g, "&nbsp;").replace(/\n/g, "<br>");
  };
  const closeProductRejectModal = () => {
    setProduct((prev) => ({
      ...prev,
      denied_note: "",
    }));
    setErrors((prev) => ({
      ...prev,
      denied_note: "",
    }));
    setEnableRejectBtn(false);
    setProductRejectModal(false);
    setRejectBtnLoading(false);
  };
  // handle selling button
  useEffect(() => {
    if (
      product.product_price !== "" &&
      product.current_market_price !== "" &&
      product.gst !== "" &&
      product.platform_charge_type !== "" &&
      product.platform_charge !== ""
    ) {
      setDisableCalculate(false);
    } else {
      setDisableCalculate(true);
    }
  }, [product]);

  const calculateSellingPrice = async (
    index,
    current_market_price,
    product_price
  ) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    let errorHas = false;
    // product price error
    const productPrice = /^[0-9]+$/;
    const isPrice = productPrice.test(product_price);
    if (product_price === "" || product_price === null) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.required,
      }));
      errorHas = true;
    } else if (product_price !== "" && product_price != null && !isPrice) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product_price !== "" &&
      product_price != null &&
      (product_price > 100000 || product_price < 100)
    ) {
      setErrors((prev) => ({
        ...prev,
        product_price: Alert.valBetween100To100000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        product_price: "",
      }));
    }

    // current_market_price validation
    const marketPrice = /^[0-9]+$/;
    const isOriginalPrice = marketPrice.test(current_market_price);
    if (current_market_price === "" || current_market_price === null) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.required,
      }));
      errorHas = true;
    } else if (
      current_market_price !== "" &&
      current_market_price != null &&
      !isOriginalPrice
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      current_market_price !== "" &&
      current_market_price != null &&
      (current_market_price > 200000 || current_market_price < 200)
    ) {
      setErrors((prev) => ({
        ...prev,
        current_market_price: Alert.valBetween200To200000,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        current_market_price: "",
      }));
    }

    // GST Percentage validation
    if (product.gst === "" || product.gst === null) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (product.platform_charge === "" || product.platform_charge === null) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    if (errorHas === true) return;
    const payload = {
      product_price: product_price,
      current_market_price: current_market_price,
      gst: product.gst,
      platform_charge_type: product.platform_charge_type,
      platform_charge: product.platform_charge,
    };
    setDisableCalculate(true);
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.calculateSellingPrice}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      updateVariantField(index, "selling_price", res.data.selling_price);
    } else if (res.status === 403) {
      setInfoModalOpen(true);
      setModalMessage(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
        showToast("error", errorMessage);
      }
    } else {
      showToast("info", "Something went wrong");
    }
    setDisableCalculate(false);
  };

  // Enable reject button
  const [enableRejectBtn, setEnableRejectBtn] = useState(false);
  useEffect(() => {
    if (product.status === "Rejected" && product.denied_note) {
      setEnableRejectBtn(true);
    } else {
      setEnableRejectBtn(false);
    }
  }, [product]);

  // Enable Add product button
  const [enableAddProductBtn, setEnableAddProductBtn] = useState(false);
  useEffect(() => {
    if (product.status !== "" && product.name !== "") {
      setEnableAddProductBtn(true);
    } else {
      setEnableAddProductBtn(false);
    }
  }, [product]);

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };
  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const getSelectedVarient = (index, variant_id, variant_value_id) => {
    variantsList.forEach((variant) => {
      if (variant.id === variant_id) {
        updateVariantField(index, "selectedVariant", variant);
        variant.variant_values.map((value) => {
          if (value.id === variant_value_id) {
            updateVariantField(index, "variant_values", value);
          }
        });
      }
    });
  };

  const getProductDetails = async (productId) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      const resData = res.data;
      setProduct(resData);
      setProduct((prev) => ({
        ...prev,
        age_group_id: resData.age_group != null ? resData.age_group.id : "",
      }));
      // Process stocks data if available
      if (res.data.stocks && res.data.stocks.length > 0) {
        let stocks = res.data.stocks;
        setVariantsProductList([]);
        stocks.forEach(async (product, index) => {
          await addNewVariant();
          updateVariantField(index, "is_default", product.is_default);
          updateVariantField(index, "stock_id", product.id);
          updateVariantField(index, "quantity", product.quantity);
          updateVariantField(index, "video_url", product.video_url);
          updateVariantField(index, "product_price", product.product_price);
          updateVariantField(
            index,
            "current_market_price",
            product.current_market_price
          );
          updateVariantField(index, "sku", product.sku);
          updateVariantField(index, "hsn", product.hsn);
          updateVariantField(index, "barcode", product.barcode);
          updateVariantField(index, "selling_price", product.selling_price);

          // Update images
          updateVariantField(index, "thumb_image", product.thumb_image);
          updateVariantField(index, "front_image", product.images_url[0]);
          updateVariantField(index, "left_image", product.images_url[1]);
          updateVariantField(index, "right_image", product.images_url[2]);
          updateVariantField(index, "back_image", product.images_url[3]);
          updateVariantField(index, "top_image", product.images_url[4]);

          if (product.variant_id && product.variant_value_id) {
            getSelectedVarient(
              index,
              product.variant_id,
              product.variant_value_id
            );
          }
        });
      }

      if (resData) {
        setProduct(resData);
      }

      if (resData.product_address === "Warehouse") {
        setEnableWarehouse(true);
      }
      if (resData.seller) {
        setSellerData(resData.seller);
      }
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // image handling
  const [showImgUpModal, setShowImgUpModal] = useState(false);
  const [rawImgName, setRawImgName] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const cropperRef = useRef(null);

  const [imgContainer, setImgContainer] = useState({
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  const resetInputType = (imgName) => {
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));

    setProduct((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  const isBase64Image = (value) => {
    return typeof value === "string" && value.startsWith("data:image/");
  };

  const loadImgOnModal = (imgName, img) => {
    setShowImgUpModal(true);
    setRawImgName(imgName);
    setRawImage(URL.createObjectURL(img));
  };

  const convertBase64ToFile = (base64Image, file) => {
    if (base64Image.startsWith("data:image/")) {
      const binaryString = atob(base64Image.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const fileSize = arrayBuffer.byteLength;
      const customFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(customFile, "size", {
        value: fileSize,
      });

      return customFile;
    }
  };

  const cropImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const customFile = convertBase64ToFile(croppedImg, product[rawImgName]);
      if (customFile) {
        updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgName,
          customFile
        );
      } else {
        updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgNameError,
          "Invalid image format"
        );
      }
    }
    // reset modal
    setShowImgUpModal(false);
    setRawImgName("");
    setRawImage(null);
    setProdImageToEdit((prev) => ({
      ...prev,
      imgFile: null,
      imgName: null,
      imgNameError: null,
      index: null,
    }));
  };

  const compressAndResizeImage = (
    file,
    imgName,
    name,
    errorName,
    index,
    stock_id
  ) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        // Set dimensions to 600x600
        canvas.width = 600;
        canvas.height = 600;
        // Calculate scaling factors to fit image within 600x600
        const scaleFactor = Math.min(600 / img.width, 600 / img.height);
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;
        // Draw image on canvas with new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgContainer((prev) => ({
                ...prev,
                [imgName]: reader.result,
              }));
            };
            reader.readAsDataURL(compressedImg);
            updateVariantField(index, name, compressedImg);
            updateVariantField(index, errorName, "");
            if (page === "edit") {
              if (stock_id !== null) {
                updateVariantField(index, "update_stock", true);
              } else {
                updateVariantField(index, "new_stock", true);
              }
            }
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // image upload functions start here
  const handleImageUpload = (imgName, name, errorName, index) => {
    const ele = document.getElementById(imgName);
    ele.click();

    ele.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (file === undefined) return;

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        updateVariantField(
          index,
          errorName,
          "Please upload an image with .png, .jpg, .jpeg, or .webp extension."
        );
        resetInputType(imgName);
        // alert("returning !allowedExtensions");
        return;
      }
      // Compress and resize image
      compressAndResizeImage(file, imgName, name, errorName, index);
    });
  };
  // Featch Category API TO show List of All Category Element;
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setCategoryData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Brand API TO show List of All Brand List;
  const [brandData, setBrandData] = useState([]);
  const getAllBrandList = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    await fetch(`${API_HOST}/${APIEndpoint.getBrandsList}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setBrandData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [conditionData, setConditionData] = useState([]);

  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/api/conditions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(`${API_HOST}/api/applicable-age-groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    }
  };

  const [gstPercentage, setGstPercentage] = useState([]);
  const getGSTpercentage = async () => {
    await fetch(`${API_HOST}/api/gst-percents`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGstPercentage(data.data);
      })
      .catch((err) => console.error(err));
  };

  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // featch Variant data
  const fetchVariantsData = (url) => {
    if (!token) {
      navigate(`${Navigation.login}`);
      return;
    }

    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          variantsList = data;
          setVariantsList(data);
        } else {
          console.error(res);
        }
      })
      .then(async () => {
        if (page === "edit") {
          setPageName("Edit");

          const urls = slug.split("-");
          const productId = urls[urls.length - 2];
          setProductId(productId);
          getProductDetails(productId);
          await getProductDetails(productId);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getAllCategoriesData();
    getAllBrandList();
    getAllConditionsList();
    getAllAplplicableAgeGroupList();
    getGSTpercentage();
    getWereHouseAddress();
    fetchVariantsData(variantsURL);
  }, []);

  const [imagesURL, setImagesURL] = useState({
    font_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  // This API Fetching for the Verifying the Products.
  const verifyAndPublishProduct = async (formData) => {
    setIsLoading(true);

    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    formData.append("_method", "PUT");
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", "Product updated successfully");
      window.history.back();
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired! Please login");
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 400) {
      if (res.message && res.message.includes("name")) {
        setErrors((prev) => ({
          ...prev,
          name: res.message,
        }));
      } else if (res.message && res.message.includes("sku")) {
        setErrors((prev) => ({
          ...prev,
          sku: res.message,
        }));
      } else {
        const errors = res.errors;
        let errorMessage = "";
        if (errors) {
          Object.keys(errors).forEach((key) => {
            errors[key].forEach((message) => {
              errorMessage = message;
            });
          });
        }
        showToast("error", errorMessage);
      }
    } else if (res.status === 403) {
      setInfoModalOpen(true);
      setModalMessage(res.message);
    } else {
      showToast("error", "Something went wrong!");
    }
    setIsLoading(false);
    setEnableAddProductBtn(true);
  };

  // Rejected the product
  const rejectedProduct = async () => {
    setRejectBtnLoading(true);
    setEnableRejectBtn(false);
    let error = false;
    if (product.denied_note.length < 10) {
      setErrors((prev) => ({
        ...prev,
        denied_note: "Minimum 10 characters are required.",
      }));
      error = true;
    }

    if (error) {
      setEnableRejectBtn(true);
      setRejectBtnLoading(false);
      return;
    }
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const payload = {
      id: productId,
      status: "Rejected",
      denied_note: product.denied_note,
    };

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,

      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", res.message);
      window.history.back();
    } else if (
      response.status === 401 &&
      response.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (response.status === 401 && response.message !== "") {
      showToast("error", response.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setEnableRejectBtn(true);
    setRejectBtnLoading(false);
  };

  const handleButtonSubmit = async () => {
    setEnableAddProductBtn(false);
    let errorHas = false;
    if (product.name === "" || product.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.required,
      }));
      errorHas = true;
    } else if (product.name.length > 150 || product.name.length < 3) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.charBetween3To150,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        name: "",
      }));
    }

    // Description validation
    const emptyHTMLTagsFound = (input) => {
      if (
        /^<\w+><br><\/\w+>/.test(input) ||
        /<\w+><br><\/\w+>$/.test(input) ||
        /^<\w+>\s*<\/\w+>/.test(input)
      ) {
        return true;
      }
      return false;
    };
    if (
      product.status !== "Rejected" &&
      emptyHTMLTagsFound(product.description)
    ) {
      setErrors((prev) => ({
        ...prev,
        description: "Leading / trailing whitespace not allowed",
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        description: "",
      }));
    }

    if (product.status === "Verified and Published") {
      variantsProductList &&
        variantsProductList.forEach((product, index) => {
          if (
            product.thumb_image === "null" ||
            product.thumb_image === null ||
            product.thumb_image === undefined
          ) {
            updateVariantField(index, "thumb_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "thumb_image_error", "");
          }
          if (
            product.front_image === "null" ||
            product.front_image === null ||
            product.front_image === undefined
          ) {
            updateVariantField(index, "front_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "front_image_error", "");
          }
          if (
            product.left_image === "null" ||
            product.left_image === null ||
            product.left_image === undefined
          ) {
            updateVariantField(index, "left_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "left_image_error", "");
          }
          if (
            product.right_image === "null" ||
            product.right_image === null ||
            product.right_image === undefined
          ) {
            updateVariantField(index, "right_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "right_image_error", "");
          }
          if (
            product.back_image === "null" ||
            product.back_image === null ||
            product.back_image === undefined
          ) {
            updateVariantField(index, "back_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "back_image_error", "");
          }
          if (
            product.top_image === "null" ||
            product.top_image === null ||
            product.top_image === undefined
          ) {
            updateVariantField(index, "top_image_error", Alert.required);
            errorHas = true;
          } else {
            updateVariantField(index, "top_image_error", "");
          }

          const productPrice = /^[0-9]+$/;
          const isPrice = productPrice.test(product.product_price);
          if (product.product_price === "" || product.product_price === null) {
            updateVariantField(index, "product_price_error", Alert.required);
            errorHas = true;
          } else if (
            product.product_price !== "" &&
            product.product_price != null &&
            !isPrice
          ) {
            updateVariantField(
              index,
              "product_price_error",
              Alert.invalidFormat
            );
            errorHas = true;
          } else if (
            product.product_price !== "" &&
            product.product_price != null &&
            (product.product_price > 100000 || product.product_price < 100)
          ) {
            updateVariantField(
              index,
              "product_price_error",
              Alert.valBetween100To100000
            );
            errorHas = true;
          } else {
            updateVariantField(index, "product_price_error", "");
          }

          const marketPrice = /^[0-9]+$/;
          const isOriginalPrice = marketPrice.test(
            product.current_market_price
          );
          if (
            product.current_market_price === "" ||
            product.current_market_price === null
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.required
            );
            errorHas = true;
          } else if (
            product.current_market_price !== "" &&
            product.current_market_price != null &&
            !isOriginalPrice
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.invalidFormat
            );
            errorHas = true;
          } else if (
            product.current_market_price !== "" &&
            product.current_market_price != null &&
            (product.current_market_price > 200000 ||
              product.current_market_price < 200)
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.valBetween200To200000
            );
            errorHas = true;
          } else {
            updateVariantField(index, "current_market_price_error", "");
          }
        });
    }
    // gst validation
    if (
      product.status === "Verified and Published" &&
      (product.gst === "" || product.gst === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        gst: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        gst: "",
      }));
    }

    // platform charges validation
    const productPlatformCharge = /^[0-9]\d*$/;
    const isCharge = productPlatformCharge.test(product.platform_charge);
    if (
      product.status === "Verified and Published" &&
      (product.platform_charge === "" ||
        product.platform_charge === null ||
        product.platform_charge_type === "" ||
        product.platform_charge_type === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.platform_charge !== "" &&
      product.platform_charge != null &&
      !isCharge
    ) {
      setErrors((prev) => ({
        ...prev,
        platform_charge: Alert.invalidFormat,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        platform_charge: "",
      }));
    }

    // Warehouse validation
    if (
      enableWarehouse &&
      (product.warehouse_id === null || product.warehouse_id === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        warehouse_id: "",
      }));
    }

    // length error
    const isValidNumeric = (value) => {
      const numeric = /^[0-9]+$/;
      const isValid = numeric.test(value);
      return isValid;
    };

    if (
      product.status === "Verified and Published" &&
      (product.length === "" || product.length === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.length !== "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    // width error
    if (
      product.status === "Verified and Published" &&
      (product.width === "" || product.width === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric(product.width)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.width !== "" &&
      product.width != null &&
      !isValidNumeric &&
      (product.width === 0 ||
        product.width === "0" ||
        product.width < 0.1 ||
        product.width > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        width: "",
      }));
    }

    // height error
    if (
      product.status === "Verified and Published" &&
      (product.height === "" || product.height === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      !isValidNumeric(product.height)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.height !== "" &&
      product.height != null &&
      (product.height === 0 ||
        product.height === "0" ||
        product.height < 0.1 ||
        product.height > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        height: "",
      }));
    }

    // weight validation
    if (
      product.status === "Verified and Published" &&
      (product.weight === "" || product.weight === null)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.required,
      }));
      errorHas = true;
    } else if (
      product.weight !== "" &&
      product.weight != null &&
      (product.weight === 0 || product.weight === "0" || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.valBetweenPoint1To20,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        weight: "",
      }));
    }

    // category
    if (
      product.status === "Verified and Published" &&
      product.category_id === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        category_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        category_id: "",
      }));
    }

    // Condition
    if (
      product.status === "Verified and Published" &&
      product.condition === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        condition: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        condition: "",
      }));
    }

    // Age group validation
    if (
      product.status === "Verified and Published" &&
      product.age_group_id === ""
    ) {
      setErrors((prev) => ({
        ...prev,
        age_group_id: Alert.required,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        age_group_id: "",
      }));
    }

    if (errorHas === true) {
      setEnableAddProductBtn(true);
      return;
    }

    const formData = new FormData();
    // Add meta title and description
    formData.append("product_update", 1);
    if (product.meta_title) {
      formData.append("meta_title", product.meta_title);
    } else {
      formData.append(
        "meta_title",
        product.name.substring(0, metaTitleMaxLength)
      );
    }
    if (product.meta_description) {
      formData.append("meta_description", product.meta_description);
    } else if (product.description) {
      formData.append("meta_description", removeHtmlTags(product.description));
    }

    // mandatory fields

    formData.append("name", product.name);
    formData.append("status", product.status);

    // Optional fields
    if (product.category_id) {
      formData.append("category_id", product.category_id);
    }
    if (product.condition) {
      formData.append("condition", product.condition);
    }
    if (product.product_price) {
      formData.append("product_price", product.product_price);
    }
    if (product.age_group_id) {
      formData.append("age_group_id", product.age_group_id);
    }
    // if (product.thumb_image) {
    //   formData.append("thumb_image", product.thumb_image);
    // }
    if (product.length) {
      formData.append("length", product.length);
    }
    if (product.width) {
      formData.append("width", product.width);
    }
    if (product.height) {
      formData.append("height", product.height);
    }
    if (product.weight) {
      formData.append("weight", product.weight);
    }
    if (product.is_donatable) {
      formData.append("is_donatable", product.is_donatable);
    }
    if (product.is_negotiable) {
      formData.append("is_negotiable", product.is_negotiable);
    }
    if (product.gst >= "0") {
      formData.append("gst", product.gst);
    }
    if (product.platform_charge_type) {
      formData.append("platform_charge_type", product.platform_charge_type);
    }
    if (product.platform_charge) {
      formData.append("platform_charge", product.platform_charge);
    }
    if (product.product_address) {
      formData.append("product_address", product.product_address);
    }
    if (product.product_address && product.product_address === "Warehouse") {
      formData.append("warehouse_id", product.warehouse_id);
    }
    if (product.hsn) {
      formData.append("hsn", product.hsn);
    }
    if (product.description) {
      const descriptionWithAmpersand = convertToAmpersand(product.description);
      formData.append("description", descriptionWithAmpersand);
    }
    if (product.brand_id) {
      formData.append("brand_id", product.brand_id);
    }
    let stock_update = 0;
    let stock_add = 0;
    let title = "";
    let count = 0;
    variantsProductList &&
      variantsProductList.forEach((variant) => {
        if (variant.new_stock === true || variant.update_stock === true) {
          if (variant.new_stock === true) {
            stock_add = 1;
            title = `new_stock[${count}]`;
          }
          if (variant.update_stock === true) {
            stock_update = 1;
            title = `stocks[${variant.stock_id}]`;
          }

          if (variant.selectedVariant) {
            formData.append(
              `${title}[variant_id]`,
              variant.selectedVariant.id ? variant.selectedVariant.id : ""
            );
            if (variant.variant_values) {
              formData.append(
                `${title}[variant_value_id]`,
                variant.variant_values.id ? variant.variant_values.id : ""
              );
            }
          }
          formData.append(`${title}[is_default]`, variant.is_default);
          formData.append(`${title}[stock_id]`, variant.stock_id);
          formData.append(`${title}[quantity]`, variant.quantity);
          formData.append(
            `${title}[product_price]`,
            variant.product_price ? variant.product_price : ""
          );
          formData.append(
            `${title}[current_market_price]`,
            variant.current_market_price ? variant.current_market_price : ""
          );

          formData.append(
            `${title}[video_url]`,
            variant.video_url ? variant.video_url : ""
          );
          formData.append(`${title}[sku]`, variant.sku ? variant.sku : "");
          formData.append(
            `${title}[barcode]`,
            variant.barcode ? variant.barcode : ""
          );
          formData.append(`${title}[thumb_image]`, variant.thumb_image);
          formData.append(`${title}[front_image]`, variant.front_image);
          formData.append(`${title}[left_image]`, variant.left_image);
          formData.append(`${title}[right_image]`, variant.right_image);
          formData.append(`${title}[back_image]`, variant.back_image);
          formData.append(`${title}[top_image]`, variant.top_image);

          if (variant.new_stock === true) {
            count++;
          }
        }
      });

    formData.append("stock_update", stock_update);
    formData.append("stock_add", stock_add);

    if (
      product.status === "Verified and Published" ||
      product.status === "Unverified"
    ) {
      verifyAndPublishProduct(formData);
    } else if (product.status === "Rejected") {
      setProductRejectModal(true);
      setEnableAddProductBtn(true);
      setRejectBtnLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "thumb_image") {
      const file = event.target.files[0];
      setProduct((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (
      name === "font_image" ||
      name === "left_image" ||
      name === "right_image" ||
      name === "back_image" ||
      name === "top_image"
    ) {
      const file = event.target.files[0];
      setImagesURL((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setProduct((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
    if (name === "platform_charge_type" && value !== "") {
      setErrors((prevData) => ({
        ...prevData,
        platform_charge: "",
      }));
    }
  };

  const handleDescriptionChange = (value) => {
    setProduct((prev) => ({
      ...prev,
      description: value,
    }));
    setErrors((prev) => ({
      ...prev,
      description: "",
    }));
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const cancelRemoveVariant = () => {
    setShowRemoveConfirmation((prev) => ({
      ...prev,
      modal: false,
      variantIndex: null,
    }));
  };

  const filterVariant = (index) => {
    setVariantsProductList((prevVariants) =>
      prevVariants.filter((_, i) => i !== index)
    );
    cancelRemoveVariant();
  };

  // variant
  const checkVariantExist = (variant_index, variant_value) => {
    variantsProductList &&
      variantsProductList.length > 1 &&
      variantsProductList.forEach((variant, index) => {
        if (
          variant_index !== index &&
          variant_value.variant_id === variant.variant_values.variant_id &&
          variant_value.id === variant.variant_values.id
        ) {
          updateVariantField(
            variant_index,
            "variant_values_error",
            "This variant already exists."
          );
        }
      });
  };
  class VariantProduct {
    constructor(
      is_default = 0,
      variantListVisible = false,
      variantValuesListVisible = false,
      stock_id = null,
      selectedVariant = null,
      variant_values = null,
      product_price = null,
      current_market_price = null,
      selling_price = null,
      thumb_image = null,
      front_image = null,
      left_image = null,
      right_image = null,
      back_image = null,
      top_image = null,
      video_url = null,
      sku = null,
      hsn = null,
      barcode = null,
      quantity = null,
      product_price_as_first = false,
      current_market_price_as_first = false,
      selectedVariant_error = null,
      variant_values_error = null,
      product_price_error = null,
      current_market_price_error = null,
      thumb_image_error = null,
      front_image_error = null,
      left_image_error = null,
      right_image_error = null,
      back_image_error = null,
      top_image_error = null,
      video_url_error = null,
      sku_error = null,
      hsn_error = null,
      barcode_error = null,
      quantity_error = null
    ) {
      this.variantListVisible = variantListVisible;
      this.selectedVariant = selectedVariant;
      this.stock_id = stock_id;
      this.variantValuesListVisible = variantValuesListVisible;
      this.variant_values = variant_values;
      this.product_price = product_price;
      this.current_market_price = current_market_price;
      this.thumb_image = thumb_image;
      this.video_url = video_url;
      this.sku = sku;
      this.hsn = hsn;
      this.selling_price = selling_price;
      this.barcode = barcode;
      this.quantity = quantity;
      this.front_image = front_image;
      this.left_image = left_image;
      this.right_image = right_image;
      this.back_image = back_image;
      this.top_image = top_image;
      this.product_price_as_first = product_price_as_first;
      this.current_market_price_as_first = current_market_price_as_first;
      // errors =======>
      this.selectedVariant_error = selectedVariant_error;
      this.variant_values_error = variant_values_error;
      this.product_price_error = product_price_error;
      this.current_market_price_error = current_market_price_error;
      this.thumb_image_error = thumb_image_error;
      this.front_image_error = front_image_error;
      this.left_image_error = left_image_error;
      this.right_image_error = right_image_error;
      this.back_image_error = back_image_error;
      this.top_image_error = top_image_error;
      this.video_url_error = video_url_error;
      this.sku_error = sku_error;
      this.hsn_error = hsn_error;
      this.barcode_error = barcode_error;
      this.quantity_error = quantity_error;
    }
  }

  // Add New Variant
  const addNewVariant = () => {
    return new Promise((resolve) => {
      let newVariant = new VariantProduct();
      setVariantsProductList((prevVariants) => {
        const updatedVariants = [...prevVariants, newVariant];
        resolve(updatedVariants); // Resolve the promise with the updated variants
        return updatedVariants;
      });
    });
  };

  const markDefaultProduct = (selectedIndex) => {
    variantsProductList &&
      variantsProductList.map((variantsProduct, index) => {
        updateVariantField(
          index,
          "is_default",
          selectedIndex === index ? 1 : 0
        );
      });
  };
  //  Update New Varient
  const updateVariantField = (index, field, value) => {
    return new Promise((resolve) => {
      setVariantsProductList((prevVariants) => {
        const updatedVariants = [...prevVariants];
        if (updatedVariants[index]) {
          updatedVariants[index][field] = value;
        }
        return updatedVariants;
      });
      resolve();
    });
  };

  return (
    <div className="page-data">
      {showRemoveConfirmation.modal && (
        <div className="modal fade">
          <div className="modal-content">
            <button
              type="button"
              className="order modal-close-icon"
              onClick={cancelRemoveVariant}
            >
              &times;
            </button>
            <div className="modal-header">
              <p className="modal-heading">Remove Variant</p>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to remove variant?</p>
            </div>

            <div className="modal-buttons">
              <button
                type="button"
                className="btn secondary-btn"
                onClick={cancelRemoveVariant}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  filterVariant(showRemoveConfirmation.variantIndex);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="page-heading">
        <div className="breadcrumb">
          <li
            onClick={() => {
              navigate(window.history.back());
            }}
          >
            Products
          </li>
          <li className="active">{pageName} Product</li>
        </div>
        <button
          type="button"
          onClick={() => {
            navigate(window.history.back());
          }}
          className="primary-btn"
        >
          Product List
        </button>
      </div>

      {/* Product denied note */}
      {product && product.status === "Rejected" && product.denied_note && (
        <div className="alert danger-alert">
          Rejection Note : {product.denied_note}
        </div>
      )}

      <div className="page-container">
        <div className="page-content">
          <div className="left-area">
            <div className="small-box">
              <label>
                Product Name <span className="red">*</span>
              </label>
              <input
                type="text"
                name="name"
                maxLength="150"
                value={product.name}
                onChange={handleInputChange}
                className="form-control"
              />
              {errors.name && <label className="red">{errors.name}</label>}
              <label className="mt-10">
                Description{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <ReactQuill
                name="description"
                value={product.description}
                onChange={handleDescriptionChange}
                theme="snow"
                style={{ height: "fitContent" }}
                className="custom-react-quill"
              />
              {errors.description && (
                <label className="red">{errors.description}</label>
              )}
            </div>

            {/* GST, Platform Charge Type and Value */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    GST Percentage{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    value={product.gst}
                    name="gst"
                    onChange={handleInputChange}
                    className="form-control gst-percentage"
                  >
                    <option value="">Select</option>
                    {Object.entries(gstPercentage).map(([key, value]) => (
                      <option key={key} value={value}>
                        {`${value}%`}
                      </option>
                    ))}
                  </select>
                  {errors.gst && <label className="red">{errors.gst}</label>}
                </div>
                <div>
                  <label>
                    Platform Charges{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <div className="platform-charge-area">
                    <select
                      value={product.platform_charge_type}
                      onChange={handleInputChange}
                      name="platform_charge_type"
                      className="form-control platform-charge-type"
                    >
                      <option value="">Select</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                    <input
                      value={product.platform_charge}
                      onChange={handleInputChange}
                      name="platform_charge"
                      type="text"
                      className="form-control platform-charge"
                    />
                  </div>
                  {errors.platform_charge && (
                    <label className="red">{errors.platform_charge}</label>
                  )}
                </div>
                <div>
                  <label>
                    Product Address{" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <select
                    className="form-control"
                    name="product_address"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const val = e.target.value;
                      if (val === "Warehouse") setEnableWarehouse(true);
                      else setEnableWarehouse(false);
                    }}
                    value={product.product_address}
                  >
                    <option value="">Select</option>
                    <option value="Seller">Seller Address</option>
                    <option value="Warehouse">Warehouse</option>
                  </select>
                  {errors.product_address && (
                    <label className="red">{errors.product_address}</label>
                  )}
                </div>
                {enableWarehouse && (
                  <div>
                    <label>
                      Warehouse <span className="red">*</span>
                    </label>
                    <select
                      className="form-control warehouse-select"
                      name="warehouse_id"
                      onChange={handleInputChange}
                      value={product.warehouse_id}
                    >
                      <option value="">Select</option>
                      {warehouseOptionsValue.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.warehouse_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.warehouse_id && (
                      <label className="red">{errors.warehouse_id}</label>
                    )}
                  </div>
                )}
                <div>
                  <label>HSN</label>
                  <input
                    type="text"
                    name="hsn"
                    className="form-control"
                    value={product.hsn}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {variantsProductList &&
              variantsProductList.map((products, index) => {
                return (
                  <div key={index}>
                    {products && (
                      <div className="small-box">
                        <div>
                          <div className="addProdVariantCheckboxContainer">
                            <div className="">
                              <label className="checkbox-label isDefaultLabel">
                                <input
                                  checked={products.is_default === 1}
                                  type="checkbox"
                                  id="product-price-first"
                                  name="priceOption"
                                  value="product_price_as_first"
                                  onClick={() => {
                                    updateVariantField(
                                      index,
                                      "update_stock",
                                      true
                                    );

                                    markDefaultProduct(index);
                                  }}
                                />
                                Show This Variant In Product List Page
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* logic for image uploading starts from here */}
                        <div className="product-images">
                          <div>
                            <label>
                              Thumbnail{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.thumb_image) {
                                  handleImageUpload(
                                    `${index}_thumb_image`,
                                    "thumb_image",
                                    "thumb_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              className={
                                "image-dropzone " +
                                (products.thumb_image && "dropzone-uploaded")
                              }
                            >
                              {products.thumb_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.thumb_image instanceof File
                                        ? URL.createObjectURL(
                                            products.thumb_image
                                          )
                                        : `${IMG_HOST}/${products.thumb_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.thumb_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "thumb_image",
                                              products.thumb_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: product.thumb_image,
                                              imgName: "thumb_image",
                                              imgNameError:
                                                product.thumb_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "thumb_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_thumb_image`}
                                id={`${index}_thumb_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="thumb_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "thumb_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "thumb_image", "");
                                }}
                                hidden
                              />
                            </div>

                            <label
                              id={`product_thumb_image_error_${index}`}
                              className="red"
                            >
                              {products.thumb_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Front View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.front_image) {
                                  handleImageUpload(
                                    `${index}_front_image`,
                                    "front_image",
                                    "front_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              id={`font_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.front_image && "dropzone-uploaded")
                              }
                            >
                              {products.front_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.front_image instanceof File
                                        ? URL.createObjectURL(
                                            products.front_image
                                          )
                                        : `${IMG_HOST}/${products.front_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.front_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "front_image",
                                              products.front_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: products.front_image,
                                              imgName: "front_image",
                                              imgNameError:
                                                products.front_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "front_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_front_image`}
                                id={`${index}_front_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="front_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "front_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "front_image", "");
                                }}
                                hidden
                              />
                            </div>

                            <label className="red">
                              {products.front_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Left View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.left_image) {
                                  handleImageUpload(
                                    `${index}_left_image`,
                                    "left_image",
                                    "left_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              id={`left_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.left_image && "dropzone-uploaded")
                              }
                            >
                              {products.left_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.left_image instanceof File
                                        ? URL.createObjectURL(
                                            products.left_image
                                          )
                                        : `${IMG_HOST}/${products.left_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.left_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "left_image",
                                              product.left_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: product.left_image,
                                              imgName: "left_image",
                                              imgNameError:
                                                product.left_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "left_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_left_image`}
                                id={`${index}_left_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="left_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "left_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "left_image", "");
                                }}
                                hidden
                              />
                            </div>
                            <label
                              id={`product_left_image_error_${index}`}
                              className="red"
                            >
                              {products.left_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Right View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.right_image) {
                                  handleImageUpload(
                                    `${index}_right_image`,
                                    "right_image",
                                    "right_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              id={`right_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.right_image && "dropzone-uploaded")
                              }
                            >
                              {products.right_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.right_image instanceof File
                                        ? URL.createObjectURL(
                                            products.right_image
                                          )
                                        : `${IMG_HOST}/${products.right_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.right_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "right_image",
                                              product.right_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: product.right_image,
                                              imgName: "right_image",
                                              imgNameError:
                                                product.right_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "right_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_right_image`}
                                id={`${index}_right_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="right_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "right_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "right_image", "");
                                }}
                                hidden
                              />
                            </div>
                            <label
                              id={`product_right_image_error_${index}`}
                              className="red"
                            >
                              {products.right_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Back View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.back_image) {
                                  handleImageUpload(
                                    `${index}_back_image`,
                                    "back_image",
                                    "back_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              id={`Back_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.back_image && "dropzone-uploaded")
                              }
                            >
                              {products.back_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.back_image instanceof File
                                        ? URL.createObjectURL(
                                            products.back_image
                                          )
                                        : `${IMG_HOST}/${products.back_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.back_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "back_image",
                                              product.back_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: product.back_image,
                                              imgName: "back_image",
                                              imgNameError:
                                                product.back_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "back_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_back_image`}
                                id={`${index}_back_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="back_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "back_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "back_image", "");
                                }}
                                hidden
                              />
                            </div>
                            <label
                              id={`product_back_image_error_${index}`}
                              className="red"
                            >
                              {products.back_image_error}
                            </label>
                          </div>
                          <div>
                            <label>
                              Top View{" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              onClick={() => {
                                if (!products.top_image) {
                                  handleImageUpload(
                                    `${index}_top_image`,
                                    "top_image",
                                    "top_image_error",
                                    index,
                                    products.stock_id
                                  );
                                }
                              }}
                              id={`top_imageUploaded_For_Sellers_${index}`}
                              className={
                                "image-dropzone " +
                                (products.top_image && "dropzone-uploaded")
                              }
                            >
                              {products.top_image ? (
                                <>
                                  <img
                                    className="product-image"
                                    src={
                                      products.top_image instanceof File
                                        ? URL.createObjectURL(
                                            products.top_image
                                          )
                                        : `${IMG_HOST}/${products.top_image}`
                                    }
                                    loading="lazy"
                                  />
                                  <div className="img-btn-action">
                                    <div className="img-btns">
                                      {isBase64Image(products.top_image) && (
                                        <button
                                          type="button"
                                          className="image-edit-icon"
                                          onClick={() => {
                                            loadImgOnModal(
                                              "top_image",
                                              product.top_image
                                            );
                                            setProdImageToEdit((prev) => ({
                                              ...prev,
                                              imgFile: product.top_image,
                                              imgName: "top_image",
                                              imgNameError:
                                                product.top_image_error,
                                              index: index,
                                            }));
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span>Edit</span>
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="image-remove-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateVariantField(
                                            index,
                                            "top_image",
                                            null
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p className="click-to-upload">
                                  Click to upload
                                </p>
                              )}
                              <input
                                key={`${index}_top_image`}
                                id={`${index}_top_image`}
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                name="top_image"
                                onChange={(e) => {
                                  updateVariantField(
                                    index,
                                    "top_image",
                                    e.target.files[0]
                                  );
                                  updateVariantField(index, "top_image", "");
                                }}
                                hidden
                              />
                            </div>
                            <label
                              id={`products_top_image_error_${index}`}
                              className="red"
                            >
                              {products.top_image_error}
                            </label>
                          </div>
                        </div>

                        <div className="video-url">
                          <label className="mt-10">Video URL</label>
                          <input
                            type="text"
                            name="video_url"
                            value={products.video_url}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                          {errors.video_url && (
                            <label className="red">{errors.video_url}</label>
                          )}
                        </div>

                        <div
                          className="multi-input-row"
                          style={{ marginTop: "15px" }}
                        >
                          <div>
                            <label>
                              Product Price (₹){" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              name="product_price"
                              min="100"
                              value={products.product_price}
                              readOnly={product && product.is_negotiable === 0}
                              onChange={(e) => {
                                if (product && product.is_negotiable === 1) {
                                  let value = e.target.value;
                                  if (!/^\d*$/.test(value)) {
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      "Only numbers are allowed."
                                    );
                                  } else if (value > 100000) {
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      "product price must not be greater than 100,000."
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "product_price",
                                      value.trimStart()
                                    );
                                    updateVariantField(
                                      index,
                                      "product_price_error",
                                      ""
                                    );
                                  }
                                  if (page === "edit") {
                                    if (product.stock_id !== null) {
                                      updateVariantField(
                                        index,
                                        "update_stock",
                                        true
                                      );
                                    } else {
                                      updateVariantField(
                                        index,
                                        "new_stock",
                                        true
                                      );
                                    }
                                  }
                                }
                              }}
                              className="form-control"
                            />
                            {products.product_price_error && (
                              <label className="red">
                                {products.product_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>
                              Estimated Original Price (₹){" "}
                              {product.status === "Verified and Published" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              name="current_market_price"
                              value={products.current_market_price}
                              readOnly={product && product.is_negotiable === 0}
                              onChange={(e) => {
                                if (product && product.is_negotiable === 1) {
                                  let value = e.target.value;
                                  if (!/^\d*$/.test(value)) {
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      "Only numbers are allowed."
                                    );
                                  } else if (value > 200000) {
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      "Estimated Original price must not be greater than 200,000."
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "current_market_price",
                                      value.trimStart()
                                    );
                                    updateVariantField(
                                      index,
                                      "current_market_price_error",
                                      ""
                                    );
                                  }
                                  if (page === "edit") {
                                    if (product.stock_id !== null) {
                                      updateVariantField(
                                        index,
                                        "update_stock",
                                        true
                                      );
                                    } else {
                                      updateVariantField(
                                        index,
                                        "new_stock",
                                        true
                                      );
                                    }
                                  }
                                }
                              }}
                              min="200"
                              className="form-control"
                            />
                            {products.current_market_price_error && (
                              <label className="red">
                                {products.current_market_price_error}
                              </label>
                            )}
                          </div>
                          <div>
                            <label>Selling Price (₹)</label>
                            <div className="selling-price-line">
                              <input
                                disabled={true}
                                readOnly={true}
                                type="number"
                                name="selling_price"
                                value={products.selling_price}
                                onChange={handleInputChange}
                                className="form-control"
                                style={{ backgroundColor: "#e7e7e7" }}
                              />
                              <button
                                type="button"
                                className={`btn ${
                                  disableCalculate ? "" : "secondary-btn"
                                }`}
                                disabled={disableCalculate}
                                style={{
                                  cursor: disableCalculate && "not-allowed",
                                  opacity: disableCalculate && "0.5",
                                  backgroundColor:
                                    disableCalculate && "#E7E7E7",
                                }}
                                onClick={(e) => {
                                  calculateSellingPrice(
                                    index,
                                    products.current_market_price,
                                    products.product_price
                                  );
                                }}
                              >
                                Calculate
                              </button>
                            </div>
                          </div>
                          <div>
                            <label>SKU</label>
                            <input
                              type="text"
                              name="sku"
                              className="form-control"
                              value={products.sku}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                updateVariantField(index, "sku", value);
                                updateVariantField(index, "sku_error", "");

                                if (page === "edit") {
                                  if (product.stock_id !== null) {
                                    updateVariantField(
                                      index,
                                      "update_stock",
                                      true
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "new_stock",
                                      true
                                    );
                                  }
                                }
                              }}
                            />
                            {errors.sku && (
                              <label className="red">{errors.sku}</label>
                            )}
                          </div>
                          <div>
                            <label>Barcode (ISBN, UPC, GTIN, etc.) </label>
                            <input
                              type="text"
                              name="barcode"
                              className="form-control"
                              value={products.barcode}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                updateVariantField(index, "barcode", value);
                                updateVariantField(index, "barcode_error", "");

                                if (products.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }}
                            />
                          </div>
                          <div>
                            <label>Quantity</label>
                            <input
                              type="text"
                              name="quantity"
                              className="form-control"
                              value={products.quantity}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                if (/^\d*$/.test(value)) {
                                  updateVariantField(index, "quantity", value);
                                  updateVariantField(
                                    index,
                                    "quantity_error",
                                    ""
                                  );

                                  if (page === "edit") {
                                    if (products.stock_id !== null) {
                                      updateVariantField(
                                        index,
                                        "update_stock",
                                        true
                                      );
                                    } else {
                                      updateVariantField(
                                        index,
                                        "new_stock",
                                        true
                                      );
                                    }
                                  }
                                } else {
                                  // Optionally, you can set an error message if needed
                                  updateVariantField(
                                    index,
                                    "quantity_error",
                                    "Please enter a valid number."
                                  );
                                }
                              }}
                            />
                            <label className="red">
                              {products.quantity_error}
                            </label>
                          </div>{" "}
                          <div className="col">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <label>
                                Variant{" "}
                                {product.status ===
                                  "Verified and Published" && (
                                  <span className="red">*</span>
                                )}
                              </label>

                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  className="varientDropDownTitle"
                                  style={{ width: "100%" }}
                                  onClick={() =>
                                    updateVariantField(
                                      index,
                                      "variantListVisible",
                                      !products.variantListVisible
                                    )
                                  }
                                >
                                  {" "}
                                  <p>
                                    {products.selectedVariant &&
                                    products.selectedVariant.id
                                      ? products.selectedVariant.name
                                      : "Select"}
                                  </p>{" "}
                                  <FontAwesomeIcon
                                    icon={
                                      products.variantListVisible
                                        ? faSortUp
                                        : faSortDown
                                    }
                                  />
                                </span>
                              </div>
                              {products.variantListVisible && (
                                <div className="variantDropdownList">
                                  {variantsList.length > 0 &&
                                    variantsList.map((variant) => {
                                      return (
                                        <div
                                          className="variantsListOptions"
                                          onClick={async () => {
                                            updateVariantField(
                                              index,
                                              "variantListVisible",
                                              false
                                            );
                                            updateVariantField(
                                              index,
                                              "selectedVariant",
                                              variant
                                            );
                                            updateVariantField(
                                              index,
                                              "variant_values",
                                              []
                                            );
                                            updateVariantField(
                                              index,
                                              "selectedVariant_error",
                                              ""
                                            );
                                            if (
                                              variant.variant_values &&
                                              variant.variant_values.length > 0
                                            ) {
                                              updateVariantField(
                                                index,
                                                "selectedVariant",
                                                variant
                                              );
                                            }

                                            if (page === "edit") {
                                              if (products.stock_id !== null) {
                                                updateVariantField(
                                                  index,
                                                  "update_stock",
                                                  true
                                                );
                                              } else {
                                                updateVariantField(
                                                  index,
                                                  "new_stock",
                                                  true
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            checked={
                                              products.selectedVariant &&
                                              products.selectedVariant.id &&
                                              products.selectedVariant.id ===
                                                variant.id
                                            }
                                          />
                                          <label htmlFor="">
                                            {variant.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                              {products.selectedVariant_error && (
                                <label className="red">
                                  {products.selectedVariant_error}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <label>
                                Variant Value{" "}
                                {product.status ===
                                  "Verified and Published" && (
                                  <span className="red">*</span>
                                )}
                              </label>
                              <div
                                className="form-control"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  style={{ width: "100%" }}
                                  className="varientDropDownTitle"
                                  onClick={() =>
                                    updateVariantField(
                                      index,
                                      "variantValuesListVisible",
                                      !products.variantValuesListVisible
                                    )
                                  }
                                >
                                  {" "}
                                  <p>
                                    {products.variant_values &&
                                    products.variant_values.value
                                      ? products.variant_values.value
                                      : "Select"}
                                  </p>{" "}
                                  <FontAwesomeIcon
                                    icon={
                                      products.variantValuesListVisible
                                        ? faSortUp
                                        : faSortDown
                                    }
                                  />
                                </span>
                              </div>
                              {products.variantValuesListVisible && (
                                <div className="variantDropdownList">
                                  {products.selectedVariant ? (
                                    <>
                                      {products.selectedVariant
                                        .variant_values &&
                                        products.selectedVariant.variant_values
                                          .length > 0 && (
                                          <>
                                            {products.selectedVariant.variant_values.map(
                                              (variantValue) => {
                                                return (
                                                  <div
                                                    className="variantsListOptions"
                                                    onClick={async () => {
                                                      updateVariantField(
                                                        index,
                                                        "variant_values_error",
                                                        ""
                                                      );
                                                      updateVariantField(
                                                        index,
                                                        "variant_values",
                                                        variantValue
                                                      )
                                                        .then(() => {
                                                          updateVariantField(
                                                            index,
                                                            "variantValuesListVisible",
                                                            false
                                                          );
                                                        })
                                                        .then(() => {
                                                          checkVariantExist(
                                                            index,
                                                            variantValue
                                                          );
                                                        });

                                                      if (page === "edit") {
                                                        if (
                                                          products.stock_id !==
                                                          null
                                                        ) {
                                                          updateVariantField(
                                                            index,
                                                            "update_stock",
                                                            true
                                                          );
                                                        } else {
                                                          updateVariantField(
                                                            index,
                                                            "new_stock",
                                                            true
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      checked={
                                                        products.variant_values &&
                                                        products.variant_values
                                                          .id ===
                                                          variantValue.id
                                                      }
                                                    />
                                                    <label htmlFor="">
                                                      {variantValue.value}
                                                    </label>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </>
                                  ) : (
                                    <label>Please select a variant</label>
                                  )}
                                </div>
                              )}

                              {product.variant_values_error && (
                                <label className="red">
                                  {products.variant_values_error}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

            {/* Length, Width, Height, Weight */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>
                    Box Length (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="length"
                    value={product.length}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.length && (
                    <label className="red">{errors.length}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box width (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="width"
                    value={product.width}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.width && (
                    <label className="red">{errors.width}</label>
                  )}
                </div>
                <div>
                  <label>
                    Box height (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="height"
                    value={product.height}
                    onChange={handleInputChange}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.height && (
                    <label className="red">{errors.height}</label>
                  )}
                </div>
                <div>
                  <label>
                    Weight (kg){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="weight"
                    value={product.weight}
                    onChange={handleInputChange}
                    className="form-control"
                    step="0.01"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.weight && (
                    <label className="red">{errors.weight}</label>
                  )}
                </div>
              </div>
            </div>

            {/* Product SEO */}
            <div className="small-box">
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaTitleRemaining(metaTitleMaxLength - length);
                    }}
                    className="form-control"
                    type="text"
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    name="meta_description"
                    className="form-control meta-description-input"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaDescRemaining(metaDescMaxLength - length);
                    }}
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="right-area">
            <div className="small-box">
              <label>
                Status <span className="red">*</span>
              </label>
              <select
                name="status"
                className="form-control"
                value={product.status}
                onChange={(e) => {
                  const val = e.target.value;
                  setProduct((prev) => ({
                    ...prev,
                    status: val,
                  }));
                }}
              >
                <option value="">Select</option>
                <option value="Verified and Published">
                  Verified and Published
                </option>
                <option value="Unverified">Unverified</option>
                <option value="Rejected">Rejected</option>
              </select>
              <p style={{ marginTop: "15px" }}>
                Created By{" "}
                <span className="sold-by-label">
                  {sellerData.is_owner ? "Business Seller" : "Domestic Seller"}
                </span>
              </p>
            </div>
            <div className="small-box">
              <label>
                Category{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <select
                className="form-control"
                name="category_id"
                value={product.category_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {categoryData &&
                  categoryData.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
              {errors.category_id && (
                <label className="red">{errors.category_id}</label>
              )}
              <label className="mt-10">Brand</label>
              <select
                className="form-control"
                name="brand_id"
                value={product.brand_id}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {brandData &&
                  brandData.map((brands, index) => (
                    <option key={index} value={brands.id}>
                      {brands.name}
                    </option>
                  ))}
              </select>
              {errors.brand_id && (
                <label className="red">{errors.brand_id}</label>
              )}
              <label className="mt-10">
                Condition{" "}
                {product.status === "Verified and Published" && (
                  <span className="red">*</span>
                )}
              </label>
              <select
                name="condition"
                value={product.condition}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select</option>
                {conditionData &&
                  conditionData.map((conditions) => (
                    <option key={conditions} value={conditions}>
                      {conditions}
                    </option>
                  ))}
              </select>
              {errors.condition && (
                <label className="red">{errors.condition}</label>
              )}
              <div>
                <label className="mt-10">
                  Applicable Age Group{" "}
                  {product.status === "Verified and Published" && (
                    <span className="red">*</span>
                  )}
                </label>
                <select
                  className="form-control"
                  name="age_group_id"
                  value={product.age_group_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {applicableAgeGroupData.map((ageGroup) => (
                    <option key={ageGroup.id} value={ageGroup.id}>
                      {ageGroup.title}
                    </option>
                  ))}
                </select>
                {errors.age_group_id && (
                  <label className="red">{errors.age_group_id}</label>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Save Product btn area*/}
        <div className="product-btn-area">
          <button
            type="button"
            onClick={() => navigate(window.history.back())}
            className="secondary-btn"
          >
            Cancel
          </button>
          {enableAddProductBtn ? (
            <button
              type="button"
              className="primary-btn"
              style={{
                width: "80px",
              }}
              onClick={() => {
                handleButtonSubmit();
              }}
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              disabled={true}
              className="disabled-primary-btn"
              style={{
                width: "80px",
              }}
            >
              {/* Save */}
              <LoaderButton />
            </button>
          )}
        </div>
      </div>

      {/* Max Selling Price */}
      {infoModalOpen && (
        <div
          className="modal fade"
          style={{ display: infoModalOpen ? "block" : "none" }}
        >
          <div className="modal-content">
            <h3 className="modal-header">Oops!</h3>
            <p className="modal-line">{modalMessage}</p>
            <div className="modal-buttons">
              <button
                className="btn primary-btn"
                onClick={() => {
                  setInfoModalOpen(false);
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reject success modal */}
      {productRejectModal && (
        <div id="productRejectModal" className="modal fade">
          <div className="modal-content">
            <span
              className="modal-close"
              onClick={() => {
                closeProductRejectModal();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <h3 className="modal-header">Rejection Confirmation</h3>
            <label>Denied Note</label>
            <textarea
              name="denied_note"
              onChange={handleInputChange}
              className="form-control"
              value={product.denied_note}
            />
            <label className="red">{errors.denied_note}</label>
            <div className="modal-buttons">
              <button
                disabled={!enableRejectBtn}
                type="button"
                onClick={() => {
                  rejectedProduct();
                }}
                className={`btn${
                  enableRejectBtn ? " primary-btn" : " disabled-primary-btn"
                }`}
              >
                {rejectBtnLoading ? <LoaderButton /> : "Reject"}
              </button>
              <button
                type="button"
                className="btn secondary-btn"
                onClick={() => {
                  closeProductRejectModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* image upload modal */}
      {showImgUpModal && (
        <div className="modal fade">
          <div className="img-modal-content">
            <div className="img-modal-header">
              <p>Image upload</p>
              <button
                className="modal-close-icon"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-img-editor">
                <Cropper
                  src={rawImage}
                  ref={cropperRef}
                  initialAspectRatio={1}
                  aspectRatio={rawImgName === "thumb_image" ? 1 : undefined}
                  preview=".img-preview"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  style={{ maxHeight: 350 }}
                />
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    float: "left",
                    height: "300px",
                    border: "1px solid #ced4da",
                    maxHeight: 350,
                  }}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn primary-btn" onClick={cropImage}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* page loading modal */}
      {isLoading && (
        <div className="modal fade">
          <div className="modal-content-loader">
            <img src={loadingImgPath} className="loader-img-size" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerProductNewEdit;
